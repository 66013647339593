/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

// import { getUpMedia } from '../../styles';

export const SmallInputPlaceholderText: FC<PropsWithChildren> = ({
  children,
  ...props
}) => <Text {...props}>{children}</Text>;

const Text = styled.span`
  font-size: 12px;
`;
