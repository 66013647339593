/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getLocaleByAlpha2 } from 'country-locale-map';
import { parse } from 'query-string';
import { ApolloError } from '@apollo/client';
import { sendSentryError } from '../../utils/sentry';
import { useGetMollieTransitPaymentLinkQuery } from '../../apollo';
import { useAlert } from '../../providers';
import { Button } from '../../components/buttons';
import { Title } from '../../components/texts';
import { AuthLayout } from '../../layouts';

const PayInvoicePage = () => {
  const { t, i18n } = useTranslation();
  const { setAlert } = useAlert();
  const location = useLocation();
  const locale = getLocaleByAlpha2(i18n.language.toUpperCase());

  const token = useMemo(() => {
    const { token } = parse(location.search) ?? {};
    return (token as string) || null;
  }, [location.search]);

  const handlePaymentLinkError = useCallback(
    (e: Error | ApolloError) => {
      setAlert({
        type: 'error',
        message: t('PAY_INVOICE_PAGE__error'),
      });
      sendSentryError(e);
    },
    [setAlert, t]
  );

  useEffect(() => {
    if (!token) {
      handlePaymentLinkError(
        new Error('No valid tokens data on PayInvoicePage')
      );
    }
  }, [token, handlePaymentLinkError]);

  const {
    loading: loadingDirectPaymentLink,
    error: errorOnGetDirectPaymentLinkLoad,
    data: getDirectPaymentLinkResponse,
  } = useGetMollieTransitPaymentLinkQuery({
    skip: !token,
    variables: {
      token: token ?? '',
      locale,
    },
    onError: handlePaymentLinkError,
  });

  const url =
    getDirectPaymentLinkResponse?.getMollieTransitPaymentLink.checkoutURL;

  return (
    <AuthLayout withTopRightLogo>
      <Title withLine gutterBottom>
        {t('PAY_INVOICE_PAGE__title')}
      </Title>
      <Button
        href={url}
        loading={loadingDirectPaymentLink}
        disabled={!!errorOnGetDirectPaymentLinkLoad || !url}
      >
        {t('PAY_INVOICE_PAGE__button')}
      </Button>
    </AuthLayout>
  );
};

export { PayInvoicePage };
