/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  forwardRef,
  useState,
  useRef,
  useCallback,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import {
  InputLabel,
  IconButton as DefIconButton,
  Popper,
  Fade,
  ClickAwayListener,
  InputLabelProps,
} from '@mui/material';
import { QuestionMark } from '../icons';

const Wrapper = styled(InputLabel)`
  display: flex;
  align-items: center;
  pointer-events: auto;
`;

const PopperWrapper = styled.div``;

const IconButton = styled(DefIconButton)`
  padding: 1px;
  margin-left: 4px;
`;

const Icon = styled(QuestionMark)`
  width: 16px;
  height: 16px;
`;

// TODO: add color to constants
const PopperContentWrapper = styled.div`
  padding: 4px 6px;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  background-color: #fff;
`;

type Props = InputLabelProps & {
  tooltipContent: ReactNode;
};

const LabelWithTooltipIcon = forwardRef(
  ({ children, tooltipContent, ...props }: Props, ref) => {
    const buttonRef = useRef<HTMLButtonElement>();
    const [popperOpened, setPopperOpened] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );

    const handleClose = useCallback(() => {
      setPopperOpened(false);
      setAnchorEl(null);
    }, []);

    const handleClick = (event: React.PointerEvent<HTMLButtonElement>) => {
      if (popperOpened) return;

      setAnchorEl(event.currentTarget);
      setPopperOpened(true);
    };

    const id = popperOpened ? 'description-popper' : undefined;

    return (
      <Wrapper ref={ref as any} {...props}>
        {children}
        <ClickAwayListener onClickAway={handleClose}>
          <PopperWrapper>
            <IconButton
              ref={buttonRef as any}
              aria-label="Info"
              aria-describedby={id}
              onClick={handleClick}
            >
              <Icon />
            </IconButton>
            <Popper
              id={id}
              open={popperOpened}
              anchorEl={anchorEl}
              placement={'right'}
              popperOptions={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              }}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <PopperContentWrapper>{tooltipContent}</PopperContentWrapper>
                </Fade>
              )}
            </Popper>
          </PopperWrapper>
        </ClickAwayListener>
      </Wrapper>
    );
  }
);

LabelWithTooltipIcon.defaultProps = {};

export { LabelWithTooltipIcon };
