/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useEffect } from 'react';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import {
  UserExchangesQuery,
  AumBelowMinLastShowTimeFragmentDoc,
} from '../apollo';
import { useAlertModal } from './useAlertModal';
import { useIsDashboardPage } from './useIsDashboardPage';

export const useMinAum = (
  exchange: Nullish<Exchange>,
  exchangeWizardActive: boolean
) => {
  const client = useApolloClient();
  const { dispatch } = useAlertModal();
  const dashboardPage = useIsDashboardPage();

  useEffect(() => {
    if (!dashboardPage) return;
    if (exchangeWizardActive) return;
    if (!exchange?.aumBelowMinimum) return;

    const lastShowTime = moment.utc(exchange.aumBelowMinLastShowTime);
    const now = moment.utc();

    if (
      exchange.aumBelowMinLastShowTime &&
      lastShowTime.isValid() &&
      now.diff(lastShowTime, 'hours') < 25
    ) {
      return;
    }

    dispatch(['setModalContent', 'aumBelowMinimum']);

    client.writeFragment({
      id: client.cache.identify(exchange),
      fragment: AumBelowMinLastShowTimeFragmentDoc,
      data: {
        aumBelowMinLastShowTime: moment.utc().format(),
      },
    });
  }, [dashboardPage, exchange, exchangeWizardActive, dispatch, client]);
};

type Exchange = UserExchangesQuery['getUserExchanges'][number];
