/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { sendSentryError } from '../../utils/sentry';
import { useAdminRebalanceUserMutation } from '../../apollo';
import { useAlert, useModals } from '../../providers';
import { Button as DefButton } from '../buttons';

const RebalanceUserAdminModal = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { data: { userId, exchangeId } = {}, dispatch } =
    useModals<'rebalanceUserAdmin'>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'rebalance-user-admin',
        titleText: t('REBALANCE_USER_ADMIN_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  const [rebalanceUser, { loading: rebalancingUser }] =
    useAdminRebalanceUserMutation({
      onCompleted: () => {
        setAlert({
          type: 'success',
          message: t('REBALANCE_USER_ADMIN_MODAL__success'),
        });
        dispatch({ type: 'closeModal' });
      },
      onError: (error) => {
        setAlert({
          type: 'error',
          message: t('REBALANCE_USER_ADMIN_MODAL__error'),
        });
        sendSentryError(error);
      },
    });

  const active = !!userId && !!exchangeId;

  return (
    <Button
      loading={rebalancingUser}
      disabled={!active}
      onClick={() => {
        active &&
          rebalanceUser({
            variables: {
              userId,
              exchangeId,
            },
          });
      }}
    >
      {t('REBALANCE_USER_ADMIN_MODAL__button')}
    </Button>
  );
};

export type RebalanceUserAdminModalData = {
  userId: number;
  exchangeId: number;
};

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 6px;
`;

export { RebalanceUserAdminModal };
