/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { externalLinkProps, SiteData } from '../../utils/consts';
import { ExchangeCode } from '../../apollo';
import { useExchange } from '../../providers';
import { WizardExchangeKeysForm } from '../forms';
import { Text as DefText, Link as DefLink } from '../texts';
import { StepSubTitle } from './StepSubTitle';

export type ExchangeKeysFormStepProps = {
  className?: string;
} & ComponentPropsWithoutRef<typeof WizardExchangeKeysForm>;

const ExchangeKeysFormStep = ({
  className,
  ...props
}: ExchangeKeysFormStepProps) => {
  const { t } = useTranslation();
  const { exchange } = useExchange();
  const link = exchange ? links[exchange.exchangeCode] : '';

  return (
    <Wrapper className={className}>
      <Text align={'center'}>
        {t(descTexts[exchange?.exchangeCode || ExchangeCode.Kraken])}
      </Text>
      <Link href={link} {...externalLinkProps}>
        {t('WIZARD_STEPPER__connectStepDescLinkText')}
      </Link>
      <StepSubTitle align={'left'} style={{ marginBottom: 10 }}>
        {t('WIZARD_STEPPER__connectStepFormTitle')}
      </StepSubTitle>
      <WizardExchangeKeysForm {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const Text = styled(DefText)`
  margin-bottom: 20px;
`;

const Link = styled(DefLink)`
  margin: 0 auto 30px;
`;

const links: { [key in ExchangeCode]: SiteData } = {
  [ExchangeCode.Bitvavo]: SiteData.BITVAVO_API_LINK,
  [ExchangeCode.Kraken]: SiteData.KRAKEN_API_LINK,
};

const descTexts: { [key in ExchangeCode]: string } = {
  [ExchangeCode.Bitvavo]: 'WIZARD_STEPPER__connectStepBitvavoDescText',
  [ExchangeCode.Kraken]: 'WIZARD_STEPPER__connectStepKrakenDescText',
};

export { ExchangeKeysFormStep };
