/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { colors } from '../utils/colors';
import { fontSize, regularText, regularTextFontFamily } from '../utils/fonts';
import '../assets/fonts/et-line-font/style.css';
import { getColor } from './utils';

const GlobalStyles = createGlobalStyle`
  ${styledNormalize};

  @font-face {
    font-family: 'Arial';
    src: local('Arial'), local('ArialMT'),
    url('../assets/fonts/arial/ArialMT.woff2') format('woff2'),
    url('../assets/fonts/arial/ArialMT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  :root {
    --app-height: 100%;
  }

  html {
    font-size: ${fontSize}px;
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html, body {
    max-width: 100%;
    height: 100vh;
    height: var(--app-height);
    overflow: hidden;
    ${regularText};
    font-family: ${regularTextFontFamily};
    color: ${colors.darkBlue};
    -webkit-font-smoothing: antialiased;
  }

  p{
    margin-block-start: 0;
    margin-block-end: 0;
  }

  a {
    font-family: ${regularTextFontFamily};
    color: ${({
      theme: {
        palette: {
          secondary: { main },
        },
      },
    }) => main};
    text-decoration: none;

    &:hover{
      color: ${getColor('darkBlue')}
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  .grecaptcha-badge { visibility: hidden; }

  .highcharts-credits {
    display: none;
  }
`;

export { GlobalStyles };
