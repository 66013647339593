/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Link } from '@mui/material';
import { SiteData, externalLinkProps } from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import { sendSentryError } from '../../utils/sentry';
import { useAlertModal, useIsMobile } from '../../hooks';
import {
  ExchangeCode,
  StartTrialStatus,
  useStartSubscriptionMutation,
  ExchangesSubscriptionInitiatedDatesDocument,
} from '../../apollo';
import { getColor } from '../../styles';
import { useAlert, useExchangeWizard, useExchange } from '../../providers';
import { Button as DefButton } from '../buttons';
import { Text as DefText } from '../texts';

export type DepositStepProps = HTMLAttributes<HTMLDivElement>;

const ExchangeDepositStep = (props: DepositStepProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { exchange } = useExchange();
  const { moneyAmount, moneyExist, fiatOnly } = useExchangeWizard();
  const { dispatch: dispatchAlertModal } = useAlertModal();

  const mobile = useIsMobile();

  const showRebalanceInProgress = () => {
    dispatchAlertModal(['setModalContent', 'initialPortfolioRebalanceStart']);
  };

  const showStartTrialError = (error: ApolloError | Error) => {
    sendSentryError(error);
    setAlert({
      type: 'error',
      message: t('WIZARD_STEPPER__depositStepStartTrialErrorText'),
    });
  };

  const [startSubscription, { loading: startingTrial }] =
    useStartSubscriptionMutation({
      refetchQueries: [
        {
          query: ExchangesSubscriptionInitiatedDatesDocument,
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const status = data?.startSubscription;

        if (status === StartTrialStatus.Ok) {
          showRebalanceInProgress();
        } else {
          showStartTrialError(
            new Error(`Start subscription status: ${status}`)
          );
        }
      },
      onError: showStartTrialError,
    });

  if (!exchange) return null;

  return (
    <Wrapper {...props}>
      <SaldoTitle variant={'h2'} component={'h3'} align={'center'}>
        {t('WIZARD_STEPPER__depositStepSaldoText')}:{' '}
        <span>{getPrice(moneyAmount ?? 0)}</span>
      </SaldoTitle>
      {!moneyExist ? (
        <>
          <Text>
            {t(descTexts[exchange.exchangeCode][mobile ? 'mobile' : 'desktop'])}
          </Text>
          <CenterLink
            href={linkTexts[exchange.exchangeCode].href}
            {...externalLinkProps}
          >
            {t(linkTexts[exchange.exchangeCode].text)}
          </CenterLink>
        </>
      ) : (
        <>
          <Text style={{ padding: '0 35px', marginBottom: 0 }}>
            {t(
              fiatOnly
                ? 'WIZARD_STEPPER__depositStepFiatSuccessText'
                : 'WIZARD_STEPPER__depositStepCryptoSuccessText'
            )}
          </Text>
          <Button
            loading={startingTrial}
            disabled={!!exchange.subscriptionInitiatedDate}
            onClick={() => {
              exchange?.exchangeID &&
                startSubscription({
                  variables: {
                    exchangeId: toNumber(exchange.exchangeID),
                  },
                });
            }}
          >
            {t('WIZARD_STEPPER__depositStepSuccessButton')}
          </Button>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section``;

const SaldoTitle = styled(DefText)`
  margin-bottom: 30px;

  span {
    color: ${getColor('blue2')};
  }
`;

const Text = styled(DefText)`
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px 0 20px;
  text-align: center;
  white-space: pre-line;
`;

const CenterLink = styled(Link)`
  display: block;
  text-align: center;
`;

const Button = styled(DefButton)`
  margin-top: 46px;
`;

const descTexts: {
  [key in ExchangeCode]: {
    [key2 in 'mobile' | 'desktop']: string;
  };
} = {
  [ExchangeCode.Kraken]: {
    mobile: 'WIZARD_STEPPER__krakenDepositStepContentDescMobile',
    desktop: 'WIZARD_STEPPER__krakenDepositStepContentDescDesktop',
  },
  [ExchangeCode.Bitvavo]: {
    mobile: 'WIZARD_STEPPER__bitvavoDepositStepContentDescMobile',
    desktop: 'WIZARD_STEPPER__bitvavoDepositStepContentDescDesktop',
  },
};

const linkTexts: Readonly<{
  [key in ExchangeCode]: {
    href: string;
    text: string;
  };
}> = {
  [ExchangeCode.Kraken]: {
    href: SiteData.KRAKEN_ADD_MONEY_LINK,
    text: 'WIZARD_STEPPER__krakenDepositStepContentLink',
  },
  [ExchangeCode.Bitvavo]: {
    href: SiteData.BITVAVO_ADD_MONEY_LINK,
    text: 'WIZARD_STEPPER__bitvavoDepositStepContentLink',
  },
};

export { ExchangeDepositStep };
