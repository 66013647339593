/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  RefCallback,
} from 'react';
import type { FC, PropsWithChildren } from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { serverResponseErrorsCodes } from '../utils/consts';
import { isServerErrorOfType } from '../utils/server-helpers';
import { Maybe } from '../apollo';
import { useAlert } from './Alert';

const GoogleRecaptcha: FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const captchaV2Ref = useRef<ReCAPTCHA | null>(null);
  const [place, setPlace] = useState<Nullish<string>>(null);
  const [data, setData] = useState(initData);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    let mounted = true;

    if (!(place && executeRecaptcha)) return;

    executeRecaptcha(place).then((token) => {
      mounted &&
        setData({
          version: 'V3',
          token,
        });
    });

    return () => {
      mounted = false;
    };
  }, [place, executeRecaptcha]);

  const setV2Ref = useCallback<SetV2RefFn>((ref) => {
    captchaV2Ref.current = ref;
  }, []);

  const setV2Token = useCallback<SetV2TokenFn>((token) => {
    setData({
      version: 'V2',
      token,
    });
  }, []);

  const handleCaptchaError = useCallback<HandleCaptchaErrorFn>(
    (error) => {
      const recaptchaV3Error = isServerErrorOfType({
        error,
        type: serverResponseErrorsCodes.CAPTCHA_V3_ERROR,
      });

      if (recaptchaV3Error) {
        setAlert({ type: 'info', message: t('COMMON_FORMS__captchaV3Error') });
        setData((prevState) => {
          return prevState.version === 'V3'
            ? {
                version: 'V2',
                token: null,
              }
            : prevState;
        });

        return {
          recaptchaError: 'V3',
        };
      }

      const recaptchaV2Error = isServerErrorOfType({
        error,
        type: serverResponseErrorsCodes.CAPTCHA_V2_ERROR,
      });

      if (recaptchaV2Error) {
        setAlert({
          type: 'warning',
          message: t('COMMON_FORMS__captchaV2Error'),
        });
        captchaV2Ref.current?.reset();

        return {
          recaptchaError: 'V2',
        };
      }

      return {
        recaptchaError: null,
      };
    },
    [setAlert, t]
  );

  const contextValue = useMemo(
    () => ({
      ...data,
      initRecaptcha: setPlace,
      handleCaptchaError,
      setV2Ref,
      setV2Token,
    }),
    [data, handleCaptchaError, setV2Ref, setV2Token]
  );

  return <GoogleRecaptchaContext.Provider value={contextValue} {...props} />;
};

type CaptchaVersion = 'V2' | 'V3';

export type GoogleRecaptchaData = {
  version: CaptchaVersion;
  token: Maybe<string>;
};

const initData: GoogleRecaptchaData = {
  version: 'V3',
  token: null,
};

type InitRecaptchaFn = (place: string) => void;
type HandleCaptchaErrorFn = (error: unknown) => {
  recaptchaError: 'V3' | 'V2' | null;
};
type SetV2RefFn = RefCallback<ReCAPTCHA>;
type SetV2TokenFn = (token: Nullish<string>) => void;
type ConteGoogleRecaptchaContextData = GoogleRecaptchaData & {
  initRecaptcha: InitRecaptchaFn;
  handleCaptchaError: HandleCaptchaErrorFn;
  setV2Ref: SetV2RefFn;
  setV2Token: SetV2TokenFn;
};

const GoogleRecaptchaContext = createContext<ConteGoogleRecaptchaContextData>({
  ...initData,
  initRecaptcha: () => undefined,
  handleCaptchaError: () => ({ recaptchaError: null }),
  setV2Ref: () => undefined,
  setV2Token: () => undefined,
});

export const useGoogleRecaptchaContext = () =>
  useContext(GoogleRecaptchaContext);

export { GoogleRecaptcha };
