/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  FC,
  PropsWithChildren,
} from 'react';

const Reminder2FA: FC<PropsWithChildren> = ({ children }) => {
  const [shouldBeShown, setShouldBeShown] = useState(false);

  const contextValue = useMemo(
    () => ({ shouldBeShown, setShouldBeShown }),
    [shouldBeShown]
  );

  return (
    <Reminder2FAContext.Provider value={contextValue}>
      {children}
    </Reminder2FAContext.Provider>
  );
};

export const Reminder2FAContext = createContext<{
  shouldBeShown: boolean;
  setShouldBeShown: (value: boolean) => void;
}>({
  shouldBeShown: false,
  setShouldBeShown: () => undefined,
});

export const useReminder2FAContext = () => useContext(Reminder2FAContext);

export { Reminder2FA };
