/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import img404 from '../../assets/images/404.svg';
import { Title, Link } from '../../components/texts';
import { AuthLayout } from '../../layouts';
import { paths } from '../paths';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <Title withLine gutterBottom>
        {t('NOT_FOUND_PAGE__title')}
      </Title>
      <Img src={img404} alt={'404 image'} />
      <Link withDoubleLeftArrow onClick={() => navigate(paths.dashboard)}>
        {t('NOT_FOUND_PAGE__link')}
      </Link>
    </AuthLayout>
  );
};

const Img = styled.img`
  margin-bottom: 40px;
`;

export { NotFound };
