/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation, TFunction } from 'react-i18next';
import {
  getColor,
  resetListStyles,
  getDownMedia,
  getUpMedia,
} from '../../styles';
import { AppPageContentWrapper as DefAppPageContentWrapper } from '../../styled';
import businessEmail from '../../assets/images/contacts-icons/business-mail.svg';
import chat from '../../assets/images/contacts-icons/chat.svg';
import mail from '../../assets/images/contacts-icons/mail.svg';
import phone from '../../assets/images/contacts-icons/phone.svg';
import {
  Title as DefTitle,
  Text as DefText,
  Link,
  LinkProps,
} from '../../components/texts';
import { AppPageLayout } from '../../layouts';

const ContactsPage = () => {
  const { t } = useTranslation();

  const items = useMemo(() => getContacts(t), [t]);

  return (
    <AppPageLayout>
      <AppPageContentWrapper>
        <Title withLine>{t('CONTACTS_PAGE__title')}</Title>
        <Desc>{t('CONTACTS_PAGE__desc')}</Desc>
        <List>
          {items.map(({ icon, title, desc, linkProps }) => (
            <ListItem key={title}>
              <ListItemIcon src={icon} aria-hidden />
              <ListItemTitle variant={'h2'}>{t(title)}</ListItemTitle>
              <ListItemDesc>{desc}</ListItemDesc>
              <ListItemLink {...linkProps} />
            </ListItem>
          ))}
        </List>
      </AppPageContentWrapper>
    </AppPageLayout>
  );
};

const AppPageContentWrapper = styled(DefAppPageContentWrapper)`
  margin-top: 110px;
  background-color: transparent;

  ${getUpMedia('md')} {
    padding-left: 7%;
    padding-right: 7%;
  }

  ${getDownMedia('md')} {
    margin-top: 40px;
  }

  ${getDownMedia(705)} {
    margin-top: 0;
  }
`;

const commonTopAsideMarginStyles = css`
  margin-left: 30px;
  margin-right: 30px;

  ${getDownMedia('lg')} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Title = styled(DefTitle)`
  ${commonTopAsideMarginStyles};
  color: ${getColor('veniceBlue')};
`;

const Desc = styled(DefText)`
  ${commonTopAsideMarginStyles};
  margin-top: 20px;
`;

const List = styled.ul`
  ${resetListStyles};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  margin-top: 20px;
`;

const ListItem = styled.li`
  //flex: 0 0 calc(25% - 20px);
  width: calc(25% - 20px);
  padding: 25px 27px 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 12px 18px -6px #e4e8ea;

  ${getDownMedia(1745)} {
    width: calc(50% - 20px);
  }

  ${getDownMedia(705)} {
    width: 100%;
  }
`;

const ListItemIcon = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`;

const ListItemTitle = styled(DefText)`
  margin-bottom: 10px;
`;

const ListItemDesc = styled(DefText)`
  margin-bottom: 10px;
  word-wrap: break-word;
`;

const ListItemLink = styled(Link)`
  margin-top: auto;
  word-wrap: break-word;
`;

const onTidioChatClick = () => {
  if (!window.tidioChatApi) {
    console.log('NO tidioChatApi');
    return;
  }

  window.tidioChatApi.open();
};

const getContacts: (t: TFunction) => ReadonlyArray<{
  icon: string;
  title: string;
  desc: ReactNode;
  linkProps: LinkProps;
}> = (t) => [
  {
    icon: phone,
    title: 'CONTACTS_PAGE__phoneTitle',
    desc: (
      <>
        {t('CONTACTS_PAGE__phoneText1')}
        <br />
        {t('CONTACTS_PAGE__phoneText2')}
      </>
    ),
    linkProps: {
      href: 'tel:+31 20 239 6159',
      children: '+31 (0)20 239 6159',
    },
  },
  {
    icon: mail,
    title: 'CONTACTS_PAGE__mailTitle',
    desc: t('CONTACTS_PAGE__mailText'),
    linkProps: {
      href: 'mailto:support@mercuryredstone.com',
      children: 'support@mercuryredstone.com',
    },
  },
  {
    icon: chat,
    title: 'CONTACTS_PAGE__chatTitle',
    desc: (
      <>
        {t('CONTACTS_PAGE__chatText1')}
        <br />
        {t('CONTACTS_PAGE__chatText2')}
      </>
    ),
    linkProps: {
      children: t('CONTACTS_PAGE__chatLink'),
      onClick: onTidioChatClick,
    },
  },
  {
    icon: businessEmail,
    title: 'CONTACTS_PAGE__businessMailTitle',
    desc: t('CONTACTS_PAGE__businessMailText'),
    linkProps: {
      href: 'mailto:contact@mercuryredstone.com',
      children: 'contact@mercuryredstone.com',
    },
  },
];

export { ContactsPage };
