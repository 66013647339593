/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isString } from 'lodash-es';
// circular-dependency otherwise
// noinspection ES6PreferShortImport
import { paths } from '../pages/paths';
import { history } from './history';

let token: string | null = null;

export const getToken = () => {
  if (!!token) return token;
  token = localStorage.getItem('userToken');
  return token;
};

export const setToken = (newToken: string) => {
  token = newToken;
  return localStorage.setItem('userToken', token);
};

export const removeToken = () => {
  token = null;
  return localStorage.removeItem('userToken');
};

export const logout = async () => {
  history.push(paths.logout);
};

export const isValidToken = (token: string) => isString(token) && !!token;
