/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

/* eslint-disable jsx-a11y/no-autofocus */
import React, { useContext, useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import { Formik, Form as DefForm, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { serverResponseErrorsCodes } from '../../utils/consts';
import { sendSentryError } from '../../utils/sentry';
import { isServerErrorOfType } from '../../utils/server-helpers';
import { useSendRestPasswordEmailMutation } from '../../apollo';
import { AlertContext, useGoogleRecaptchaContext } from '../../providers';
import { GoogleRecaptchaV2 } from '../GoogleRecaptchaV2';
import { Button as DefButton } from '../buttons';
import { FormikInput, FormikInputProps } from '../formik-elements';

export type ResetPasswordFormProps = HTMLAttributes<HTMLFormElement> & {
  closeModal?: () => void;
};

const ResetPasswordForm = ({
  closeModal,
  ...props
}: ResetPasswordFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertContext);
  const recaptchaData = useGoogleRecaptchaContext();
  const fieldsDataByName = useMemo(() => getFieldsDataByName(t), [t]);
  const schema = useMemo(() => getSchema(t), [t]);

  useMount(() => {
    recaptchaData.initRecaptcha('resetPasswordForm');
  });

  const [sendResetPasswordEmail] = useSendRestPasswordEmailMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setAlert({
        type: 'success',
        message: t('RESET_PASSWORD_FORM__successText'),
      });

      isFunction(closeModal) && closeModal();
    },
    onError: (error) => {
      const { recaptchaError } = recaptchaData.handleCaptchaError(error);

      if (recaptchaError) return;

      const googleEmailError = isServerErrorOfType({
        error,
        type: serverResponseErrorsCodes.GOOGLE_EMAIL,
      });

      if (googleEmailError) {
        return setAlert({
          type: 'warning',
          message: t('RESET_PASSWORD_FORM__googleEmailError'),
        });
      }

      setAlert({ type: 'error', message: t('RESET_PASSWORD_FORM__errorText') });
      sendSentryError(error);
    },
  });

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        if (!recaptchaData.token) {
          setAlert({
            type: 'error',
            message: t('RESET_PASSWORD_FORM__errorText'),
          });
          sendSentryError(new Error('No captchaToken in reset password form'));
          return;
        }

        const captchaFieldName =
          recaptchaData.version === 'V3' ? 'captchaToken' : 'captchaV2Token';

        await sendResetPasswordEmail({
          variables: {
            ...values,
            [captchaFieldName]: recaptchaData.token,
          },
        });
      }}
    >
      {({ values, isSubmitting }: FormikProps<FormValues>) => (
        <Form {...props}>
          {Object.keys(values).map((name) => {
            const { extraProps } = fieldsDataByName[name];

            return (
              <Input
                key={name}
                id={`reset-password-form-${name}`}
                name={name}
                autoFocus
                disabled={extraProps.disabled || isSubmitting}
                {...extraProps}
              />
            );
          })}
          <GoogleRecaptchaV2 />
          <BottomBlock>
            <Button type={'submit'} loading={isSubmitting}>
              {t('RESET_PASSWORD_FORM__buttonText')}
            </Button>
          </BottomBlock>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = {
  email: string;
};

const getSchema = (t: TFunction): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('COMMON_FORMS_ERROR__wrongEmail'))
      .required(t('COMMON_FORMS_ERROR__required')),
  });

const Form = styled(DefForm)`
  background-color: #fff;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Input = styled(FormikInput)`
  flex: 0 0 100%;

  .MuiInputLabel-root.Mui-disabled {
    display: none;
  }
`;

const BottomBlock = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 6px;
`;

const getFieldsDataByName: (t: TFunction) => {
  [key in keyof FormValues]: {
    extraProps?: Omit<FormikInputProps, 'name'>;
  };
} = (t) => ({
  email: {
    extraProps: {
      label: t('RESET_PASSWORD_FORM__inputLabel') as string,
    },
  },
});

export { ResetPasswordForm };
