/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { FC, PropsWithChildren } from 'react';
import { AppTitle } from './AppTitle';
import { DrawerProvider } from './Drawer';
import { Exchange } from './Exchange';
import { Payments } from './Payments';
import { PortfolioCurrencyProvider } from './PortfolioCurrencyProvider';

const AppProviders: FC<PropsWithChildren> = ({ children }) => (
  <Exchange>
    <Payments>
      <DrawerProvider>
        <PortfolioCurrencyProvider>
          <AppTitle>{children}</AppTitle>
        </PortfolioCurrencyProvider>
      </DrawerProvider>
    </Payments>
  </Exchange>
);

export { AppProviders };
