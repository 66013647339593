/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMountedState } from 'react-use';
import { retry } from 'ts-retry-promise';
import { sendSentryError } from '../../utils/sentry';
import { useAlertModal } from '../../hooks';
import {
  useAdminCancelUserSubscriptionMutation,
  AdminUserStatusAndActiveSubscriptionDocument,
  AdminUserStatusAndActiveSubscriptionQuery,
  AdminUserStatusAndActiveSubscriptionQueryVariables,
} from '../../apollo';
import { useAlert, useModals } from '../../providers';
import { Button as DefButton } from '../buttons';

const DisableSubscriptionAdminModal = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { data: { userId, exchangeId } = {}, dispatch: dispatchModal } =
    useModals<'disableSubscriptionAdmin'>();
  const { dispatch: dispatchAlertModal } = useAlertModal();
  const isMounted = useMountedState();

  const [disablingSubscription, setDisablingSubscription] = useState(false);

  useEffect(() => {
    dispatchModal({
      type: 'setWrapperProps',
      payload: {
        modalName: 'disable-subscription-admin',
        titleText: t('DISABLE_SUBCRIPTION_ADMIN_MODAL__title'),
      },
    });
  }, [dispatchModal, t]);

  const active = !!userId && !!exchangeId;

  const [disableSubscriptionMutation, { client }] =
    useAdminCancelUserSubscriptionMutation();

  const disableSubscription = async () => {
    const showError = () => {
      setAlert({
        type: 'error',
        message: t('DISABLE_SUBCRIPTION_ADMIN_MODAL__error'),
      });
    };

    if (!active) {
      showError();
      return;
    }

    setDisablingSubscription(true);

    try {
      const { errors } = await disableSubscriptionMutation({
        variables: {
          userId,
          exchangeId,
        },
      });

      if (!isEmpty(errors)) throw new Error();

      await retry(
        async () => {
          const { errors, data: { adminGetUser: user } = {} } =
            await client.query<
              AdminUserStatusAndActiveSubscriptionQuery,
              AdminUserStatusAndActiveSubscriptionQueryVariables
            >({
              query: AdminUserStatusAndActiveSubscriptionDocument,
              fetchPolicy: 'network-only',
              variables: {
                userId,
              },
            });

          if (!isEmpty(errors) || !user) throw new Error();

          if (user.hasActiveSubscription) {
            throw new Error('hasActiveSubscription is not yet updated');
          }

          dispatchModal({ type: 'closeModal' });
          dispatchAlertModal([
            'setModalContent',
            'subscriptionDisabledSuccess',
          ]);
        },
        {
          delay: 800,
          retries: 30,
        }
      );
    } catch (e) {
      showError();
      sendSentryError(e);
    } finally {
      isMounted() && setDisablingSubscription(false);
    }
  };

  return (
    <Button
      loading={disablingSubscription}
      disabled={!active}
      onClick={() => {
        disableSubscription();
      }}
    >
      {t('DISABLE_SUBCRIPTION_ADMIN_MODAL__button')}
    </Button>
  );
};

export type DisableSubscriptionAdminModalData = {
  userId: number;
  exchangeId: number;
};

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 6px;
`;

export { DisableSubscriptionAdminModal };
