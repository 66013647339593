/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { css } from 'styled-components';

export const lineStyles = css`
  content: '';
  display: block;
  width: 50px;
  height: 5px;
`;
