/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled, { css } from 'styled-components';
import { isFunction } from 'lodash-es';
import { Link as DefLink, LinkProps as DefLinkProps } from '@mui/material';
import { getColor } from '../../styles';
import { DoubleRightIcon as DefDoubleRightIcon } from '../icons';

export type LinkProps = DefLinkProps & {
  withDoubleLeftArrow?: boolean;
  withDoubleRightArrow?: boolean;
  disabled?: boolean;
};

const Link = ({
  children,
  withDoubleLeftArrow = false,
  withDoubleRightArrow = false,
  disabled = false,
  onClick,
  ...props
}: LinkProps) => (
  <StyledLink
    $disabled={disabled}
    {...(isFunction(onClick) && !disabled ? { onClick } : {})}
    {...props}
  >
    {withDoubleLeftArrow && (
      <>
        <DoubleLeftIcon />{' '}
      </>
    )}
    {children}
    {withDoubleRightArrow && (
      <>
        {' '}
        <DoubleRightIcon />
      </>
    )}
  </StyledLink>
);

const DoubleRightIcon = styled(DefDoubleRightIcon)``;

const DoubleLeftIcon = styled(DoubleRightIcon)`
  transform: rotate(180deg);
`;

const StyledLink = styled(DefLink)<{
  $disabled: boolean;
}>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${getColor('gray')};
      cursor: default;
      &:hover {
        color: ${getColor('gray')};
      }
    `}
  ${DoubleRightIcon} {
    width: 14px;
    height: 14px;
    color: inherit;
    position: relative;
    left: -1px;
    bottom: -3px;
  }

  ${DoubleLeftIcon} {
    left: 0;
  }
`;

export { Link };
