/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getAdminSendEmailsFormSchema } from '../../utils/validation-schemas';
import { getDownMedia } from '../../styles';
import { useCustomFormik } from '../../hooks/useCustomFormik';
import { Button as DefButton } from '../buttons';
import { Input as DefInput } from '../form-elements';

export type AdminSendEmailsFormProps = {
  handleSendAdminEmails: HandleSendAdminEmailsFn;
  active: boolean;
} & HTMLAttributes<HTMLFormElement>;

const AdminSendEmailsForm = ({
  handleSendAdminEmails,
  active,
  ...props
}: AdminSendEmailsFormProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getAdminSendEmailsFormSchema(t), [t]);
  const {
    values: { templateId: templateIdValue },
    errors: { templateId: templateIdError },
    submitForm,
    handleChange,
  } = useCustomFormik(
    {
      initialValues: {
        templateId: '',
      },
      validationSchema: schema,
      onSubmit: ({ templateId }) => {
        handleSendAdminEmails(templateId);
      },
    },
    { templateId: 'admin_email-templateId-input' },
    true
  );

  return (
    <Form
      onSubmit={(e): void => {
        e.preventDefault();
        submitForm();
      }}
      {...props}
    >
      <Input
        name={'templateId'}
        label={t('ADMIN_SEND_EMAILS_FORM__templateIDLabel')}
        value={templateIdValue}
        {...(templateIdError
          ? { error: true, helperText: templateIdError }
          : {})}
        onChange={handleChange}
      />
      <Button disabled={active} type={'submit'}>
        {t('ADMIN_SEND_EMAILS_FORM__buttonText')}
      </Button>
    </Form>
  );
};

export type AdminSendEmailsFormValues = {
  templateId: string;
};

export type HandleSendAdminEmailsFn = (templateId: string) => void;

const Form = styled.form`
  display: flex;
  align-items: flex-start;
  ${getDownMedia('xs')} {
    flex-wrap: wrap;
  }
`;

const Input = styled(DefInput)`
  margin: 0;
`;

const Button = styled(DefButton)`
  height: 56px;
  margin-left: 10px;

  ${getDownMedia('md')} {
    margin-left: 20px;
  }

  ${getDownMedia('xs')} {
    margin-left: 0;
  }
`;

export { AdminSendEmailsForm };
