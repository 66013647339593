/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { css } from 'styled-components';
import { semiBoldText } from '../utils/fonts';

export const bottomButtonStyles = css`
  height: 51.625px;
  margin-top: auto;
  text-transform: uppercase;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    max-width: none;
    margin-top: 22px;
  }

  .MuiButton-root {
    letter-spacing: 0;
    text-transform: none;
    ${semiBoldText};
  }
`;

export const commonBottomBlocksTextStyles = css`
  ${({ theme: { breakpoints } }) => breakpoints.between('sm', 'md')} {
    margin-right: 54px;
  }
`;
