/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { getPercentages } from '../../utils/percentages';
import {
  BlockData,
  BlockIcon,
  BlockInfo,
  DesktopBlock,
  GraphIconSummary,
} from './styled';

export type Change24PercentInfoProps = {
  data: number;
} & HTMLAttributes<HTMLLIElement>;

const Change24PercentInfo = ({ data, ...props }: Change24PercentInfoProps) => {
  const { t } = useTranslation();

  const positiveValue = data !== 0 ? data > 0 : undefined;

  return (
    <DesktopBlock {...props}>
      <BlockData positiveValue={positiveValue}>
        {getPercentages(data, { withSign: true })}
      </BlockData>
      <BlockIcon>
        <GraphIconSummary />
      </BlockIcon>
      <BlockInfo>{t('DASHBOARD_PAGE__summaryChangePercent')}</BlockInfo>
    </DesktopBlock>
  );
};

export { Change24PercentInfo };
