/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

export const colors = {
  white: '#FFFFFF',
  black: '#161616',
  darkBlue: '#0a355a', // new color
  blue: '#1588f9', // was #1EAAFC
  blue2: '#36A1FF', // was #3AB047 - green, for hovering
  blueRibbon: '#0051fe', // bitvavo
  veniceBlue: '#0a4981',
  lightBlue: '#F6F8FB', // was #ECF6FE
  blueBayoux: '#4E6780',
  purpleHeart: '#5741d8', // kraken
  green: '#3AB047',
  beige: '#B7B7B7',
  lightGray: '#EFEFEF',
  lightGreen: '#90ee90',
  mercury: '#E2E2E2',
  porcelain: '#E4E8EA',
  gray: '#acb6c1',
  shuttleGray: '#5E6D7D',
  skyBlue: '#36a1ff',
  boulder: '#787878',
  // it's applied
  orange: '#D6441A',
  red: '#E73324',
  lightRed: '#da0000',
  flamingo: '#f46036',
} as const;

export type ColorName = keyof typeof colors;
export type ColorValue = typeof colors[ColorName];
