/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { FC, PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';

const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => (
  <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
    {children}
  </Sentry.ErrorBoundary>
);

export { ErrorBoundary };
