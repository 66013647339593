/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState, useCallback } from 'react';
import { downloadFile } from '../utils/download-file';

export const useDownloadFile = (filePath: string) => {
  const [fileIsLoading, setFileIsLoading] = useState(false);

  const loadFile = useCallback(async () => {
    if (fileIsLoading) return;

    setFileIsLoading(true);
    await downloadFile(filePath);
    setFileIsLoading(false);
  }, [filePath, fileIsLoading]);

  return {
    loadFile,
    fileIsLoading,
  };
};
