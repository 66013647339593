/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 257px;
`;

type Props = {
  className?: string;
  color?: string;
  onClick?: () => void;
};

const HorizontalTextLogo = ({
  className,
  color = '#161616',
  onClick,
}: Props) => (
  <Svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 13"
    onClick={onClick}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M10.477 1L6.515 8.918 2.539 1H1v11h1.181V2.933l3.945 7.904h.746l3.963-7.904.014 9.067H12V1z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M10.849 12l-.015-9.067-3.962 7.904h-.746L2.181 2.933V12H1V1h1.538l3.977 7.919L10.477 1H12v11z"
      />
      <path
        fill={color}
        d="M21 1v11h7v-1.193h-5.852V7.035h5.063v-1.21h-5.063V2.209v.001h5.665V1z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M22.259 2.56v3.409h5.364V7.11h-5.364v3.558h6.199v1.126h-7.415V1.419h7.218v1.142h-6.002z"
      />
      <path
        fill={color}
        d="M41.055 7.378c1.93 0 3.023-.83 3.023-2.446 0-1.571-1.094-2.372-3.023-2.372H38.23v4.818h2.826zm.715 1.112c-.228.014-.472.029-.715.029H38.23v3.276h-1.216V1.42h4.042c2.66 0 4.193 1.275 4.193 3.484 0 1.719-.85 2.874-2.385 3.365l2.492 3.528h-1.383L41.77 8.49z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M41.055 7.378c1.93 0 3.023-.83 3.023-2.446 0-1.571-1.094-2.372-3.023-2.372H38.23v4.818h2.826zm.715 1.112c-.228.014-.472.029-.715.029H38.23v3.276h-1.216V1.42h4.042c2.66 0 4.193 1.275 4.193 3.484 0 1.719-.85 2.874-2.385 3.365l2.492 3.528h-1.383L41.77 8.49z"
      />
      <path
        fill={color}
        d="M62.51 2.901l-.73.904a4.524 4.524 0 00-3.145-1.304c-2.4 0-4.33 1.808-4.33 4.091 0 2.267 1.929 4.09 4.33 4.09a4.7 4.7 0 003.146-1.244l.744.815c-1.049.963-2.477 1.6-3.95 1.6-3.07 0-5.5-2.326-5.5-5.26 0-2.908 2.46-5.204 5.545-5.204A5.834 5.834 0 0162.51 2.9"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M62.51 2.901l-.73.904a4.524 4.524 0 00-3.145-1.304c-2.4 0-4.33 1.808-4.33 4.091 0 2.267 1.929 4.09 4.33 4.09a4.7 4.7 0 003.146-1.244l.744.815c-1.049.963-2.477 1.6-3.95 1.6-3.07 0-5.5-2.326-5.5-5.26 0-2.908 2.46-5.204 5.545-5.204A5.834 5.834 0 0162.51 2.9z"
      />
      <path
        fill={color}
        d="M79.482 7.526c0 2.698-1.67 4.33-4.451 4.33-2.796 0-4.482-1.632-4.482-4.33V1.42h1.215v6.107c0 2.015 1.246 3.187 3.267 3.187 2.02 0 3.236-1.17 3.236-3.187V1.42h1.215v6.107z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M79.482 7.526c0 2.698-1.67 4.33-4.451 4.33-2.796 0-4.482-1.632-4.482-4.33V1.42h1.215v6.107c0 2.015 1.246 3.187 3.267 3.187 2.02 0 3.236-1.17 3.236-3.187V1.42h1.215v6.107z"
      />
      <path
        fill={color}
        d="M92.581 7.378c1.93 0 3.024-.83 3.024-2.446 0-1.571-1.094-2.372-3.024-2.372h-2.826v4.818h2.826zm.715 1.112c-.229.014-.471.029-.715.029h-2.826v3.276H88.54V1.42h4.042c2.66 0 4.194 1.275 4.194 3.484 0 1.719-.851 2.874-2.386 3.365l2.492 3.528h-1.383L93.296 8.49z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M92.581 7.378c1.93 0 3.024-.83 3.024-2.446 0-1.571-1.094-2.372-3.024-2.372h-2.826v4.818h2.826zm.715 1.112c-.229.014-.471.029-.715.029h-2.826v3.276H88.54V1.42h4.042c2.66 0 4.194 1.275 4.194 3.484 0 1.719-.851 2.874-2.386 3.365l2.492 3.528h-1.383L93.296 8.49z"
      />
      <path
        fill={color}
        d="M109.235 11.795h-1.2V8.282l-4.117-6.863h1.261l3.449 5.41 3.403-5.41h1.246l-4.042 6.818z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M109.235 11.795h-1.2V8.282l-4.117-6.863h1.261l3.449 5.41 3.403-5.41h1.246l-4.042 6.818z"
      />
      <path
        fill={color}
        d="M135.325 7.378c1.929 0 3.023-.83 3.023-2.446 0-1.571-1.094-2.372-3.023-2.372h-2.827v4.818h2.827zm.715 1.112c-.229.014-.471.029-.714.029h-2.827v3.276h-1.216V1.42h4.043c2.658 0 4.192 1.275 4.192 3.484 0 1.719-.851 2.874-2.386 3.365l2.492 3.528h-1.383L136.04 8.49z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M135.325 7.378c1.929 0 3.023-.83 3.023-2.446 0-1.571-1.094-2.372-3.023-2.372h-2.827v4.818h2.827zm.715 1.112c-.229.014-.471.029-.714.029h-2.827v3.276h-1.216V1.42h4.043c2.658 0 4.192 1.275 4.192 3.484 0 1.719-.851 2.874-2.386 3.365l2.492 3.528h-1.383L136.04 8.49z"
      />
      <path
        fill={color}
        d="M149.563 2.56v3.409h5.363V7.11h-5.363v3.558h6.199v1.126h-7.415V1.419h7.217v1.142h-6.001z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M149.563 2.56v3.409h5.363V7.11h-5.363v3.558h6.199v1.126h-7.415V1.419h7.217v1.142h-6.001z"
      />
      <path
        fill={color}
        d="M165.532 10.669h3.1c2.37 0 4.224-1.735 4.224-4.046 0-2.328-1.884-4.063-4.27-4.063h-3.053v8.109zm3.085-9.25c3.1 0 5.47 2.238 5.47 5.188 0 2.95-2.385 5.188-5.515 5.188h-4.255V1.419h4.3z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M165.532 10.669h3.1c2.37 0 4.224-1.735 4.224-4.046 0-2.328-1.884-4.063-4.27-4.063h-3.053v8.109zm3.085-9.25c3.1 0 5.47 2.238 5.47 5.188 0 2.95-2.385 5.188-5.515 5.188h-4.255V1.419h4.3z"
      />
      <path
        fill={color}
        d="M188.916 2.337l-.515 1.113a5.601 5.601 0 00-2.933-.875c-1.367 0-2.264.504-2.264 1.379 0 2.668 5.987 1.275 5.971 5.04 0 1.733-1.564 2.845-3.845 2.845-1.548 0-3.07-.652-4.07-1.585l.546-1.068c.988.934 2.338 1.467 3.54 1.467 1.535 0 2.493-.608 2.493-1.6.013-2.728-5.972-1.276-5.972-4.996 0-1.631 1.473-2.684 3.677-2.684 1.231.002 2.492.387 3.372.964"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M188.916 2.337l-.515 1.113a5.601 5.601 0 00-2.933-.875c-1.367 0-2.264.504-2.264 1.379 0 2.668 5.987 1.275 5.971 5.04 0 1.733-1.564 2.845-3.845 2.845-1.548 0-3.07-.652-4.07-1.585l.546-1.068c.988.934 2.338 1.467 3.54 1.467 1.535 0 2.493-.608 2.493-1.6.013-2.728-5.972-1.276-5.972-4.996 0-1.631 1.473-2.684 3.677-2.684 1.231.002 2.492.387 3.372.964z"
      />
      <path
        fill={color}
        d="M200.982 2.56v9.235h-1.216V2.56h-3.465V1.418h8.159V2.56z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M200.982 2.56v9.235h-1.216V2.56h-3.465V1.418h8.159V2.56z"
      />
      <path
        fill={color}
        d="M211.847 6.607c0 2.282 1.975 4.106 4.344 4.106 2.37 0 4.315-1.823 4.315-4.106s-1.945-4.091-4.315-4.091c-2.369 0-4.344 1.808-4.344 4.091m9.905 0c0 2.92-2.46 5.247-5.558 5.247-3.116 0-5.577-2.327-5.577-5.247 0-2.935 2.461-5.217 5.577-5.217 3.1 0 5.558 2.296 5.558 5.217"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M211.847 6.607c0 2.282 1.975 4.106 4.344 4.106 2.37 0 4.315-1.823 4.315-4.106s-1.945-4.091-4.315-4.091c-2.369 0-4.344 1.808-4.344 4.091zm9.905 0c0 2.92-2.46 5.247-5.558 5.247-3.116 0-5.577-2.327-5.577-5.247 0-2.935 2.461-5.217 5.577-5.217 3.1 0 5.558 2.296 5.558 5.217z"
      />
      <path
        fill={color}
        d="M237.646 11.795l-6.38-8.33v8.33h-1.216V1.419h1.261l6.381 8.346V1.419h1.2v10.376z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M237.646 11.795l-6.38-8.33v8.33h-1.216V1.419h1.261l6.381 8.346V1.419h1.2v10.376z"
      />
      <path
        fill={color}
        d="M249.424 2.56v3.409h5.363V7.11h-5.363v3.558h6.199v1.126h-7.414V1.419h7.216v1.142h-6.001z"
      />
      <path
        stroke={color}
        strokeWidth="0.5"
        d="M249.424 2.56v3.409h5.363V7.11h-5.363v3.558h6.199v1.126h-7.414V1.419h7.216v1.142h-6.001z"
      />
    </g>
  </Svg>
);

export { HorizontalTextLogo };
