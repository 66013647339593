/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { getDownMedia } from '../../styles';
import { TableCell } from './TableCell';

export const NameCell = styled(TableCell)`
  padding: 12px 12px 12px 4px;
  background-color: #fff;

  ${getDownMedia('md')} {
    padding-right: 6px;
    position: sticky;
    left: 0;
    z-index: 1;
  }

  > div {
    width: 210px;
    display: flex;
    align-items: center;

    ${getDownMedia(1350)} {
      width: 120px;
    }

    ${getDownMedia('md')} {
      width: 150px;
    }

    ${getDownMedia('sm')} {
      width: 110px;
    }
  }
`;
