/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled, { css } from 'styled-components';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { CloseIcon as DefCloseIcon } from '../icons';

export type ModalCloseButtonProps = IconButtonProps & {
  cssProps?: ReturnType<typeof css>;
  withTopImg?: boolean;
};

const ModalCloseButton = ({
  cssProps,
  withTopImg = false,
  ...props
}: ModalCloseButtonProps) => (
  <Button
    aria-label={'close'}
    $cssProps={cssProps}
    $withTopImg={withTopImg}
    {...props}
  >
    <CloseIcon />
  </Button>
);

const CloseIcon = styled(DefCloseIcon)``;

const Button = styled(IconButton)<{
  $cssProps?: ReturnType<typeof css>;
  $withTopImg: boolean;
}>`
  padding: 12px;
  position: absolute;

  ${({ $withTopImg }) =>
    $withTopImg
      ? css`
          top: -35px;
          right: -12px;
          ${CloseIcon} {
            color: #fff;
          }
        `
      : css`
          top: 5px;
          right: 5px;
        `};

  ${({ $cssProps }) => $cssProps};
`;

export { ModalCloseButton };
