/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDownMedia } from '../../styles';
import sadFace from '../../assets/images/sad-face-in-circle.svg';
import {
  ContentModalWhiteContent as DefContentModalWhiteContent,
  ContentModalWrapper as DefContentModalWrapper,
} from '../modal-elements';
import { Text as DefText } from '../texts';

export type ServerIsDownModalProps = HTMLAttributes<HTMLDivElement>;

const ServerIsDownModal = (props: ServerIsDownModalProps) => {
  const { t } = useTranslation();
  return (
    <ContentModalWrapper opened {...props}>
      <ContentModalWhiteContent>
        <Img alt={'Sad face'} src={sadFace} />
        <Title variant={'h2'}>{t('SERVER_ERROR_MODAL__title')}</Title>
        <Text>{t('SERVER_ERROR_MODAL__desc1')}</Text>
      </ContentModalWhiteContent>
    </ContentModalWrapper>
  );
};

const ContentModalWrapper = styled(DefContentModalWrapper)`
  ${getDownMedia('xs')} {
    background-color: #fff;
  }
`;

const ContentModalWhiteContent = styled(DefContentModalWhiteContent)`
  padding-top: 52px;
  padding-bottom: 62px;
  align-items: center;
  border-radius: 3px;
  ${getDownMedia('sm')} {
    margin: auto;
  }
`;

const Img = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`;

const Title = styled(DefText)`
  margin-bottom: 30px;
  text-align: center;
`;

const Text = styled(DefText)`
  margin: 0 42px;
  text-align: center;
  ${getDownMedia('sm')} {
    margin: 0;
  }
`;

export { ServerIsDownModal };
