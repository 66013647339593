/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import DefHoverPopover from 'material-ui-popup-state/HoverPopover';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import { exchangesCodesTranslations } from '../../utils/translations';
import { ExchangeCode } from '../../apollo';
import { resetListStyles, getColor } from '../../styles';
import { useExchange } from '../../providers';
import bitvavoLogo from '../../assets/images/bitvavo-logo-with-text.svg';
import krakenLogo from '../../assets/images/kraken-logo-with-text.svg';
import { Text, TextProps } from '../texts';

export type ExchangesMenuProps = TextProps;

const ExchangesMenu = (props: ExchangesMenuProps) => {
  const { t } = useTranslation();
  const { exchange, setExchange } = useExchange();

  return (
    <PopupState variant="popover" popupId="exchangesPopover">
      {(popupState) => (
        <>
          <CurrentExchange variant={'h3'} {...props} {...bindHover(popupState)}>
            {t('EXCHANGES_MENU__platform')}{' '}
            {t(
              exchangesCodesTranslations[
                exchange?.exchangeCode ?? ExchangeCode.Kraken
              ]
            )}
            <KeyboardArrowDownOutlined fontSize={'small'} />
          </CurrentExchange>
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <List>
              {Object.entries(exchangesMenuItems).map(
                ([code, { label, logo }]) => (
                  <ListItem key={code}>
                    <Button
                      aria-label={label}
                      onClick={() => {
                        exchange?.exchangeCode !== code &&
                          setExchange(code as ExchangeCode);
                        popupState.close();
                      }}
                    >
                      <img src={logo} alt={label} />
                    </Button>
                  </ListItem>
                )
              )}
            </List>
          </HoverPopover>
        </>
      )}
    </PopupState>
  );
};

const CurrentExchange = styled(Text)`
  color: #fff;
  align-self: stretch;
  display: flex;
  align-items: center;
`;

const HoverPopover = styled(DefHoverPopover)`
  margin-top: 6px;
  .MuiPaper-root {
    overflow-x: visible;
    &:before {
      content: '';
      position: absolute;
      left: 20%;
      margin-left: -20px;
      top: -18px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 18px solid #fff;
    }

    &:after {
      content: '';
      width: 100%;
      background-color: transparent;
      height: 20px;
      position: absolute;
      top: -20px;
      left: 0;
    }
  }
`;

const List = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-direction: column;
  padding-top: 4px;
`;

const ListItem = styled.li`
  overflow: hidden;

  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const Button = styled(ButtonBase)`
  width: 250px;
  height: 64px;
  padding: 10px 20px 14px;
  justify-content: flex-start;

  &:hover {
    background-color: ${getColor('porcelain')};
  }

  img {
    width: auto;
    height: 17px;
  }
`;

const exchangesMenuItems: Readonly<{
  [key in ExchangeCode]: {
    logo: string;
    label: string;
  };
}> = {
  [ExchangeCode.Bitvavo]: { logo: bitvavoLogo, label: 'bitvavo' },
  [ExchangeCode.Kraken]: { logo: krakenLogo, label: 'kraken' },
};

export { ExchangesMenu };
