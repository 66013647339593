/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
  PropsWithChildren,
} from 'react';
import { useAsync } from 'react-use';
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useAuthVar, setAuthVar } from '../utils/apollo-vars';
import { getToken } from '../utils/auth';
import { isServerError } from '../utils/helpers';
import { UserIdDocument, UserIdQuery } from '../apollo';

const Auth: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const authorized = useAuthVar();
  const client = useApolloClient();

  useEffect(() => {
    if (!authorized) {
      Sentry.configureScope((scope) => scope.setUser(null));
    } else {
      client.query<UserIdQuery>({ query: UserIdDocument }).then(
        ({
          data: {
            getUser: { id },
          },
        }) => {
          !!id && Sentry.setUser({ id });
        }
      );
    }
  }, [authorized, client]);

  useAsync(async () => {
    let mounted = true;
    const token = getToken();
    try {
      if (!token) return;
      await client.query<UserIdQuery>({
        query: UserIdDocument,
        fetchPolicy: 'network-only',
      });
      mounted && setAuthVar(true);
    } catch (e) {
      if ([mounted, token, isServerError(e)].every(Boolean)) {
        setAuthVar(true);
      }
    } finally {
      mounted && setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, []);

  if (loading) return null;

  return (
    <AuthContext.Provider value={authorized}>{children}</AuthContext.Provider>
  );
};

const AuthContext = createContext(false);

export const useAuthContext = () => useContext(AuthContext);

export { Auth };
