/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled from 'styled-components';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FormikProps, FormikFormProps } from 'formik';
import { TFunction } from 'i18next';
import { removeToken } from '../../utils/auth';
import { serverResponseErrorsCodes } from '../../utils/consts';
import { sendSentryError } from '../../utils/sentry';
import { getErrorCodesOfServerResponse } from '../../utils/server-helpers';
import { getSetNewPasswordFormSchema } from '../../utils/validation-schemas';
import { useResetPasswordMutation } from '../../apollo';
import { useAlert } from '../../providers';
import { paths } from '../../pages';
import { Button as DefButton } from '../buttons';
import { PasswordField, PasswordFieldProps } from '../form-elements';

const SetNewPasswordForm = (props: FormikFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const navigate = useNavigate();

  const [resetPassword] = useResetPasswordMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      removeToken();
      setAlert({
        type: 'success',
        message: t('SET_NEW_PASSWORD_FORM__successText'),
      });
      navigate(paths.login);
    },
    onError: (error) => {
      const timeoutError = getErrorCodesOfServerResponse(error).some(
        (er) => er === serverResponseErrorsCodes.RESET_PASSWORD_TIMEOUT
      );

      setAlert({
        type: 'error',
        message: t(
          timeoutError
            ? 'SET_NEW_PASSWORD_FORM__timeoutErrorText'
            : 'SET_NEW_PASSWORD_FORM__errorText'
        ),
      });

      sendSentryError(error);
    },
  });

  const { fieldsProps, schema } = useMemo(
    () => ({
      fieldsProps: getFieldPropsByName(t),
      schema: getSetNewPasswordFormSchema(t),
    }),
    [t]
  );

  // @ts-ignore
  return (
    <Formik
      initialValues={{
        password: '',
        confPassword: '',
      }}
      onSubmit={async (values) => {
        await resetPassword({ variables: values });
      }}
      validationSchema={schema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }: FormikProps<SetNewPasswordFormValues>) => (
        <Wrapper {...props} onSubmit={handleSubmit}>
          {Object.entries(values).map(([name, value]) => {
            const props = get(fieldsProps, name, {});

            return (
              <PasswordField
                key={name}
                id={`set-new-password-form-${name}`}
                type={'password'}
                name={name}
                value={value}
                disabled={isSubmitting}
                helperText={touched[name] && !!errors[name] && errors[name]}
                error={touched[name] && !!errors[name]}
                onChange={handleChange}
                {...props}
              />
            );
          })}
          <Button
            type={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('SET_NEW_PASSWORD_FORM__buttonText')}
          </Button>
        </Wrapper>
      )}
    </Formik>
  );
};

export type SetNewPasswordFormValues = {
  password: string;
  confPassword: string;
};

const Wrapper = styled(Form)`
  display: flex;
  flex-wrap: wrap;
`;

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 9px;
`;

const getFieldPropsByName: (t: TFunction) => {
  [key in keyof SetNewPasswordFormValues]: PasswordFieldProps;
} = (t) => ({
  password: {
    label: t<string>('COMMON_FORMS__passwordFieldPlaceholder'),
  },
  confPassword: {
    label: t<string>('COMMON_FORMS__confirmPasswordFieldPlaceholder'),
  },
});

export { SetNewPasswordForm };
