/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { PortfolioCurrency, DashboardDataQuery } from '../../apollo';
import { usePortfolioCurrency, useExchange } from '../../providers';
import {
  DollarIcon,
  EuroIcon,
  GraphIconSummary,
  PieIcon,
} from '../summary/styled';
import {
  Change24AmountInfo,
  Change24Mobile,
  Change24PercentInfo,
  CostInfo,
  RebalanceInfo,
} from '../summary';

type PortfolioSummaryProps = {
  data: DashboardDataQuery['getPortfolioTotals'];
} & HTMLAttributes<HTMLUListElement>;

const PortfolioSummary = ({
  data: portfolio,
  ...props
}: PortfolioSummaryProps) => {
  const { exchange } = useExchange();
  const { currency } = usePortfolioCurrency();

  const aumBelowMinimum = !!exchange?.aumBelowMinimum;

  const { totalCost, change24Amount, change24Percent } = useMemo(() => {
    if (!portfolio)
      return { totalCost: 0, change24Amount: 0, change24Percent: 0 };

    return currency === PortfolioCurrency.Eur
      ? {
          totalCost: portfolio.eurCostTotal,
          change24Amount: portfolio.eurCostChange24Total,
          change24Percent: portfolio.eurCostChange24Percentage,
        }
      : {
          totalCost: portfolio.usdCostTotal,
          change24Amount: portfolio.usdCostChange24total,
          change24Percent: portfolio.usdCostChange24Percentage,
        };
  }, [portfolio, currency]);

  if (!portfolio) return null;

  return (
    <Wrapper {...props}>
      {blocks.map(({ type, Component }, index) => {
        switch (type) {
          case 'rebalance':
            return (
              !!exchange?.nextRebalanceDate && (
                <Component
                  key={index}
                  // @ts-ignore
                  data={{
                    aumBelowMinimum,
                    date: exchange.nextRebalanceDate,
                  }}
                />
              )
            );
          case 'cost':
            // @ts-ignore
            return <Component key={index} data={totalCost} />;
          case 'change24Amount':
            // @ts-ignore
            return <Component key={index} data={change24Amount} />;
          case 'change24Percent':
            // @ts-ignore
            return <Component key={index} data={change24Percent} />;
          case 'change24Mobile':
            return (
              <Component
                key={index}
                // @ts-ignore
                data={{
                  change24Percent,
                  change24Amount,
                }}
              />
            );
          default:
            return null;
        }
      })}
    </Wrapper>
  );
};

const iconFillColor = css`
  fill: ${({ theme }) => theme.palette.secondary.main};
`;

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  ${EuroIcon}, ${DollarIcon}, ${PieIcon}, ${GraphIconSummary} {
    width: 12px;
    height: 12px;
    ${iconFillColor}

    ${({ theme }) => theme.breakpoints.down('lg')} {
      width: 14px;
      height: 14px;
    }
  }
`;

const blocks = [
  { type: 'rebalance', Component: RebalanceInfo },
  { type: 'cost', Component: CostInfo },
  { type: 'change24Amount', Component: Change24AmountInfo },
  { type: 'change24Percent', Component: Change24PercentInfo },
  { type: 'change24Mobile', Component: Change24Mobile },
] as const;

export { PortfolioSummary };
