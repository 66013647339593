/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { useField } from 'formik';
import { CheckboxWithLabel, CheckboxWithLabelProps } from '../form-elements';

export type FormikCheckboxWithLabelProps = Omit<
  CheckboxWithLabelProps,
  'onChange'
> & {
  name: string;
};

const FormikCheckboxWithLabel = ({
  name,
  ...props
}: FormikCheckboxWithLabelProps) => {
  const [field, { touched, error }] = useField(name);

  return (
    <CheckboxWithLabel
      {...field}
      {...props}
      {...(touched && !!error
        ? {
            error: true,
            helperText: error,
          }
        : {})}
    />
  );
};

export { FormikCheckboxWithLabel };
