/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { css } from 'styled-components';

export const adminUsersTableExchangeIcon = css`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
`;
