/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikProps, FormikFormProps } from 'formik';
import { TFunction } from 'i18next';
import { sendSentryError } from '../../utils/sentry';
import { getUserProfileFormSchema } from '../../utils/validation-schemas';
import {
  useUserProfileQuery,
  useEditUserProfileMutation,
  UserProfileQuery,
} from '../../apollo';
import { getDownMedia } from '../../styles';
import { useAlert } from '../../providers';
import { commonInputHalfWidthStyles } from '../../styles/account-page';
import { Button } from '../buttons';
import {
  FormikInput,
  FormikCountriesSelect,
  FormikInputProps,
  FormikCountriesSelectProps,
} from '../formik-elements';
import { SmallInputPlaceholderText } from '../texts';

const UserProfileForm = (props: FormikFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const schema = useMemo(() => getUserProfileFormSchema(t), [t]);
  const dataByFieldName = useMemo(() => getDataByFieldName(t), [t]);

  const { data: { getUser: user } = {} } = useUserProfileQuery({
    fetchPolicy: 'cache-and-network',
  });

  const initialValues = useMemo(() => {
    if (!user) return defInitialFormValues;
    return Object.entries(defInitialFormValues).reduce(
      (acc, [name, defValue]) => ({
        ...acc,
        [name]: user[name] ?? defValue,
      }),
      {} as UserProfileFormValues
    );
  }, [user]);

  const [editUser] = useEditUserProfileMutation({
    onCompleted: () => {
      setAlert({
        type: 'success',
        message: t('USER_PROFILE_FORM__successText'),
      });
    },
    onError: (error) => {
      setAlert({
        type: 'error',
        message: t('USER_PROFILE_FORM__errorText'),
      });
      sendSentryError(error);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async (values) => {
        if (!isEqual(initialValues, values)) {
          await editUser({ variables: values });
        } else {
          setAlert({
            type: 'info',
            message: 'Same values',
          });
        }
      }}
    >
      {({ isSubmitting }: FormikProps<UserProfileFormValues>) => (
        <Form {...props}>
          {Object.entries(dataByFieldName).map(([name, data]) => {
            const { Component = Input, extraProps } = data;

            return (
              <Component
                key={name}
                id={`profile-form-${name}`}
                name={name}
                disabled={isSubmitting}
                {...extraProps}
              />
            );
          })}
          <BottomBlock>
            <Button type={'submit'} loading={isSubmitting}>
              {t('USER_PROFILE_FORM__buttonText')}
            </Button>
          </BottomBlock>
        </Form>
      )}
    </Formik>
  );
};

export type UserProfileFormValues = Required<
  Omit<UserProfileQuery['getUser'], '__typename' | 'id' | 'amountToInvest'>
>;

const Form = styled(DefForm)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Input = styled(FormikInput)`
  ${commonInputHalfWidthStyles};
`;

const CountriesSelect = styled(FormikCountriesSelect)`
  ${commonInputHalfWidthStyles};
`;

const BottomBlock = styled.div`
  display: flex;
  flex: 0 0 100%;
  justify-content: flex-end;
  margin-top: 20px;

  ${getDownMedia('sm')} {
    margin-top: 12px;
  }
`;

const getDataByFieldName: (t: TFunction) => {
  [key in keyof UserProfileFormValues]: {
    Component?: typeof FormikCountriesSelect;
    extraProps?: Omit<FormikInputProps, 'name'> | FormikCountriesSelectProps;
  };
} = (t) => ({
  firstName: {
    extraProps: {
      label: t<string>('COMMON_FORMS__firstNameFieldPlaceholder'),
    },
  },
  lastName: {
    extraProps: {
      label: t<string>('COMMON_FORMS__lastNameFieldPlaceholder'),
    },
  },
  companyName: {
    extraProps: {
      label: (
        <>
          {t('COMMON_FORMS__companyNamePlaceholder')}{' '}
          <SmallInputPlaceholderText>
            {t<string>('COMMON_FORMS__companyNameDescPlaceholder')}
          </SmallInputPlaceholderText>
        </>
      ),
    },
  },
  vatNumber: {
    extraProps: {
      label: (
        <>
          {t('COMMON_FORMS__vatNumberPlaceholder')}{' '}
          <SmallInputPlaceholderText>
            {t<string>('COMMON_FORMS__vatNumberDescPlaceholder')}
          </SmallInputPlaceholderText>
        </>
      ),
    },
  },
  address: {
    extraProps: {
      label: t<string>('COMMON_FORMS__addressFieldPlaceholder'),
    },
  },
  houseNumber: {
    extraProps: {
      label: t<string>('COMMON_FORMS__houseNumberFieldPlaceholder'),
    },
  },
  zip: {
    extraProps: {
      label: t<string>('COMMON_FORMS__zipFieldPlaceholder'),
    },
  },
  city: {
    extraProps: {
      label: t<string>('COMMON_FORMS__cityFieldPlaceholder'),
    },
  },
  country: {
    Component: CountriesSelect,
    extraProps: {
      label: t<string>('COMMON_FORMS__countryFieldPlaceholder'),
    },
  },
  phone: {
    extraProps: {
      label: t<string>('COMMON_FORMS__phoneNumberFieldPlaceholder'),
    },
  },
});

const defInitialFormValues: UserProfileFormValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  vatNumber: '',
  phone: '',
  city: '',
  address: '',
  houseNumber: '',
  zip: '',
  country: '',
};

export { UserProfileForm };
