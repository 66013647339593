/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { exchangesCodesImages } from '../../utils/consts';
import { AdminAllUsersQuery, KeysStatus } from '../../apollo';
import { useModals } from '../../providers';
import { Modal } from '../../providers/Modals';
import { adminUsersTableExchangeIcon } from '../../styles/admin';
import { Button as DefButton } from '../buttons';
import { AdminUserTableColumn } from '../tables';

export type AdminUsersTableExchangesButtonsProps = {
  userId: number;
  exchanges: Exchange[];
  column: Column;
};

const AdminUsersTableExchangesButtons = ({
  userId,
  exchanges,
  column,
}: AdminUsersTableExchangesButtonsProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals();

  const { modalName, buttonText } = columnsData[column];

  return (
    <>
      {exchanges.map(({ exchangeID, exchangeCode, keysStatus }) => {
        const exchangeId = toNumber(exchangeID);
        const disabledRemoveApiBtn = column === "keysRemovement" && keysStatus === KeysStatus.NoKeys;
        const disabled =   (column === 'deactivateSubscription' || column === 'rebalance' ) && keysStatus !== KeysStatus.Ok;

        return (
          <Button
            key={exchangeID}
            disabled={disabledRemoveApiBtn || disabled}
            onClick={() => {
              dispatch({
                type: 'setModalContent',
                payload: {
                  name: modalName,
                  data: {
                    userId,
                    exchangeId,
                  },
                },
              });
            }}
          >
            <img src={exchangesCodesImages[exchangeCode]} alt={exchangeCode} />
            {t(buttonText)}
          </Button>
        );
      })}
    </>
  );
};

type Exchange =
  AdminAllUsersQuery['adminGetUsers']['users'][number]['exchanges'][number];

type PickColumn<T extends AdminUserTableColumn> = T extends AdminUserTableColumn
  ? T
  : never;

type Column = PickColumn<
  'rebalance' | 'keysRemovement' | 'deactivateSubscription'
>;

const Button = styled(DefButton)`
  min-width: 170px;
  white-space: nowrap;

  & + & {
    margin-top: 10px;
  }

  img {
    ${adminUsersTableExchangeIcon};
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

const columnsData: {
  [key in Column]: {
    modalName: Modal;
    buttonText: string;
  };
} = {
  deactivateSubscription: {
    modalName: 'disableSubscriptionAdmin',
    buttonText: 'ADMIN_ALL_USERS_PAGE__cancelSubscriptionButtonText',
  },
  rebalance: {
    modalName: 'rebalanceUserAdmin',
    buttonText: 'ADMIN_ALL_USERS_PAGE__rebalanceButtonText',
  },
  keysRemovement: {
    modalName: 'removeUserKeysAdmin',
    buttonText: 'ADMIN_ALL_USERS_PAGE__removeKeysButtonText',
  },
};

export { AdminUsersTableExchangesButtons };
