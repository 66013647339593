/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useModals, usePayments } from '../../providers';
import {
  EditUserBlock,
  SecurityBlock,
  PromotionBlock,
  SubscriptionsAndAccountBlock,
} from '../../components/account-page';
import { AppPageLayout } from '../../layouts';

const AccountPage = () => {
  const { dispatch } = useModals();
  const { togglePaymentModal } = usePayments();

  const { state } = useLocation();

  type LocationState = {
    show2FAEnableModal?: boolean;
    showCreditCardModal?: boolean;
  };

  const show2FAEnableModal = (state as LocationState)?.show2FAEnableModal;
  const showCreditCardModal = (state as LocationState)?.showCreditCardModal;

  useEffect(() => {
    if (show2FAEnableModal) {
      dispatch({
        type: 'setModalContent',
        payload: {
          name: 'enable2FA',
        },
      });
    }
  }, [dispatch, show2FAEnableModal]);

  useEffect(() => {
    if (showCreditCardModal) {
      togglePaymentModal(true);
    }
  }, [showCreditCardModal, togglePaymentModal]);

  return (
    <AppPageLayout>
      <EditUserBlock />
      <SecurityBlock />
      <PromotionBlock />
      <SubscriptionsAndAccountBlock />
    </AppPageLayout>
  );
};

export { AccountPage };
