/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { RootProviders } from '../providers';
import { ErrorBoundary } from './ErrorBoundary';
import { Routes } from './Routes';

const App = () => (
  <ErrorBoundary>
    <RootProviders>
      <Routes />
    </RootProviders>
  </ErrorBoundary>
);

export { App };
