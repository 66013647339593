/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled, { css } from 'styled-components';
import {
  Checkbox as DefCheckbox,
  CheckboxProps as DefCheckboxProps,
} from '@mui/material';
import { CheckmarkIcon as DefCheckmarkIcon } from '../icons';

export type CheckboxProps = DefCheckboxProps;

const Checkbox = (props: CheckboxProps) => (
  <StyledCheckbox
    icon={<EmptyIconWrapper />}
    indeterminateIcon={<IndeterminateIcon />}
    checkedIcon={
      <CheckedIconWrapper>
        <CheckmarkIcon />
      </CheckedIconWrapper>
    }
    {...props}
  />
);

const StyledCheckbox = styled(DefCheckbox)`
  .MuiIconButton-label {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const commonIconWrapperStyles = css`
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 3px;
  position: relative;
`;

const EmptyIconWrapper = styled.div`
  ${commonIconWrapperStyles};
`;

const IndeterminateIcon = styled.div`
  ${commonIconWrapperStyles};
  &:after {
    content: '';
    display: block;
    width: 90%;
    height: 2px;
    background-color: ${({ theme: { palette } }) => palette.primary.main};
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CheckedIconWrapper = styled.div`
  ${commonIconWrapperStyles};
  background-color: ${({ theme: { palette } }) => palette.primary.main};
  border-color: ${({ theme: { palette } }) => palette.primary.main};
`;

const CheckmarkIcon = styled(DefCheckmarkIcon)`
  display: block;
  width: 11px;
  height: 11px;
  position: relative;
  top: 2px;
  left: 3px;
`;

export { Checkbox };
