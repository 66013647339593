/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const UserInCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 180 180" {...props}>
    <g fill="currentColor">
      <path d="M90 2.4c48.3 0 87.5 39.3 87.5 87.7s-39.2 87.5-87.6 87.5S2.4 138.4 2.4 89.9C2.5 41.6 41.7 2.4 90 2.4zm77.3 87.7c0-42.7-34.4-77.2-77-77.3-42.9 0-77.4 34.2-77.4 77 0 42.9 34.2 77.4 76.9 77.4 43 .1 77.4-34.2 77.5-77.1z" />
      <path d="M91.7 155.8c-23-.5-41-8.3-55.5-23.9-.7-.7-.9-1.4-1-2.4-.4-7.6 2.6-14 6.9-19.9 7.4-10.1 17.6-16.3 29.6-19.4 18.5-4.9 36.3-3 52.8 7.1 9.4 5.7 16.2 13.6 19.5 24.3.9 2.8 1 5.7.8 8.6 0 .5-.3 1-.6 1.3-11.1 12.3-24.8 20-41.1 23-4.4.7-8.8 1-11.4 1.3z" />
      <path d="M90 98.1c-20.6 0-37.3-16.7-37.4-37.2 0-10.1 3.9-19.6 11-26.7 7-7.1 16.4-11 26.4-11 20.6 0 37.4 16.7 37.4 37.3 0 10-3.9 19.4-10.9 26.5-7.1 7.2-16.5 11.1-26.5 11.1z" />
    </g>
    <path
      d="M90 28.3c17.9 0 32.4 14.5 32.4 32.3 0 18-14.4 32.5-32.4 32.5-17.8 0-32.3-14.4-32.4-32.2 0-18 14.4-32.6 32.4-32.6zm0-10c-11.3 0-22 4.4-30 12.4-8 8.1-12.4 18.8-12.4 30.3.1 23.3 19.1 42.2 42.4 42.2 11.4 0 22-4.4 30-12.5 8-8 12.4-18.7 12.4-30 0-23.4-19.1-42.4-42.4-42.4z"
      fill="#1588f9"
    />
  </Icon>
);

// #1588f9

export { UserInCircleIcon };
