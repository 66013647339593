/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isNil, isObject, isFunction, isNumber, isString } from 'lodash-es';
import { Location } from 'history';
import { ApolloError, NetworkStatus } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { Maybe } from '../apollo';
import {
  adminPathPrefix,
  appPathPrefix,
  authPathPrefix,
  paths,
} from '../pages';
import { serverResponseErrorMessages } from './consts';

export const getUrlParams = (url: Maybe<string>) => {
  if (!url) return null;

  const parsedURL = url ? new URL(url) : null;
  const params = parsedURL?.search.slice(1);

  return params ? new URLSearchParams(params) : null;
};

export const isIterable = (obj: unknown) => {
  if (isNil(obj) || !isObject(obj)) {
    return false;
  }

  return isFunction(obj[Symbol.iterator]);
};

export const wait = (ms = 1000) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getClipboardText = async () => {
  try {
    return navigator.clipboard.readText();
  } catch (e) {
    console.log('Error in getClipboardText', e);
    return '';
  }
};

const isErrorResponse = (obj: unknown): obj is ErrorResponse => {
  return isObject(obj) && 'operation' in obj && 'forward' in obj;
};

export const getNetworkErrorStatusCode = (error: ApolloError | ErrorResponse) =>
  error.networkError && 'statusCode' in error.networkError
    ? error.networkError.statusCode
    : null;

const getNetworkErrorMessage = (error: ApolloError | ErrorResponse) =>
  error.networkError && 'message' in error.networkError
    ? error.networkError.message
    : null;

const isServerErrorCode = (code: number) => 500 <= code && code < 600;

export const isServerError = (error: unknown) => {
  if (!(error instanceof ApolloError || isErrorResponse(error))) return false;
  const message = getNetworkErrorMessage(error);
  const statusCode = getNetworkErrorStatusCode(error);
  return (
    message === serverResponseErrorMessages.FAILED_TO_FETCH ||
    (isNumber(statusCode) && isServerErrorCode(statusCode))
  );
};

type LocationType = Location | string;

const getPathName = (location: LocationType) =>
  isString(location) ? location : location.pathname;

export const isVerifyPagePath = (location: LocationType) => {
  const pathname = getPathName(location);
  return pathname === paths.verify;
};

export const isAuthPath = (location: LocationType) => {
  const pathname = getPathName(location);
  return pathname.startsWith(authPathPrefix);
};

export const isAppPath = (location: LocationType) => {
  const pathname = getPathName(location);
  return pathname.startsWith(appPathPrefix);
};

export const isAdminPath = (location: Location | string) => {
  const pathname = getPathName(location);
  return pathname.startsWith(adminPathPrefix);
};

export const isInnerAppPath = (location: LocationType) =>
  [isAppPath, isAdminPath].some((check) => check(location));

export const isLoadingApolloStatus = (status: NetworkStatus) =>
  [
    NetworkStatus.loading,
    NetworkStatus.setVariables,
    NetworkStatus.refetch,
  ].some((loadingStatus) => status === loadingStatus);

export const isDutchLocale = () =>
  navigator.language.toLocaleLowerCase().includes('nl');
