/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { googleLogout } from '@react-oauth/google';
import { setAuthVar } from '../../utils/apollo-vars';
import { removeToken } from '../../utils/auth';
import { removeAuthIdentityId } from '../../utils/auth-identity-id';
import { wait } from '../../utils/helpers';
import { SignOutDocument, SignOutMutation } from '../../apollo';
import { Spinner } from '../../styled';
import { paths } from '../paths';

const LogoutPage = () => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    new Promise<void>((resolve) => {
      client
        .mutate<SignOutMutation>({ mutation: SignOutDocument })
        .finally(() => {
          resolve();
        });
    })
      .then(() => wait())
      .then(() => {
        googleLogout();
        removeToken();
        removeAuthIdentityId();
        return client.resetStore();
      })
      .then(() => setAuthVar(false))
      .then(() => {
        navigate(paths.login);
      });
  }, [client, navigate]);

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export { LogoutPage };
