/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useMountedState } from 'react-use';
import { useSpring, animated } from '@react-spring/web';
import { fullScreenHeightStyles } from '../../styles';

const LoadingPage = () => {
  const mounted = useMountedState();
  const [resetMask, setResetMask] = useState(false);

  const { y } = useSpring({
    from: { y: 553 },
    to: { y: 0 },
    config: { duration: 6000 },
    reset: resetMask,
    onRest: () => mounted() && setResetMask((state) => !state),
  });

  return (
    <Wrapper>
      <Svg viewBox="0 0 468 553">
        <g fill="none" fillRule="evenodd">
          <mask id="prefix__b" fill="#fff">
            <animated.rect id="path-1" x="0" y={y} width="467" height="553" />
          </mask>
          <g mask="url(#prefix__b)">
            <path
              fill="#0A355A"
              fillRule="nonzero"
              d="M1.146 418.792l52.025 30.039V179.183l51.727-29.863v329.377l52.025 30.038V119.283l51.731-29.868V538.6l26.013 15.021L260.68 538.6V1.045L1.146 149.148z"
            />
            <path
              fill="#1588F9"
              fillRule="nonzero"
              d="M428.955 233.758l39.233-30.628v-82.28l-156.69-90.464v478.873l52.937-30.562V121.023l50.816 29.338.91 238.397-.166 60.17 52.193-30.137V264.36z"
            />
          </g>
          <g opacity={0.298} fillRule="nonzero">
            <path
              d="M1.146 418.792l52.025 30.039V179.183l51.727-29.863v329.377l52.025 30.038V119.283l51.731-29.868V538.6l26.013 15.021L260.68 538.6V1.045L1.146 149.148z"
              fill="#0A355A"
            />
            <path
              d="M428.955 233.758l39.233-30.628v-82.28l-156.69-90.464v478.873l52.937-30.562V121.023l50.816 29.338.91 238.397-.166 60.17 52.193-30.137V264.36z"
              fill="#1588F9"
            />
          </g>
        </g>
      </Svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${fullScreenHeightStyles};
`;

const Svg = styled.svg`
  width: 100px;
  height: 100px;
`;

export { LoadingPage };

export default LoadingPage;
