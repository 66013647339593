/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Form as DefForm, Formik, FormikFormProps, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { Link } from '@mui/material';
import { externalLinkProps, SiteData } from '../../utils/consts';
import { sendSentryError } from '../../utils/sentry';
import { getChangeUserPasswordSchema } from '../../utils/validation-schemas';
import { useChangeUserPasswordMutation } from '../../apollo';
import { getDownMedia } from '../../styles';
import { useAlert } from '../../providers';
import { commonInputHalfWidthStyles } from '../../styles/account-page';
import { Button } from '../buttons';
import { FormikInput, FormikInputProps } from '../formik-elements';
import { Text } from '../texts';

export type ChangeUserPasswordFormProps = FormikFormProps;

const ChangeUserPasswordForm = (props: ChangeUserPasswordFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const [changePassword] = useChangeUserPasswordMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setAlert({
        type: 'success',
        message: t('CHANGE_USER_PASSWORD_FORM__successText'),
      });
    },
    onError: (error) => {
      setAlert({
        type: 'error',
        message: t('CHANGE_USER_PASSWORD_FORM__errorText'),
      });
      sendSentryError(error);
    },
  });

  const { schema, fields } = useMemo(
    () => ({
      schema: getChangeUserPasswordSchema(t),
      fields: getFieldsDataByName(t),
    }),
    [t]
  );

  return (
    <Formik
      initialValues={{ currentPassword: '', password: '', confPassword: '' }}
      validationSchema={schema}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        await changePassword({ variables: values });
        resetForm();
      }}
    >
      {({ isSubmitting }: FormikProps<ChangeUserPasswordFormValues>) => (
        <Form {...props}>
          <Desc>
            {t('CHANGE_USER_PASSWORD_FORM__descText1')}{' '}
            <Link href={SiteData.SECURITY_LINK} {...externalLinkProps}>
              {t('CHANGE_USER_PASSWORD_FORM__descLinkText1')}
            </Link>{' '}
            {t('CHANGE_USER_PASSWORD_FORM__descText2')}
          </Desc>
          {Object.entries(fields).map(([name, data]) => {
            const { Component = HalfWidthInput, extraProps } = data;

            return (
              <Component
                key={name}
                id={`${formPrefix}-${name}`}
                type={'password'}
                name={name}
                disabled={isSubmitting}
                {...extraProps}
              />
            );
          })}
          <BottomBlock>
            <Button
              type={'submit'}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('CHANGE_USER_PASSWORD_FORM__buttonText')}
            </Button>
          </BottomBlock>
        </Form>
      )}
    </Formik>
  );
};

export type ChangeUserPasswordFormValues = {
  currentPassword: string;
  password: string;
  confPassword: string;
};

const Form = styled(DefForm)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const HalfWidthInput = styled(FormikInput)`
  ${commonInputHalfWidthStyles};
`;

const Desc = styled(Text)`
  width: 100%;
  margin: 0 0 10px;
`;

const BottomBlock = styled.div`
  display: flex;
  flex: 0 0 100%;
  margin-top: 20px;
  justify-content: flex-end;
  ${getDownMedia('sm')} {
    margin-top: 12px;
  }
`;

const getFieldsDataByName: (t: TFunction) => {
  [key in keyof ChangeUserPasswordFormValues]: {
    Component?: typeof FormikInput;
    extraProps: Omit<FormikInputProps, 'name'>;
  };
} = (t) => ({
  password: {
    extraProps: {
      label: t<string>('COMMON_FORMS__newPasswordFieldPlaceholder'),
    },
  },
  confPassword: {
    extraProps: {
      label: t<string>('COMMON_FORMS__confirmPasswordFieldPlaceholder'),
    },
  },
  currentPassword: {
    Component: FormikInput,
    extraProps: {
      label: t<string>('COMMON_FORMS__currentPasswordFieldPlaceholder'),
    },
  },
});

const formPrefix = 'change-user-password-form';

export { ChangeUserPasswordForm };
