/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useTranslation } from 'react-i18next';
import { setToken } from '../utils/auth';
import { setAuthIdentityId } from '../utils/auth-identity-id';
import { sendSentryError } from '../utils/sentry';
import { useAdminSignInAsMutation } from '../apollo';
import { useAlert } from '../providers';
import { useClearApolloCache } from './useClearApolloCache';

export const useAdminSignInAs = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const clearCache = useClearApolloCache();
  return useAdminSignInAsMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ adminSignInAs: { token, authIdentityID } }) => {
      clearCache().then(() => {
        requestAnimationFrame(() => {
          setToken(token);
          setAuthIdentityId(authIdentityID);
          window.location.reload();
        });
      });
    },
    onError: (error) => {
      setAlert({
        type: 'error',
        message: t('ADMIN_ALL_USERS_PAGE__loadError'),
      });
      sendSentryError(error);
    },
  });
};
