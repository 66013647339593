/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled, { css } from 'styled-components';
import { isNil } from 'lodash-es';
import { colors } from '../../utils/colors';
import { getDownMedia } from '../../styles';
import { TableCell } from './TableCell';
import { commonNumbersAligning } from './common';

export const ChangeCell = styled(TableCell)<{ $positiveChange?: boolean }>`
  ${commonNumbersAligning};
  min-width: 78px;

  ${getDownMedia('sm')} {
    min-width: 80px;
  }

  ${({ $positiveChange }) => {
    if (isNil($positiveChange)) return null;

    return css`
      color: ${$positiveChange ? colors.green : colors.red};
    `;
  }}
`;
