/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { getDownMedia } from '../../styles';

export const DescBlock = styled.div`
  flex: 1 1 auto;

  ${getDownMedia('sm')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
