/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';

export const AppPageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 37px 42px 30px;
  background-color: #fff;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 30px 22px 22px;
  }
`;
