/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Link } from '@mui/material';
import { LinkProps } from '@mui/material/Link';

const WrappedLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <RouterLink ref={ref} {...props} />
);

type Props = LinkProps &
  RouterLinkProps & {
    className?: string;
    to: string;
  };

const RouteLink: React.FC<Props> = ({
  children,
  className,
  to,
  ...linkProps
}) => (
  <Link className={className} component={WrappedLink} to={to} {...linkProps}>
    {children}
  </Link>
);

export { RouteLink };
