/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { TFunction } from 'i18next';
import { MolliePaymentInfoQueryHookResult } from '../apollo';
import { sendSentryError } from './sentry';

type PaymentInfo = NonNullable<
  MolliePaymentInfoQueryHookResult['data']
>['getMolliePaymentInfo'];

export const getPaymentInfoString = (data: PaymentInfo, t: TFunction) => {
  try {
    const details = JSON.parse(data.details);

    if (data.method === 'creditcard') {
      const lastDigits = (details as CreditCardDetails).cardNumber;
      return !!lastDigits
        ? `${t(
            'ADJUST_CREDIT_CARD_SECTION__last4CardDigitsText'
          )}: ${lastDigits}`
        : null;
    } else if (data.method === 'directdebit') {
      const account = (details as BankDetails).consumerAccount;
      return !!account
        ? `${account.slice(0, 8)} ****** ${account.slice(-4)}`
        : null;
    } else {
      return null;
    }
  } catch (e) {
    sendSentryError(e);
    return null;
  }
};

/*export type PaymentInfo = {
  method: Method;
  details: CreditCardDetails | BankDetails;
  status: 'valid' | 'invalid';
  createdAt: string; // "2021-02-01T09:26:28Z"
};*/

// type Method = 'creditcard' | 'directdebit';

type CreditCardDetails = {
  cardHolder: string; //"T. TEST",
  cardNumber: string; //"6787",
  cardLabel: string; //"Mastercard",
  cardFingerprint: string; //"JkqJNcMj7vQTcShf4mmyhkmC",
  cardExpiryDate: string; //"2021-12-31"
};

type BankDetails = {
  consumerName: string; // "T. TEST",
  consumerAccount: string; // "NL65RABO0690923580",
  consumerBic: string; // "ABNANL2A"
};
