/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
// import { isString } from 'lodash-es';
import { useField, useFormikContext } from 'formik';
import { DatePicker, DatePickerProps } from '../form-elements';

export type FormikDatePickerProps = Omit<
  DatePickerProps,
  'value' | 'onChange'
> & {
  name: string;
};

const FormikDatePicker = ({ name, ...props }: FormikDatePickerProps) => {
  const { setFieldValue } = useFormikContext();
  const [{ onChange, ...field }, { error }] = useField(name);

  return (
    <DatePicker
      {...field}
      {...props}
      onChange={(dateUTCStr) => setFieldValue(name, dateUTCStr)}
      {...(!!error
        ? {
            error: true,
            helperText: error,
          }
        : {})}
    />
  );
};

export { FormikDatePicker };
