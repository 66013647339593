/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { setAuthVar } from '../../utils/apollo-vars';
import { setToken } from '../../utils/auth';
import { sendSentryError } from '../../utils/sentry';
import { useTotpVerifyMutation } from '../../apollo';
import { useAlert, useModals } from '../../providers';
import { DesktopBr } from 'styled';
import { TwoFACode } from '../TwoFACode';

const Login2FAModal = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { dispatch } = useModals();

  const [backupCodeFieldVisible, setBackupCodeFieldVisible] = useState(false);

  const title = useMemo(
    () =>
      !backupCodeFieldVisible ? (
        <>
          {t('LOGIN_MODAL__titleFirstLine')} <DesktopBr />
          {t('LOGIN_MODAL__titleSecondLine')}
        </>
      ) : (
        <>
          {t('LOGIN_MODAL__backupTitleFirstLine')} <DesktopBr />
          {t('LOGIN_MODAL__backupTitleSecondLine')}
        </>
      ),
    [backupCodeFieldVisible, t]
  );

  useEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'login-2fa',
        titleText: title,
      },
    });
  }, [dispatch, title]);

  const [verifyTOTP, { loading }] = useTotpVerifyMutation({
    fetchPolicy: 'no-cache',
    onCompleted: async ({ totpVerify }) => {
      const token = get(totpVerify, 'token');

      if (!token) {
        setAlert({ type: 'warning', message: t('LOGIN_MODAL__noTokenError') });
        sendSentryError(new Error(`Couldn't verify 2FA`));
        return;
      }

      setToken(token);
      setAuthVar(true);
      dispatch({ type: 'closeModal' });
    },
    onError: (error) => {
      setAlert({ type: 'error', message: t('LOGIN_MODAL__errorText') });
      sendSentryError(error);
    },
  });

  return (
    <TwoFACode
      loading={loading}
      submitCodeButtonText={t('TWO_FA_MODAL__loginButton')}
      onBackupCodeVisibilityChange={setBackupCodeFieldVisible}
      onCodeFill={(code) => verifyTOTP({ variables: { code } })}
    />
  );
};

export { Login2FAModal };
