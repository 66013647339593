/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from './Icon';
import { Text } from './Text';
import { Title } from './Title';

const UnlockedCurrency = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon type={'unlockedLock'} />
      <Title>{t('NOTIFICATION_UNLOCKED_CURRENCY_MODAL__title')}</Title>
      <Text>{t('NOTIFICATION_UNLOCKED_CURRENCY_MODAL__desc')}</Text>
    </>
  );
};

export { UnlockedCurrency };
