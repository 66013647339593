/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  compact,
  isEmpty,
  isNumber,
  isString,
  omit,
  toString,
  uniqBy,
} from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useDebounce, useEffectOnce, useLocalStorage } from 'react-use';
import { getName } from 'i18n-iso-countries';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { investOptions, LocalStorage } from '../../utils/consts';
import { customUserStatusesTranslations } from '../../utils/translations';
import {
  CustomUserStatus,
  Maybe,
  SendAdminEmailsMutationVariables,
  useAdminUsersFiltersDataQuery,
} from '../../apollo';
import { getDownMedia, getUpMedia } from '../../styles';
import { useModals } from '../../providers';
import { Input, InputProps, Select } from '../form-elements';
import {
  AdminSendEmailsForm as DefAdminSendEmailsForm,
  HandleSendAdminEmailsFn,
} from '../forms';

export type AdminUsersTableFilterProps = {
  selectedUserIDs: string[];
  customStatus: Maybe<CustomUserStatus>;
  queryFilters: Omit<SendAdminEmailsMutationVariables, 'templateId'>;
  onCustomStatusChange: (status: Maybe<CustomUserStatus>) => void;
  onFiltersChange: (filters: FiltersState) => void;
  adminSendEmailsFormDisabled: boolean;
  resetOnSuccess: () => void;
};

const AdminUsersTableFilter = ({
  selectedUserIDs,
  customStatus,
  queryFilters,
  onCustomStatusChange,
  onFiltersChange,
  resetOnSuccess,
  adminSendEmailsFormDisabled,
}: AdminUsersTableFilterProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals();

  const {
    data: {
      getExchanges: exchanges,
      adminGetUsers: usersWithFiltersOptions,
    } = {},
  } = useAdminUsersFiltersDataQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
  });

  const users = useMemo(
    () => usersWithFiltersOptions?.users ?? [],
    [usersWithFiltersOptions]
  );

  const [savedFilters, setSavedFilters] = useLocalStorage<
    { customStatus: Maybe<CustomUserStatus> } & FiltersState
  >(LocalStorage.adminUsersTableFilters);

  const [filters, setFilters] = useState(() => {
    const savedFiltersWithoutStatus = omit(savedFilters, ['customStatus']);
    return Object.entries(initialFilters).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: savedFiltersWithoutStatus[key] ?? value,
      }),
      {} as FiltersState
    );
  });

  useDebounce(
    () => {
      onFiltersChange(filters);
    },
    600,
    [filters, onFiltersChange]
  );

  useEffectOnce(() => {
    if (
      savedFilters?.customStatus &&
      customStatus !== savedFilters.customStatus
    ) {
      onCustomStatusChange(savedFilters.customStatus);
    }
  });

  useEffect(() => {
    setSavedFilters({
      customStatus,
      ...filters,
    });
  }, [customStatus, filters, setSavedFilters]);

  const customStatuses = useMemo(
    () =>
      Object.values(CustomUserStatus)
        .map((status) => ({
          label: t(customUserStatusesTranslations[status]!),
          value: status,
        }))
        .sort(filterFn()),
    [t]
  );

  const countriesOptions = useMemo(() => {
    if (isEmpty(users)) return [];

    return uniqBy(
      compact([
        {
          label: t('ADMIN_ALL_USERS_PAGE__filterByCountryForeignText'),
          value: 'not-nl',
        },
        ...users
          .map(({ country }) => country)
          .map((country) =>
            isString(country) && !isEmpty(country)
              ? {
                  label: getName(country, 'nl') ?? 'country',
                  value: country,
                }
              : null
          ),
      ]),
      'value'
    );
  }, [t, users]);

  const amountsToInvestOptions = useMemo(() => {
    if (isEmpty(users)) return [];

    return uniqBy(
      compact([
        ...users
          .map(({ amountToInvest }) => amountToInvest)
          .map((amountToInvest) => {
            if (!isNumber(amountToInvest)) return null;

            const label = investOptions.find(
              ({ value }) => value === amountToInvest
            )?.label;

            if (!label) return null;

            return {
              label,
              value: amountToInvest.toString(),
            };
          }),
      ]),
      'value'
    );
  }, [users]);

  const exchangesOptions = useMemo(() => {
    if (isEmpty(exchanges)) return [];
    return Object.values(exchanges!).map(({ id, exchangeName }) => ({
      label: exchangeName,
      value: toString(id),
    }));
  }, [exchanges]);

  const handleFilterChange = useCallback<
    (field: keyof FiltersState) => InputProps['onChange']
  >(
    (field) =>
      ({ target: { value } }) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [field]: value,
        }));
      },
    []
  );

  const handleSendAdminEmails = useCallback<HandleSendAdminEmailsFn>(
    (templateId) => {
      const userIDs =
        (() => {
          if (!isEmpty(selectedUserIDs)) {
            return selectedUserIDs.map((id) => parseInt(id));
          }

          if (!isEmpty(queryFilters.userIDs)) {
            return queryFilters.userIDs;
          }
        })() || [];

      const filters = {
        templateId,
        ...queryFilters,
        userIDs,
      };

      dispatch({
        type: 'setModalContent',
        payload: {
          name: 'sendEmailsAdmin',
          data: {
            filters,
            resetOnSuccess,
          },
        },
      });
    },
    [selectedUserIDs, queryFilters, dispatch, resetOnSuccess]
  );

  return (
    <Wrapper>
      <Filters>
        <SelectField
          items={customStatuses}
          label={t('ADMIN_ALL_USERS_PAGE__filterByStatusLabelText')}
          InputLabelProps={{ shrink: !!customStatus }}
          resetOptionText={t('ADMIN__resetFilterText')}
          value={customStatus ?? ''}
          onChange={({ target: { value } }) =>
            onCustomStatusChange((value as CustomUserStatus) || null)
          }
        />
        <SelectField
          items={countriesOptions}
          label={t('ADMIN_ALL_USERS_PAGE__filterByCountryLabelText')}
          resetOptionText={t('ADMIN__resetFilterText')}
          value={filters.country}
          onChange={handleFilterChange('country')}
        />
        <SelectField
          items={amountsToInvestOptions}
          label={t('ADMIN_ALL_USERS_PAGE__filterByInvestsLabelText')}
          resetOptionText={t('ADMIN__resetFilterText')}
          value={filters.amountToInvest}
          onChange={handleFilterChange('amountToInvest')}
        />
        <SelectField
          items={exchangesOptions}
          label={t('ADMIN_ALL_USERS_PAGE__filterByExchangesLabelText')}
          resetOptionText={t('ADMIN__resetFilterText')}
          value={filters.exchangeId}
          onChange={handleFilterChange('exchangeId')}
        />
        <InputField
          label={t('ADMIN_ALL_USERS_PAGE__filterByEmailLabelText')}
          value={filters.email}
          onChange={handleFilterChange('email')}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    email: '',
                  }))
                }
                sx={{
                  visibility: !filters.email ? 'hidden ' : 'visible',
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
        <InputField
          label={t('ADMIN_ALL_USERS_PAGE__filterByNameLabelText')}
          value={filters.name}
          onChange={handleFilterChange('name')}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    name: '',
                  }))
                }
                sx={{ visibility: !filters.name ? 'hidden ' : 'visible' }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
        <InputField
          type="number"
          label={t('ADMIN_ALL_USERS_PAGE__filterByIdLabelText')}
          value={filters.id}
          onChange={handleFilterChange('id')}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    id: '',
                  }))
                }
                sx={{ visibility: !filters.id ? 'hidden ' : 'visible' }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Filters>
      <AdminSendEmailsForm
        active={adminSendEmailsFormDisabled}
        handleSendAdminEmails={handleSendAdminEmails}
      />
    </Wrapper>
  );
};

const filtersBreakpoint = 1440;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  ${getDownMedia(filtersBreakpoint)} {
    flex-wrap: wrap;
  }
`;

const SelectField = styled(Select)``;
const InputField = styled(Input)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
`;
const marginBetweenFilters = 5;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100%;
  margin-left: -${marginBetweenFilters}px;
  margin-right: -${marginBetweenFilters}px;

  .MuiInputBase-root {
    padding-right: 3px;
  }
  ${getUpMedia(filtersBreakpoint)} {
    flex: 1 1 auto;
    margin-right: 10px;
  }

  ${SelectField}, ${InputField} {
    width: 0;
    flex: 1 1 auto;
    min-width: 100px;
    margin: 0 ${marginBetweenFilters}px 10px;
  }
`;

const AdminSendEmailsForm = styled(DefAdminSendEmailsForm)`
  flex: 1 0 auto;
  max-width: 100%;
  align-self: flex-start;

  ${getUpMedia('sm')} {
    flex-grow: 0;
  }

  ${getUpMedia(filtersBreakpoint)} {
    margin-left: auto;
  }
`;

type FiltersState = {
  country: string;
  amountToInvest: string;
  name: string;
  email: string;
  id: string;
  exchangeId: string;
};

const initialFilters: FiltersState = {
  country: '',
  amountToInvest: '',
  name: '',
  email: '',
  id: '',
  exchangeId: '',
};

const filterFn =
  (sortProperty = 'label') =>
  (a: Record<string, string>, b: Record<string, string>) => {
    const nameA = a[sortProperty].toUpperCase();
    const nameB = b[sortProperty].toUpperCase();

    if (!(!!nameA && !!nameB)) return 0;

    if (nameA < nameB) return -1;

    if (nameA > nameB) return 1;

    return 0;
  };

const adminUsersTableInitialFilters = initialFilters;

type AdminUsersFiltersState = FiltersState;

export type { AdminUsersFiltersState };

export { AdminUsersTableFilter, adminUsersTableInitialFilters };
