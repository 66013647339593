/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import { compact, isEmpty, isUndefined } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import {
  TableCell as DefTableCell,
  TableRow as DefTableRow,
  TableRowProps,
} from '@mui/material';
import { LocalStorage } from '../../utils/consts';
import {
  OrderByDirection,
  UsersOrderByField,
  UsersOrderBy,
} from '../../apollo';
import { Checkbox, CheckboxProps } from '../form-elements';
import { AdminUserTableColumn } from '../tables';
import { SortLink } from '../texts';
import {
  AdminTableEmailCell,
  AdminTableEmailCellProps,
} from './AdminTableEmailCell';

type UserTableRowProps = TableRowProps & {
  columns: AdminUserTableColumn[];
  sortableColumns?: AdminUserTableColumn[];
  selectAllCheckboxProps: CheckboxProps;
  onSortingsChange: (sortings: Sortings) => void;
};

const AdminTableHeadRow = ({
  columns,
  sortableColumns = [],
  selectAllCheckboxProps,
  onSortingsChange,
  ...props
}: UserTableRowProps) => {
  const { t } = useTranslation();

  const [initialSortings, setInitialSortings] = useLocalStorage<
    Array<{ field: AdminUserTableColumn; value: OrderByDirection }>
  >(LocalStorage.adminUsersTableSortings);

  const [sortings, setSortings] = useState<
    Array<{
      field: AdminUserTableColumn;
      value: OrderByDirection;
    }>
  >(() => {
    if (isEmpty(initialSortings)) {
      return [
        {
          field: 'dateOfSignup',
          value: OrderByDirection.Desc,
        },
      ];
    }
    return initialSortings!;
  });

  useEffect(() => {
    setInitialSortings(sortings);
  }, [sortings, setInitialSortings]);

  useEffect(() => {
    onSortingsChange(
      compact(
        sortings.map(({ field, value }) => {
          const sortField = sortFieldsByColumnName[field];
          if (!sortField) return null;
          return {
            Field: sortField,
            Direction: value,
          };
        })
      )
    );
  }, [sortings, onSortingsChange]);

  const onSortingChange = (key: AdminUserTableColumn) => {
    const currentValue = sortings.find(({ field }) => field === key)?.value;
    let nextValue;

    switch (currentValue) {
      case OrderByDirection.Desc:
        nextValue = OrderByDirection.Asc;
        break;
      case OrderByDirection.Asc:
        nextValue = undefined;
        break;
      default:
        nextValue = OrderByDirection.Desc;
    }

    /*const nextSortings = sortings.filter(({ field }) => field !== key);

    if (nextValue) {
      nextSortings.push({
        field: key,
        value: nextValue,
      });
    }*/

    setSortings(
      !isUndefined(nextValue)
        ? [
            {
              field: key,
              value: nextValue,
            },
          ]
        : []
    );
  };

  const getSortDirection = (key: AdminUserTableColumn) =>
    sortings.find(({ field }) => field === key)?.value;

  return (
    <TableRow {...props}>
      <TableCell>
        <Checkbox
          inputProps={{ 'aria-label': 'select all desserts' }}
          {...selectAllCheckboxProps}
        />
      </TableCell>
      {columns.map((key, index) => {
        let Cell: FC<AdminTableEmailCellProps> = TableCell;
        if (key === 'email') {
          Cell = AdminTableEmailCell;
        }
        const cellText = t(columnsTitles[key]!);
        let cellValue;

        if (sortableColumns.includes(key)) {
          cellValue = (
            <SortLink
              direction={getSortDirection(key)}
              onClick={() => onSortingChange(key)}
            >
              {cellText}
            </SortLink>
          );
        } else {
          cellValue = cellText;
        }

        return <Cell key={index}>{cellValue}</Cell>;
      })}
    </TableRow>
  );
};

type Sortings = UsersOrderBy[];

const TableRow = styled(DefTableRow)``;
const TableCell = styled(DefTableCell)``;

const sortFieldsByColumnName: Partial<{
  [key in AdminUserTableColumn]: UsersOrderByField;
}> = {
  amount: UsersOrderByField.AssetsUnderManagement,
  dateOfSignup: UsersOrderByField.DateOfSignup,
  paid: UsersOrderByField.Kosten,
};

const columnsTitles: Partial<{
  [key in AdminUserTableColumn]: string;
}> = {
  id: 'ADMIN_ALL_USERS_PAGE__tableNameColumnID',
  name: 'ADMIN_ALL_USERS_PAGE__tableNameColumnName',
  amountToInvest: 'ADMIN_ALL_USERS_PAGE__tableAmountToInvestColumnName',
  email: 'ADMIN_ALL_USERS_PAGE__tableEmailColumnName',
  phone: 'ADMIN_ALL_USERS_PAGE__tablePhoneColumnName',
  customStatus: 'ADMIN_ALL_USERS_PAGE__tableStatusColumnName',
  exchangesStatuses: 'ADMIN_ALL_USERS_PAGE__tableExchangesStatusesColumnName',
  dateOfSignup: 'ADMIN_ALL_USERS_PAGE__tableSignupDateColumnName',
  amount: 'ADMIN_ALL_USERS_PAGE__tableMoneyUnderManagementColumnName',
  paid: 'ADMIN_ALL_USERS_PAGE__tableMoneyPaidColumnName',
  tfa: 'ADMIN_ALL_USERS_PAGE__table2FAColumnName',
  deactivateSubscription: 'ADMIN_ALL_USERS_PAGE__tableDeactivateColumnName',
  rebalance: 'ADMIN_ALL_USERS_PAGE__tableRebalanceColumnName',
  keysRemovement: 'ADMIN_ALL_USERS_PAGE__tableKeysRemovementColumnName',
};
const adminUsersTableInitialSortings: Sortings = [
  {
    Field: UsersOrderByField.DateOfSignup,
    Direction: OrderByDirection.Desc,
  },
];

type AdminUsersSortings = Sortings;

export type { AdminUsersSortings };
export { AdminTableHeadRow, adminUsersTableInitialSortings };
