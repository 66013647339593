/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNumber, isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { NotRetryableError, retry } from 'ts-retry-promise';
import { useApolloClient } from '@apollo/client';
import { sendSentryError } from '../../utils/sentry';
import {
  InvoicePaymentStatus,
  InvoiceDocument,
  InvoiceQuery,
  InvoiceQueryVariables,
} from '../../apollo';
import { Spinner as DefSpinner } from '../../styled/Spinner';
import { Icon } from './Icon';
import { Text } from './Text';
import { Title } from './Title';

const PaymentSingle = (props: Record<string, unknown>) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  const invoiceId =
    'invoiceId' in props && isString(props.invoiceId) && !!props.invoiceId
      ? parseInt(props.invoiceId)
      : undefined;

  useEffect(() => {
    if (!isNumber(invoiceId)) {
      setLoading(false);
      setError(true);
      return;
    }

    let mounted = true;

    retry(
      async () => {
        if (!mounted) return;
        const { data } = await client.query<
          InvoiceQuery,
          InvoiceQueryVariables
        >({
          query: InvoiceDocument,
          fetchPolicy: 'network-only',
          variables: {
            id: invoiceId,
          },
        });
        if (!mounted) return;
        if (!data) {
          throw new Error('No data in query response');
        }
        const invoice = data.getInvoice;
        if (!invoice) {
          throw new NotRetryableError(
            `No invoice with such id to grab data: ${invoiceId}`
          );
        }
        if (invoice.status !== InvoicePaymentStatus.InvoiceStatusComplete) {
          throw new Error(`Invoice ${invoiceId} hasn't yet been paid.`);
        }
        setError(false);
      },
      {
        delay: 1000,
        retries: 15,
      }
    )
      .catch((error) => {
        sendSentryError(error);
      })
      .finally(() => {
        mounted && setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [invoiceId, client]);

  if (loading) {
    return (
      <>
        <Title>{t('NOTIFICATION_PAYMENT_SINGLE_MODAL__loadingTitle')}</Title>
        <Spinner />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Icon type={'sadFace'} />
        <Title>{t('NOTIFICATION_PAYMENT_SINGLE_MODAL__errorTitle')}</Title>
        <Text>{t('NOTIFICATION_PAYMENT_SINGLE_MODAL__errorDesc')}</Text>
      </>
    );
  }

  return (
    <>
      <Icon type={'success'} />
      <Title>{t('NOTIFICATION_PAYMENT_SINGLE_MODAL__successTitle')}</Title>
    </>
  );
};

const Spinner = styled(DefSpinner)`
  align-self: center;
  margin-top: 28px;
`;

export { PaymentSingle };
