/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { FC } from 'react';
import styled from 'styled-components';
import { TableCell as DefTableCell, TableCellProps } from '@mui/material';

export type AdminTableEmailCellProps = TableCellProps;

const AdminTableEmailCell: FC<AdminTableEmailCellProps> = ({
  children,
  ...props
}) => (
  <TableCell {...props}>
    <p>{children}</p>
  </TableCell>
);

const TableCell = styled(DefTableCell)`
  > p {
    word-wrap: break-word;
    color: inherit;
  }
`;

export { AdminTableEmailCell };
