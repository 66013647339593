/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikFormProps } from 'formik';
import moment from 'moment';
import { callApi } from '../../utils/api';
import { sendSentryError } from '../../utils/sentry';
import { getDownloadInvoicesFormSchema } from '../../utils/validation-schemas';
import { getDownMedia, getUpMedia } from '../../styles';
import { useAlert } from '../../providers';
import { Button as DefButton } from '../buttons';
import { FormikDatePicker } from '../formik-elements';

export type AdminTableInvoicesFormProps = FormikFormProps;

const DownloadInvoicesForm = (props: AdminTableInvoicesFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const schema = useMemo(() => getDownloadInvoicesFormSchema(), []);

  return (
    <Formik<AdminTableInvoicesFormValues>
      initialValues={{
        from: '',
        till: '',
      }}
      validationSchema={schema}
      onSubmit={async ({ from, till }) => {
        try {
          await callApi('getInvoices', {
            from: from || moment.utc('2015-01-01').format(),
            till: till || moment.utc().format(),
          });
        } catch (e) {
          setAlert({
            type: 'error',
            message: 'DOWNLOAD_INVOICES_FORM__error',
          });
          sendSentryError(e);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          {Object.entries(fieldsLabels).map(([name, label]) => (
            <DatePicker key={name} name={name} label={t(label)} disableFuture />
          ))}
          <Button type={'submit'} loading={isSubmitting}>
            {t('DOWNLOAD_INVOICES_FORM__button')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export type AdminTableInvoicesFormValues = {
  from: string;
  till: string;
};

const Form = styled(DefForm)`
  display: flex;

  ${getDownMedia('sm')} {
    flex-wrap: wrap;
  }
`;

const DatePicker = styled(FormikDatePicker)`
  ${getUpMedia('sm')} {
    & + & {
      margin-left: 10px;
    }
  }
`;

const Button = styled(DefButton)`
  align-self: flex-start;
  height: 56px;
  margin-top: 12px;
  white-space: pre;

  ${getUpMedia('sm')} {
    margin-left: 10px;
  }
`;

const fieldsLabels: {
  [key in keyof AdminTableInvoicesFormValues]: string;
} = {
  from: 'DOWNLOAD_INVOICES_FORM__fromLabel',
  till: 'DOWNLOAD_INVOICES_FORM__endLabel',
};

export { DownloadInvoicesForm };
