/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { ComponentType, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import color from 'color';
import { Link } from '@mui/material';
import DefContainer, { ContainerProps } from '@mui/material/Container';
import { colors } from '../utils/colors';
import { externalLinkProps, SiteData } from '../utils/consts';
import { lightText } from '../utils/fonts';
import { getDownMedia } from '../styles';
import { LinkedinIcon, TwitterIcon, YoutubeIcon } from './icons';
import { Text } from './texts';

export type AppFooterProps = {
  withGoogleCaptchaTerms?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const AppFooter = ({ withGoogleCaptchaTerms, ...props }: AppFooterProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <Container>
        <LeftPart>
          <FooterLink href={SiteData.TERMS_LINK}>
            {t('FOOTER_TEXT__terms')}
          </FooterLink>
          <FooterLink href={SiteData.PRIVACY_LINK}>
            {t('FOOTER_TEXT__privacy')}
          </FooterLink>
          <FooterLink href={SiteData.DISCLAIMER_LINK}>
            {t('FOOTER_TEXT__disclaimer')}
          </FooterLink>
        </LeftPart>
        {withGoogleCaptchaTerms ? (
          <GoogleCaptchaTermsBlock>
            This site is protected by reCAPTCHA and the Google{' '}
            <GoogleCaptchaLink href="https://policies.google.com/privacy">
              Privacy Policy
            </GoogleCaptchaLink>{' '}
            and{' '}
            <GoogleCaptchaLink href="https://policies.google.com/terms">
              Terms of Service
            </GoogleCaptchaLink>{' '}
            apply.
          </GoogleCaptchaTermsBlock>
        ) : (
          <FooterSocialLinksList>
            {socialLinks.map(({ title, url, Icon }) => (
              <FooterSocialLinkListItem key={title}>
                <FooterSocialLink
                  aria-label={title}
                  href={url}
                  {...externalLinkProps}
                >
                  <Icon />
                </FooterSocialLink>
              </FooterSocialLinkListItem>
            ))}
          </FooterSocialLinksList>
        )}
      </Container>
    </Wrapper>
  );
};

const LinkedInIcon = styled(LinkedinIcon)`
  position: relative;
  top: -1px;
  right: -1px;
`;

const getStylesIcon = (Icon: ComponentType<any>) => styled(Icon)`
  width: 10px;
  height: 10px;
  fill: ${({ theme }) => theme.palette.primary.main};
`;

const socialLinks: Array<
  Readonly<{
    title: string;
    url: SiteData;
    Icon: ComponentType<any>;
  }>
> = [
  {
    title: 'linkedin',
    url: SiteData.LINKED_IN_LINK,
    Icon: getStylesIcon(LinkedInIcon),
  },
  {
    title: 'twitter',
    url: SiteData.TWITTER_LINK,
    Icon: getStylesIcon(TwitterIcon),
  },
  {
    title: 'youtube',
    url: SiteData.YOUTUBE_LINK,
    Icon: getStylesIcon(YoutubeIcon),
  },
];

const Wrapper = styled.footer`
  margin-top: auto;
  padding: 12px 0;
  background-color: #fff;
`;

const Container = styled((props: ContainerProps) => (
  <DefContainer maxWidth={false} {...props} />
))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${lightText};
`;

const LeftPart = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  color: ${colors.shuttleGray};
  width: 100%;
  text-align: center;
  order: 1;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
    margin-bottom: 0;
    order: 0;
    text-align: left;
  }
`;

const textStyles = css`
  font-size: 12px;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
`;

const FooterLink = styled(Link)`
  ${textStyles};

  & + &:before {
    content: '';
    display: inline-block;
    height: 12px;
    width: 1px;
    background-color: ${color(colors.shuttleGray).lighten(0.65).hex()};
    margin: 0 5px;
    position: relative;
    top: 2px;
  }
`;

const GoogleCaptchaTermsBlock = styled(Text)`
  ${textStyles};
  color: ${colors.shuttleGray};

  ${getDownMedia('sm')} {
    padding: 0 10%;
    margin-bottom: 10px;
    text-align: center;
  }
`;

const GoogleCaptchaLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: 300;
`;

const FooterSocialLinksList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const FooterSocialLinkListItem = styled.li`
  margin-left: 6px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    &:first-child {
      margin-left: 0;
    }
  }
`;

const FooterSocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  transition: background-color 0.15s ease-in;

  svg {
    fill: ${({ theme }) => theme.palette.primary.main};
  }

  &:hover {
    border-color: ${colors.darkBlue};

    svg {
      fill: ${colors.darkBlue};
    }
  }
`;

export { AppFooter };
