/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { colors, ColorName } from '../../utils/colors';
import { lineStyles } from '../../styles/title-line-styles';
import { Text as DefText, TextProps } from './Text';

export type TitleProps = TextProps & CustomProps;

const Title: React.FC<TitleProps> = ({ withLine = false, ...props }) => (
  <Text variant={'h1'} withLine={withLine} {...props} />
);

type CustomProps = {
  withLine?: boolean;
  titleLineColor?: ColorName;
};

const Text = styled(({ withLine, titleLineColor, ...props }: TitleProps) => (
  <DefText {...props} />
))`
  ${({ theme: { palette }, withLine, titleLineColor }) =>
    withLine
      ? css`
          padding-top: 18px;
          position: relative;

          &:before {
            ${lineStyles};
            background-color: ${titleLineColor
              ? colors[titleLineColor]
              : palette.secondary.main};
            position: absolute;
            top: 0;
            left: 2px;
          }
        `
      : css`
          padding-top: 20px;
        `};
`;

export { Title };
