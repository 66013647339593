/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeUserEmailForm } from '../forms';
import { SectionTitle } from './SectionTitle';
import { SectionWrapper } from './SectionWrapper';

export type EmailChangeFormSectionProps = ComponentProps<typeof SectionWrapper>;

const EmailChangeFormSection = (props: EmailChangeFormSectionProps) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper {...props}>
      <SectionTitle>{t('CHANGE_USER_EMAIL_FORM__title')}</SectionTitle>
      <ChangeUserEmailForm />
    </SectionWrapper>
  );
};

export { EmailChangeFormSection };
