/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from '@mui/material';
import { useDownloadFile } from '../../hooks';
import { Spinner as DefSpinner } from '../../styled/Spinner';

export type DownloadFileLinkProps = Omit<LinkProps, 'href'> & {
  href: string;
};

const DownloadFileLink = ({
  children,
  href,
  ...props
}: DownloadFileLinkProps) => {
  const { loadFile, fileIsLoading } = useDownloadFile(href);

  return (
    <Link onClick={() => loadFile()} {...props}>
      {fileIsLoading ? <Spinner size={14} /> : children}
    </Link>
  );
};

const Spinner = styled(DefSpinner)`
  margin: 0;
`;

export { DownloadFileLink };
