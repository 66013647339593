/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendSentryError } from '../../utils/sentry';
import {
  GetUserTotpStatusDocument,
  useTotpDisableMutation,
} from '../../apollo';
import { useAlert, useModals } from '../../providers';
import { TwoFACode } from '../TwoFACode';

const Disable2FAModal = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { dispatch } = useModals();
  const [backupCodeFieldVisible, setBackupCodeFieldVisible] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'disable-2-fa',
        titleText: t(
          !backupCodeFieldVisible
            ? 'DISABLE_2FA_MODAL__title'
            : 'DISABLE_2FA_MODAL__codesTitle'
        ),
      },
    });
  }, [dispatch, t, backupCodeFieldVisible]);

  const [disableTOTP, { loading }] = useTotpDisableMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [{ query: GetUserTotpStatusDocument }],
    onCompleted: ({ totpDisable: { success } }) => {
      if (!success) {
        setAlert({ type: 'warning', message: t('DISABLE_2FA_MODAL__error') });
        sendSentryError(new Error(t('DISABLE_2FA_MODAL__error')));
        return;
      }

      setAlert({ type: 'success', message: t('DISABLE_2FA_MODAL__success') });
      dispatch({ type: 'closeModal' });
    },
    onError: (error) => {
      setAlert({ type: 'error', message: t('DISABLE_2FA_MODAL__error') });
      sendSentryError(error);
    },
  });

  return (
    <TwoFACode
      loading={loading}
      submitCodeButtonText={t('TWO_FA_MODAL__disabledButton')}
      onBackupCodeVisibilityChange={setBackupCodeFieldVisible}
      onCodeFill={(code) => disableTOTP({ variables: { code } })}
    />
  );
};

export { Disable2FAModal };
