/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isString } from 'lodash-es';
import copy from 'copy-to-clipboard';
import { Maybe } from '../apollo';

type Options = Parameters<typeof copy>[1];

export const copyToClipboard = (text: Maybe<string>, options?: Options) => {
  if (!isString(text)) return false;

  return copy(text, {
    message: 'please confirm copy',
    ...options,
  });
};
