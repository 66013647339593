/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useCallback, useEffect, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { useWindowSize } from '../hooks';
import { theme } from '../styles';
import { GlobalStyles } from '../styles/GlobalStyles';

const Styles: React.FC<PropsWithChildren> = ({ children }) => {
  const { height } = useWindowSize();

  const setAppHeight = useCallback(() => {
    const doc = document.documentElement;
    const height = window.innerHeight;
    doc.style.setProperty('--app-height', `${height}px`);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setAppHeight);

    return () => window.removeEventListener('resize', setAppHeight);
  }, [height, setAppHeight]);

  useEffect(() => {
    setAppHeight();
  }, [setAppHeight]);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyles />
            {children}
          </>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export { Styles };
