/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Form as DefForm, Formik, FormikFormProps, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { externalLinkProps } from '../../utils/consts';
import { sendSentryError } from '../../utils/sentry';
import { getChangeUserEmailFormSchema } from '../../utils/validation-schemas';
import {
  AuthIdentityProvider,
  useChangeUserEmailMutation,
  useUserAuthIdentitiesQuery,
} from '../../apollo';
import { getDownMedia, getUpMedia } from '../../styles';
import { useAlert } from '../../providers';
import { paths } from '../../pages';
import { commonInputHalfWidthStyles } from '../../styles/account-page';
import { Button as DefButton } from '../buttons';
import { FormikInput, FormikInputProps } from '../formik-elements';
import { RouteLink } from '../texts';

const ChangeUserEmailForm = (props: FormikFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const { data: { getUser: user } = {} } = useUserAuthIdentitiesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { email, googleConnected } = useMemo(
    () => ({
      email: user?.authIdentities[0].uid ?? '',
      googleConnected: !!user?.authIdentities?.find(
        ({ provider }) => provider === AuthIdentityProvider.Google
      ),
    }),
    [user]
  );

  const initialValues = useMemo(
    () =>
      googleConnected
        ? {
            email,
          }
        : { email, currentPassword: '' },
    [googleConnected, email]
  );

  const { schema, fields } = useMemo(
    () => ({
      schema: getChangeUserEmailFormSchema(),
      fields: getFieldsDataByName(t),
    }),
    [t]
  );

  const [changeEmail] = useChangeUserEmailMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setAlert({
        type: 'success',
        message: t('CHANGE_USER_EMAIL_FORM__successText'),
      });
    },
    onError: (error) => {
      setAlert({
        type: 'error',
        message: t('CHANGE_USER_EMAIL_FORM__errorText'),
      });
      sendSentryError(error);
    },
  });

  if (!user) return null;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize
      onSubmit={async (values) => {
        if (!(values.email && values.currentPassword)) return;

        !isEqual(initialValues, values) &&
          (await changeEmail({ variables: values }));
      }}
    >
      {({ values, isSubmitting }: FormikProps<ChangeUserEmailFormValues>) => (
        <Form $extraBottomOffset={googleConnected} {...props}>
          {Object.keys(values).map((name) => {
            if (name === 'currentPassword' && googleConnected) return null;

            const inputProps = fields[name];
            const helperText =
              name === 'email' && googleConnected ? (
                <>
                  {t('CHANGE_USER_EMAIL_FORM__googleEmailHelperText1')}{' '}
                  {t('CHANGE_USER_EMAIL_FORM__googleEmailHelperText2')}{' '}
                  <RouteLink to={paths.contacts} {...externalLinkProps}>
                    {t('CHANGE_USER_EMAIL_FORM__googleEmailHelperText3')}
                  </RouteLink>{' '}
                  {t('CHANGE_USER_EMAIL_FORM__googleEmailHelperText4')}
                </>
              ) : (
                inputProps.helperText
              );

            return (
              <Input
                key={name}
                id={`${formPrefix}-${name}`}
                name={name}
                disabled={googleConnected || isSubmitting}
                helperText={helperText}
                {...inputProps}
              />
            );
          })}
          {!googleConnected && (
            <BottomBlock>
              <Button type={'submit'} loading={isSubmitting}>
                {t('CHANGE_USER_EMAIL_FORM__buttonText')}
              </Button>
            </BottomBlock>
          )}
        </Form>
      )}
    </Formik>
  );
};
export type ChangeUserEmailFormValues = {
  email: string;
  currentPassword?: string;
};

const Form = styled(DefForm)<{
  $extraBottomOffset: boolean;
}>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${({ $extraBottomOffset }) =>
    $extraBottomOffset &&
    css`
      ${getUpMedia('md')} {
        margin-bottom: 20px;
      }
    `}
`;

const Input = styled(FormikInput)`
  ${commonInputHalfWidthStyles};
`;

const BottomBlock = styled.div`
  display: flex;
  flex: 0 0 100%;
  margin-top: 20px;
  justify-content: flex-end;

  ${getDownMedia('sm')} {
    margin-top: 12px;
  }
`;

const Button = styled(DefButton)`
  ${getUpMedia('sm')} {
    min-width: 144px;
  }
`;

const getFieldsDataByName: (t: TFunction) => {
  [key in keyof ChangeUserEmailFormValues]: Omit<FormikInputProps, 'name'>;
} = (t) => ({
  email: {
    type: 'text',
    label: t<string>('COMMON_FORMS__emailFieldPlaceholder'),
  },
  currentPassword: {
    type: 'password',
    label: t<string>('COMMON_FORMS__currentPasswordFieldPlaceholder'),
  },
});

const formPrefix = 'change-user-email-form';

export { ChangeUserEmailForm };
