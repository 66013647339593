/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { externalLinkProps, SiteData } from 'utils/consts';
import { semiBoldText } from 'utils/fonts';
import { ArrowRightAlt as DefArrowRightAlt } from '@mui/icons-material';
import { Link } from '@mui/material';
import { useModals } from '../../providers';
import { paths } from '../../pages';
import { Button as DefButton } from '../buttons';
import { Text } from '../texts';

const Reminder2FAModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useModals();

  useEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'reminder-2-fa',
        titleText: t('REMINDER_2FA_MODAL__title'),
        noScrollBar: true,
      },
    });
  }, [dispatch, t]);

  return (
    <>
      <DescText>{t('REMINDER_2FA_MODAL__desc1')}</DescText>
      <DescText>
        {t('REMINDER_2FA_MODAL__desc2')}{' '}
        <Link href={SiteData.GOOGLE_AUTHENTICATOR} {...externalLinkProps}>
          {t('REMINDER_2FA_MODAL__desc2Link')}
        </Link>
        .
      </DescText>
      <Button
        onClick={() => {
          dispatch({ type: 'closeModal' });
          navigate(paths.account, {
            state: { show2FAEnableModal: true },
          });
        }}
      >
        {t('REMINDER_2FA_MODAL__buttonText')}
      </Button>
      <BottomLink href={SiteData.SECURITY_LINK} {...externalLinkProps}>
        {t('REMINDER_2FA_MODAL__bottomLinkText')} <ArrowRightAlt />
      </BottomLink>
    </>
  );
};

/*const ModalWrapper = styled(DefModalWrapper)`
  .MuiDialogTitle-root {
    width: 100%;
    margin: 0 0 14px;
  }
`;*/

const DescText = styled(Text)`
  margin-bottom: 20px;
`;

const Button = styled(DefButton)`
  margin-bottom: 20px;
`;

const BottomLink = styled(Link)`
  display: flex;
  align-items: center;
  ${semiBoldText}
`;

const ArrowRightAlt = styled(DefArrowRightAlt)`
  margin-left: 5px;
`;

export { Reminder2FAModal };
