/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { forwardRef, ComponentProps } from 'react';
import styled from 'styled-components';
import type { ColorValue } from '../../utils/colors';

export type IconProps = ComponentProps<typeof Svg> & {
  fill?: ColorValue | 'none';
};

const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ children, viewBox = '0 0 30 30', ...rest }, ref) => (
    <Svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill={'currentColor'}
      {...rest}
    >
      {children}
    </Svg>
  )
);

const Svg = styled.svg`
  width: 20px;
  height: 20px;
  max-width: 100%;
  max-height: 100%;
  color: #fff;
`;

export { Icon };
