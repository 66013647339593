/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleRecaptchaContext } from '../providers';

export type GoogleRecaptchaProps = React.HTMLAttributes<HTMLDivElement>;

const GoogleRecaptchaV2 = (props: GoogleRecaptchaProps) => {
  const { version, setV2Ref, setV2Token } = useGoogleRecaptchaContext();

  return version === 'V2' ? (
    <Wrapper {...props}>
      <ReCAPTCHA ref={setV2Ref} sitekey={key} onChange={setV2Token} />
    </Wrapper>
  ) : null;
};

const key = process.env.REACT_APP_GOOGLE_RECAPTCHA_V2 ?? '';

const Wrapper = styled.div`
  width: calc(100% + 2px);
  margin-top: 12px;
  margin-bottom: 8px;
  margin-right: -2px;
`;

export { GoogleRecaptchaV2 };
