/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { gql } from '@apollo/client';

export const typeDefs = gql`
  directive @client(always: Boolean) on FIELD
  directive @export(as: String!) on FIELD

  enum PortfolioCurrency {
    EUR
    USD
  }

  extend type Query {
    portfolioCurrency: PortfolioCurrency
    selectedExchange: ExchangeCode
  }

  extend type UserExchangeResponse {
    aumBelowMinLastShowTime: String
  }
`;
