/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useCallback,
  useRef,
  useState,
  FC,
  PropsWithChildren,
  ReactNode,
  ComponentType,
} from 'react';
import styled from 'styled-components';
import {
  ClickAwayListener,
  Button as DefButton,
  Popper as DefPopper,
  Fade,
} from '@mui/material';

// import { ButtonProps } from '@material-ui/core/Button';

const Button = styled(DefButton)`
  min-width: 0;
  padding: 0;
  margin: 0;
`;

const PopperContentWrapper = styled.div`
  padding: 4px 6px;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  background-color: #fff;
`;

type Props = {
  className?: string;
  tooltipContent: ReactNode | ComponentType<any>;
};

const Popper: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  tooltipContent,
}) => {
  const anchorRef = useRef(null);
  const [popperOpened, setPopperOpened] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClose = useCallback(() => {
    setPopperOpened(false);
    setAnchorEl(null);
  }, []);

  const handleClick = (event: React.PointerEvent<HTMLButtonElement>) => {
    if (popperOpened) return;

    setAnchorEl(event.currentTarget);
    setPopperOpened(true);
  };

  const id = popperOpened ? 'description-popper' : undefined;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Button
          ref={anchorRef}
          className={className}
          aria-label="Element with popup"
          aria-describedby={id}
          variant={'text'}
          disableRipple={true}
          onClick={handleClick}
        >
          {children}
        </Button>
        <DefPopper
          id={id}
          open={popperOpened}
          anchorEl={anchorEl}
          placement={'right'}
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ],
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              {/*
              //@ts-ignore*/}
              <PopperContentWrapper>{tooltipContent}</PopperContentWrapper>
            </Fade>
          )}
        </DefPopper>
      </div>
    </ClickAwayListener>
  );
};

export { Popper };
