/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

// #3AB047

const PieIcon = (props: IconProps) => (
  <Icon viewBox="0 0 11 11" {...props}>
    <g fillRule="nonzero">
      <path d="M9.625 6.188c0 1.332-.469 2.467-1.407 3.405C7.28 10.531 6.145 11 4.813 11c-1.333 0-2.468-.469-3.406-1.407C.469 8.655 0 7.52 0 6.188 0 4.854.469 3.72 1.407 2.781c.938-.938 2.073-1.407 3.405-1.407v4.813h4.813z" />
      <path d="M11 5.042a.224.224 0 01-.064.165.224.224 0 01-.165.064H5.729V.229c0-.068.021-.123.064-.165A.224.224 0 015.958 0c1.392 0 2.58.492 3.565 1.477C10.508 2.46 11 3.65 11 5.042z" />
    </g>
  </Icon>
);

export { PieIcon };
