/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useEffect, RefObject } from 'react';
import { useIntersection } from 'react-use';
import { useSpring } from '@react-spring/web';
import { useIsMobile } from './useIsMobile';

export const useHorizontalDemoScroll = <T extends HTMLElement>(
  ref: RefObject<T>,
  scrollValue = 150
) => {
  const mobile = useIsMobile();

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.6,
  });

  const [{ scroll }, api] = useSpring(() => ({
    from: { scroll: 0 },
    to: async (next) => {
      await next({ scroll: scrollValue });
      await next({ scroll: 0 });
    },
    pause: true,
  }));

  useEffect(() => {
    if (!mobile || !ref.current || ref.current.scrollLeft !== 0) return;
    if (!intersection || !intersection.isIntersecting) return;
    api.resume();
    console.log('CALL');
  }, [mobile, ref, intersection, api]);

  return scroll;
};
