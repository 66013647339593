/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo, ComponentProps } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getPaymentInfoString } from '../../utils/payments';
import { useCreateMollieClient, useIsMobile } from '../../hooks';
import { useMolliePaymentInfoQuery } from '../../apollo';
import { getDownMedia } from '../../styles';
import { usePayments } from '../../providers';
import mobImg from '../../assets/images/_Payment_icons_-_Block.svg';
import descImg from '../../assets/images/_Payment_icons_-_Line.svg';
import { horizontalSectionButtonStyles } from '../../styles/account-page';
import { Button as DefButton } from '../buttons';
import { Text } from '../texts';
import { SectionTitle } from './SectionTitle';
import { SectionWrapper } from './SectionWrapper';

export type PaymentSectionProps = ComponentProps<typeof SectionWrapper>;

const PaymentSection = (props: PaymentSectionProps) => {
  const { t } = useTranslation();
  const { togglePaymentModal } = usePayments();
  const mobile = useIsMobile();

  const { data: { getMolliePaymentInfo: paymentInfo } = {} } =
    useMolliePaymentInfoQuery({
      fetchPolicy: 'cache-and-network',
    });

  const paymentInfoText = useMemo(() => {
    if (!paymentInfo) return null;

    try {
      return getPaymentInfoString(paymentInfo, t);
    } catch (e) {
      return null;
    }
  }, [paymentInfo, t]);

  const [adjustMollieData, { loading: adjustingMollieData }] =
    useCreateMollieClient();

  if (!paymentInfo) return null;

  return (
    <SectionWrapper $horizontal {...props}>
      <Content>
        <SectionTitle $noBottomMargin>
          {t('ADJUST_CREDIT_CARD_SECTION__title')}
        </SectionTitle>
        <TextContent>
          {!!paymentInfoText && <Desc>{paymentInfoText}</Desc>}
          {mobile ? (
            <DescImg src={mobImg} alt={'Payment options'} />
          ) : (
            <DescImg src={descImg} alt={'Payment options'} />
          )}
        </TextContent>
      </Content>
      <Button
        loading={adjustingMollieData}
        onClick={
          !!paymentInfoText ? adjustMollieData : () => togglePaymentModal()
        }
      >
        {t(
          !paymentInfoText
            ? 'ADJUST_CREDIT_CARD_SECTION__addButtonText'
            : 'ADJUST_CREDIT_CARD_SECTION__changeButtonText'
        )}
      </Button>
    </SectionWrapper>
  );
};

const Content = styled.div``;

const TextContent = styled.div`
  ${getDownMedia('sm')} {
   margin-top: 20px;
  }`;

const Desc = styled(Text)`
  white-space: pre-wrap;
  margin-top: 4px;
`;

const DescImg = styled.img`
  width: 500px;
  height: auto;
  margin-top: 6px;

  ${getDownMedia(1500)} {
    width: 400px;
  }

  ${getDownMedia('sm')} {
    width: auto;
  }
`;

const Button = styled(DefButton)`
  ${horizontalSectionButtonStyles};
`;

export { PaymentSection };
