/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { toNumber, uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { Form, Formik, FormikFormProps, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { serverResponseErrorsCodes } from 'utils/consts';
import { sendSentryError } from 'utils/sentry';
import { getErrorCodesOfServerResponse } from 'utils/server-helpers';
import * as Yup from 'yup';
import { MenuItem as DefMenuItem } from '@mui/material';
import { getCryptoIconPath } from '../../utils/portfolio-helpers';
import { useAlertModal } from 'hooks';
import { usePortfolioLiquidateMutation, DashboardDataQuery } from 'apollo';
// import { getColor } from '../../styles';
import { useAlert, useExchange } from '../../providers';
import { Spinner } from '../../styled';
import {
  bottomButtonStyles,
  commonBottomBlocksTextStyles,
} from '../../styles/dashboard-page';
import { Button as DefButton } from '../buttons';
import { FormikInput } from '../formik-elements';
import { Text } from '../texts';

export type LiquidationFormProps = {
  portfolio: DashboardDataQuery['getPortfolio'];
} & FormikFormProps;

const LiquidationForm = ({ portfolio, ...props }: LiquidationFormProps) => {
  const { t } = useTranslation();
  const { dispatch: dispatchAlertModal } = useAlertModal();
  const { setAlert } = useAlert();
  const { exchange } = useExchange();

  const schema = useMemo(() => getSchema(t), [t]);

  const sortedSaleCurrencies = useMemo(() => {
    const currencies = exchange?.targetForSaleCurrencies ?? [];
    return [...currencies].sort((a, b) => {
      if (a.isFiat === b.isFiat) {
        return a.symbol.localeCompare(b.symbol);
      }
      return a.isFiat ? -1 : 1;
    });
  }, [exchange?.targetForSaleCurrencies]);

  const { loading: loadingCurrenciesOptions, value: currenciesOptions = [] } =
    useAsync(async () => {
      const [fiatOptions] = await Promise.all([
        Promise.all(
          sortedSaleCurrencies.map(async ({ id, symbol }, index, arr) => ({
            label: symbol,
            value: id,
            icon: await getCryptoIconPath(symbol),
            lastFiatCurrency: index + 1 === arr.length,
          }))
        ),
      ]);
      return uniqBy([...fiatOptions], 'value');
    }, [sortedSaleCurrencies, portfolio]);

  const [liquidate] = usePortfolioLiquidateMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      dispatchAlertModal(['setModalContent', 'pendingSellCrypto']);
    },
    onError: (error: any) => {
      const lowLiquidationMoneyError = getErrorCodesOfServerResponse(
        error
      ).some((er) => er === serverResponseErrorsCodes.NOT_ENOUGH_MONEY_TO_SELL);

      const lowLeftMoneyError = getErrorCodesOfServerResponse(error).some(
        (er) => er === serverResponseErrorsCodes.NOT_ENOUGH_MONEY
      );

      let message;

      if (lowLiquidationMoneyError) {
        message = t('LIQUIDATION_FORM__lowPercentageValueToSellError');
      } else if (lowLeftMoneyError) {
        message = t('LIQUIDATION_FORM__notEnoughMinLeftFundsError');
      } else {
        message = t('LIQUIDATION_FORM__errorText');
      }

      setAlert({ type: 'error', message });

      !lowLiquidationMoneyError && !lowLeftMoneyError && sendSentryError(error);
    },
  });

  return (
    <Formik
      initialValues={{
        percents: '',
        currencyId: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (!exchange?.exchangeID) {
            throw new Error('No exchange id in liquidate mutation');
          }

          await liquidate({
            variables: {
              exchangeId: toNumber(exchange.exchangeID),
              currencyId: toNumber(values.currencyId),
              percents: parseFloat(values.percents),
            },
          });
        } catch (e) {
          sendSentryError(e);
          setAlert({
            type: 'error',
            message: t('COMMON_ERROR'),
          });
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={schema}
    >
      {({ isSubmitting }: FormikProps<FormValues>) => (
        <Wrapper {...props}>
          <Title component={'h4'} variant={'subtitle2'}>
            {t('LIQUIDATION_FORM__title')}
          </Title>
          <Content>
            <Input
              id={`liquidation-form-percent`}
              name={'percents'}
              type={'number'}
              label={'%'}
              fullWidth={false}
              margin={'none'}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            />
            <Input
              id={`liquidation-form-currency-id`}
              name={'currencyId'}
              type={'number'}
              select
              label={t('LIQUIDATION_FORM__currencyPlaceholder')}
              fullWidth={false}
              margin={'none'}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            >
              {loadingCurrenciesOptions ? (
                <Spinner
                  size={24}
                  style={{
                    margin: '10px 20px',
                  }}
                />
              ) : (
                currenciesOptions.map(({ label, value, icon }) => (
                  <DefMenuItem
                    key={value}
                    value={value}
                    // $lastFiatCurrency={!!lastFiatCurrency}
                  >
                    {!!icon && <IconImg src={icon} />}
                    {label.toUpperCase()}
                  </DefMenuItem>
                ))
              )}
            </Input>
            <Button
              type={'submit'}
              loading={isSubmitting}
              disabled={isSubmitting}
              buttonStyles={css`
                height: 100%;
              `}
            >
              {t('LIQUIDATION_FORM__buttonText')}
            </Button>
          </Content>
        </Wrapper>
      )}
    </Formik>
  );
};

type FormValues = {
  percents: string;
  currencyId: string;
};

// @ts-ignore
const getSchema = (t: TFunction): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    percents: Yup.string()
      .test({
        name: 'min-value-test',
        test: (value) =>
          !value || parseInt(value) === 100 || 1 <= parseInt(value),
        message: t('LIQUIDATION_FORM__minValueError'),
      })
      .test({
        name: 'max-value-test',
        test: (value) =>
          !value || parseInt(value) === 100 || parseInt(value) <= 75,
        message: t('LIQUIDATION_FORM__maxValueError'),
      })
      .required(t('COMMON_FORMS_ERROR__required')),
    currencyId: Yup.string().required(t('COMMON_FORMS_ERROR__required')),
  });

const Wrapper = styled(Form)``;

const Title = styled(Text)`
  margin-bottom: 8px;
  ${commonBottomBlocksTextStyles}
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
  }
`;

const Input = styled(FormikInput)`
  flex: 0 0 calc(48%);

  .MuiSelect-select {
    display: flex;
    align-items: center;
  }

  .MuiInputLabel-root.Mui-disabled {
    display: none;
  }
`;

/*const MenuItem = styled(DefMenuItem)<{
  $lastFiatCurrency: boolean;
}>`
  ${({ $lastFiatCurrency }) =>
  $lastFiatCurrency &&
  css`
      border-bottom: 1px solid ${getColor('beige')}; ;
    `}
`;*/

const IconImg = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
`;

const Button = styled(DefButton)`
  ${bottomButtonStyles};
  margin-top: 22px;
  width: 100%;
` as typeof DefButton;

export { LiquidationForm };
