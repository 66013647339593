/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { sendSentryError } from '../../utils/sentry';
import { useUserAdminStatusQuery } from '../../apollo';
import { useAlert } from '../../providers';
import { AppPageContentWrapper as DefAppPageContentWrapper } from '../../styled';
import { InvoicesTable, AdminInvoicesTable } from '../../components/tables';
import { AppPageLayout } from '../../layouts';

const InvoicesPage = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { data: { getUser: user } = {} } = useUserAdminStatusQuery({
    onError: (error) => {
      setAlert({ type: 'error', message: t('COMMON_ERROR') });
      sendSentryError(error);
    },
  });

  if (!user) return null;

  return (
    <AppPageLayout>
      <AppPageContentWrapper>
        <ScrollContainer>
          {user.isAdmin ? <AdminInvoicesTable /> : <InvoicesTable />}
        </ScrollContainer>
      </AppPageContentWrapper>
    </AppPageLayout>
  );
};

const AppPageContentWrapper = styled(DefAppPageContentWrapper)`
  padding: 25px 30px 30px;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

export { InvoicesPage };
