/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useCallback } from 'react';
import { isString } from 'lodash-es';
import { useDesktopSmall } from './useDesktopSmall';
import { useIsExactTablet } from './useIsExactTablet';
import { useIsMobile } from './useIsMobile';

export const useTruncateText = () => {
  const isTablet = useIsExactTablet();
  const isMobile = useIsMobile();
  const isDesktopSmall = useDesktopSmall();

  return useCallback(
    (text?: string, customConfig?: Config) => {
      if (!isString(text)) return '';

      const config = {
        ...defConfig,
        ...customConfig,
      };

      if (text.length <= config.length) {
        return text;
      }

      switch (config.widthType) {
        case 'mobile':
          return isMobile ? `${text.substring(0, config.length)}...` : text;
        case 'tablet':
          return isTablet ? `${text.substring(0, config.length)}...` : text;
        case 'desktop-small':
          return isDesktopSmall
            ? `${text.substring(1, config.length)}...`
            : text;
        default:
          return text;
      }
    },
    [isTablet, isMobile, isDesktopSmall]
  );
};

type Config = {
  widthType?: 'mobile' | 'tablet' | 'desktop-small';
  length?: number;
};

const defConfig: Required<Config> = {
  widthType: 'mobile',
  length: 10,
};
