/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';

export const MobileBr = styled.br`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;
