import { useUnmount, useUpdateEffect } from 'react-use';
import { useFormik, FormikConfig } from 'formik';

export const useCustomFormik = <T extends Record<string, unknown>>(
  config: FormikConfig<T>,
  localStorageData?: Partial<Record<keyof T, string>>,
  clearOnPageChange = false
) => {
  const formikData = useFormik(
    (() => {
      if (!localStorageData) {
        return config;
      }
      return {
        ...config,
        initialValues: Object.entries(config.initialValues).reduce(
          (acc, [key, value]) => {
            const localStorageKey = localStorageData[key] as string;
            const savedValue = localStorageKey
              ? localStorage.getItem(localStorageKey) ?? ''
              : '';
            return {
              ...acc,
              [key]: value || savedValue,
            };
          },
          {} as T
        ),
      };
    })()
  );

  useUpdateEffect(() => {
    if (!localStorageData) return;

    Object.entries(localStorageData).forEach(([field, localStorageKey]) => {
      const value = formikData.values[field] as string;

      if (value) {
        localStorage.setItem(localStorageKey!, value);
      } else {
        localStorage.removeItem(localStorageKey!);
      }
    });
  }, [formikData, localStorageData]);

  useUnmount(() => {
    if (!localStorageData) return;
    if (clearOnPageChange) {
      Object.values(localStorageData).forEach((localStorageKey) => {
        localStorage.removeItem(localStorageKey!);
      });
    }
  });

  return formikData;
};
