/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

let id: string | null = null;

const storeName = 'authIdentityId';

export const getAuthIdentityId = (): typeof id => {
  if (!!id) return id;

  id = localStorage.getItem(storeName);

  return id;
};

export const setAuthIdentityId = (newToken: string) => {
  id = newToken;

  return localStorage.setItem(storeName, id);
};

export const removeAuthIdentityId = () => {
  id = null;

  return localStorage.removeItem(storeName);
};
