/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Link,
  TableCell as DefTableCell,
  TableRow as DefTableRow,
} from '@mui/material';
import { TableRowProps } from '@mui/material/TableRow';
import { exchangesCodesImages, investOptions } from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import moment from '../../utils/moment';
import { capitalizeAllWords } from '../../utils/text-helpers';
import {
  customUserStatusesTranslations,
  exchangesKeysTranslations,
} from '../../utils/translations';
import { AdminAllUsersQuery, KeysStatus, MollieStatus } from '../../apollo';
import { getColor, getUpMedia } from '../../styles';
import { useModals } from '../../providers';
import { adminUsersTableExchangeIcon } from '../../styles/admin';
import { Button as DefButton } from '../buttons';
import { Checkbox } from '../form-elements';
import { AdminUserTableColumn } from '../tables';
import { AdminTableEmailCell as DefAdminTableEmailCell } from './AdminTableEmailCell';
import { AdminUsersTableExchangesButtons } from './AdminUsersTableExchangesButtons';

export type UserTableRowProps = TableRowProps & {
  columns: AdminUserTableColumn[];
  user: UserResponse;
  selected: boolean;
  onSelect: () => void;
  onUserClick: () => void;
};

const AdminTableUserRow = ({
  columns,
  user: rawUser,
  selected,
  onSelect,
  onUserClick,
  ...props
}: UserTableRowProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals();

  const user = useMemo(() => modifyUserResponse(rawUser), [rawUser]);

  const userId = parseInt(user.id);

  return (
    <TableRow $invalidUserStatus={user.invalidUserStatus} {...props}>
      <TableCell>
        <Checkbox checked={selected} onClick={onSelect} />
      </TableCell>
      {columns.map((key, index) => {
        const userValue = user[key];
        let Cell = TableCell;
        let cellValue;

        switch (key) {
          case 'id':
          case 'name':
            if (key === 'name') {
              Cell = NameCell;
            }
            cellValue = (
              <Link onClick={onUserClick}>{capitalizeAllWords(userValue)}</Link>
            );
            break;
          case 'email':
            Cell = AdminTableEmailCell;
            cellValue = userValue;
            break;
          case 'amountToInvest':
            Cell = AmountToInvestCell;
            cellValue = (
              <>
                <div>
                  {investOptions.find(
                    ({ value: amountValue }) => amountValue === userValue
                  )?.['label'] ?? ''}
                </div>
              </>
            );
            break;
          case 'customStatus':
            Cell = UserStatusCell;
            cellValue = t(customUserStatusesTranslations[userValue]);
            break;
          case 'exchangesStatuses':
            Cell = ExchangeStatusCell;
            cellValue = (
              <div>
                {user.exchanges.map(({ exchangeCode, keysStatus }) => (
                  <p
                    key={exchangeCode}
                    className={
                      keysStatus !== KeysStatus.Ok
                        ? 'invalid-exchange-status'
                        : ''
                    }
                  >
                    <img
                      src={exchangesCodesImages[exchangeCode]}
                      alt={exchangeCode}
                    />
                    <span>{t(exchangesKeysTranslations[keysStatus])}</span>
                  </p>
                ))}
              </div>
            );
            break;
          case 'tfa':
            cellValue = (
              <Button
                disabled={!user.totpEnabled}
                onClick={() => {
                  dispatch({
                    type: 'setModalContent',
                    payload: {
                      name: 'disable2FAAdmin',
                      data: {
                        userId,
                      },
                    },
                  });
                }}
              >
                {t('ADMIN_ALL_USERS_PAGE__disableTOTPButtonText')}
              </Button>
            );
            break;
          case 'deactivateSubscription':
          case 'rebalance':
          case 'keysRemovement':
            cellValue = (
              <AdminUsersTableExchangesButtons
                userId={userId}
                exchanges={user.exchanges}
                column={key}
              />
            );
            break;
          default:
            cellValue = userValue ?? null;
        }

        return <Cell key={index}>{cellValue}</Cell>;
      })}
    </TableRow>
  );
};

type UserResponse = AdminAllUsersQuery['adminGetUsers']['users'][number];

type User = Pick<
  UserResponse,
  | 'id'
  | 'phone'
  | 'customStatus'
  | 'exchanges'
  | 'dateOfSignup'
  | 'totpEnabled'
  | 'amountToInvest'
  | 'hasActiveSubscription'
> & {
  name: string;
  email: string;
  amount: string;
  paid: string;
  invalidUserStatus: boolean;
};

const AdminTableEmailCell = styled(DefAdminTableEmailCell)`
  > p {
    text-transform: lowercase;
    max-width: 360px;
    ${getUpMedia('lg')} {
      min-width: 200px;
    }
  }
`;
const TableCell = styled(DefTableCell)``;
const NameCell = styled(TableCell)`
  a {
    display: block;
    min-width: 120px;
  }
`;
const AmountToInvestCell = styled(TableCell)`
  div {
    min-width: 80px;
  }
`;
const UserStatusCell = styled(TableCell)``;
const ExchangeStatusCell = styled(TableCell)`
  div {
    min-width: 220px;

    p {
      display: flex;
      align-items: center;

      &.invalid-exchange-status {
        color: ${getColor('red')};
      }

      img {
        ${adminUsersTableExchangeIcon};
        margin-right: 6px;
      }
    }
  }

  p + p {
    margin-top: 8px;
  }
`;
const TableRow = styled(DefTableRow)<{
  $invalidUserStatus: boolean;
}>`
  ${({ $invalidUserStatus }) =>
    $invalidUserStatus &&
    css`
      & ${UserStatusCell} {
        color: ${getColor('red')};
      }
    `}
`;
const Button = styled(DefButton)`
  white-space: nowrap;

  & + & {
    margin-top: 10px;
  }
`;

const modifyUserResponse = ({
  id,
  firstName,
  lastName,
  authIdentities,
  phone,
  customStatus,
  exchanges,
  dateOfSignup,
  amountToInvest,
  moneyPaid,
  totpEnabled,
  hasActiveSubscription,
  hasUnpaidInvoices,
  trialEndDate,
  mollieStatus,
}: UserResponse): User => {
  const noPaymentMethod = mollieStatus !== MollieStatus.Ok;

  const trialEnded = !!trialEndDate && moment.utc(trialEndDate) <= moment.utc();

  return {
    id,
    name: `${firstName} ${lastName}`,
    amountToInvest,
    email: authIdentities[0]?.uid ?? '',
    phone,
    customStatus,
    exchanges,
    dateOfSignup: moment(dateOfSignup).format('l'),
    amount: getPrice(
      exchanges.reduce(
        (acc, { moneyUnderManagement = 0 }) => acc + moneyUnderManagement,
        0
      )
    ),
    paid: getPrice(moneyPaid),
    totpEnabled,
    hasActiveSubscription,
    invalidUserStatus:
      hasUnpaidInvoices ||
      (hasActiveSubscription && trialEnded && noPaymentMethod),
  };
};

export type AdminTableUser = User;

export { AdminTableUserRow };
