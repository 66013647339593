/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled, { css } from 'styled-components';
import { getColor, getDownMedia } from 'styles';

const SectionWrapper = styled.section<{
  $withSplitter?: boolean;
  $horizontal?: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${({ $horizontal }) =>
    $horizontal &&
    css`
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      ${getDownMedia('sm')} {
        flex-direction: column;
        align-items: flex-start;
      }
    `};

  ${({ $withSplitter }) =>
    $withSplitter &&
    css`
      margin-top: 40px;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        margin-bottom: 30px;
        background-color: ${getColor('lightBlue')};
      }
    `};
`;

export { SectionWrapper };
