/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { commonBottomBlocksTextStyles } from '../../styles/dashboard-page';
import { Text as DefText, TextProps } from '../texts';

export const BottomBlockText = (props: TextProps) => <Text {...props} />;

export const Text = styled(DefText)`
  margin-bottom: 8px;
  ${commonBottomBlocksTextStyles};
`;
