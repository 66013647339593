/* eslint-disable jsx-a11y/no-autofocus */

/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { getColor } from '../styles';
import { Button as DefButton } from './buttons';
import { Input as DefInput } from './form-elements';
import { TwoFactorBackupCodeForm } from './forms';
import { Text } from './texts';

type Props = {
  loading: boolean;
  submitCodeButtonText: string;
  onCodeFill: (arg0: string) => Promise<any>;
  onBackupCodeVisibilityChange?: (visible: boolean) => void;
};

const TwoFACode = ({
  loading,
  submitCodeButtonText,
  onCodeFill,
  onBackupCodeVisibilityChange,
}: Props) => {
  const { t } = useTranslation();
  const [codeFieldVisible, setCodeFieldVisible] = useState(true);
  const [userCode, setUserCode] = useState('');

  // Required to change title of bounding modal
  useEffect(() => {
    onBackupCodeVisibilityChange?.(!codeFieldVisible);
  }, [onBackupCodeVisibilityChange, codeFieldVisible]);

  return (
    <>
      {codeFieldVisible ? (
        <>
          <Input
            type={'number'}
            autoFocus
            label={t('TWO_FA_MODAL__inputLabel')}
            value={userCode}
            onKeyUp={(event) => {
              if (
                ['Enter', 'NumpadEnter'].some((name) => event.code === name)
              ) {
                onCodeFill(userCode);
              }
            }}
            onChange={({ target: { value } }) => setUserCode(value)}
          />
          <Button
            loading={loading}
            onClick={() => {
              onCodeFill(userCode);
            }}
          >
            {submitCodeButtonText}
          </Button>
          <ProceedToBackupCodesBlock>
            {t('TWO_FA_MODAL__text')}
            <br />
            <TextButton onClick={() => setCodeFieldVisible(false)}>
              {t('TWO_FA_MODAL__linkText')}
            </TextButton>
          </ProceedToBackupCodesBlock>
        </>
      ) : (
        <TwoFactorBackupCodeForm onCodeFill={onCodeFill} />
      )}
    </>
  );
};

const Input = styled(DefInput)``;

const Button = styled(DefButton)`
  margin-top: 6px;
`;

const ProceedToBackupCodesBlock = styled(Text)`
  margin-top: 34px;
  text-align: center;
  color: ${getColor('shuttleGray')};
`;

const TextButton = styled(Link)``;

export { TwoFACode };
