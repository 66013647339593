/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from './Icon';
import { Text } from './Text';
import { Title } from './Title';

// NOT USED
const InitialPortfolioRebalanceError = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon type={'sadFace'} />
      <Title>{t('INITIAL_PORTFOLIO_REBALANCE_ERROR_MODAL__title')}</Title>
      <Text>
        {t('INITIAL_PORTFOLIO_REBALANCE_ERROR_MODAL__desc1')}
        <br />
        {t('INITIAL_PORTFOLIO_REBALANCE_ERROR_MODAL__desc2')}
      </Text>
    </>
  );
};

export { InitialPortfolioRebalanceError };
