/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { MenuItem } from '@mui/material';
import { Input, InputProps } from './Input';

export type SelectProps = InputProps & {
  resetOptionText?: string;
  items: Array<{ label: string; value: string | number }>;
};

const Select = ({ resetOptionText, items, ...props }: SelectProps) => (
  <Input select {...props}>
    {!!resetOptionText && <MenuItem value={''}>{resetOptionText}</MenuItem>}
    {items.map(({ label, value }, index) => (
      <MenuItem key={index} value={value}>
        {label}
      </MenuItem>
    ))}
  </Input>
);

export { Select };
