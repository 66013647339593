/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState, useEffect, useRef, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import * as Highstock from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
import { TFunction } from 'i18next';
import { Paper } from '@mui/material';
import { colors } from '../../utils/colors';
import { getPrice } from '../../utils/currency';
import { regularTextFontFamily } from '../../utils/fonts';
import moment from '../../utils/moment';
import { capitalizeAllWords } from '../../utils/text-helpers';
import { DashboardDataQuery } from '../../apollo';

export type GraphProps = {
  data: DashboardDataQuery['getPortfolioGraphData'];
} & HTMLAttributes<HTMLDivElement>;

const Graph = ({ data, ...props }: GraphProps) => {
  const { t } = useTranslation();
  const chartCompRef = useRef<{
    chart: Highstock.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);

  const [graphOptions, setGraphOptions] =
    useState<Highstock.Options>(defaultOptions);

  // const [activeButton, setActiveButton] = useState<'linear' | 'log'>('linear');

  // Update data
  useEffect(() => {
    const portfolioGraphData = data.map(({ date, value }) => [
      parseInt(moment.utc(date).format('X')) * 1000,
      +value.toFixed(2),
    ]);

    setGraphOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        { name: 'Portefeuille-waarde', type: 'line', data: portfolioGraphData },
      ],
    }));

    window.dispatchEvent(new Event('resize'));
  }, [data]);

  // Change translation
  useEffect(() => {
    // eslint-disable-next-line import/namespace
    Highstock.setOptions({ lang: getChartTranslations(t) });
  }, [t]);

  /*const onLinearButtonPress = useCallback(() => {
    if (!chartCompRef.current) return;

    setActiveButton('linear');

    const { chart } = chartCompRef.current;

    chart.yAxis[0].update({
      type: 'linear',
    });
  }, []);*/

  /*const onLogButtonPress = useCallback(() => {
    if (!chartCompRef.current) return;

    setActiveButton('log');

    const { chart } = chartCompRef.current;

    chart.yAxis[0].update({
      type: 'logarithmic',
    });
  }, []);*/

  return (
    <Wrapper {...props}>
      <HighchartsReact
        ref={chartCompRef}
        containerProps={{ style: { height: '100%' } }}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={graphOptions}
      />
      {/*<Buttons>
       <Button
       active={activeButton === 'linear'}
       onClick={onLinearButtonPress}
       >
       Linear Scale
       </Button>
       <Button
       active={activeButton === 'log'}
       onClick={onLogButtonPress}
       >
       Log Scale
       </Button>
       </Buttons>*/}
    </Wrapper>
  );
};

exporting(Highcharts);

const Wrapper = styled(Paper)`
  position: relative;
  height: 100%;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 0 10px;
  }
`;

/*const Buttons = styled.div`
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 11px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    bottom: 0;
    right: 90px;
  }
`;*/

/*const Button = styled(({ active, ...props }) => <DefButton {...props} />)`
  .MuiButton-contained {
    padding: 4px 5px;
    min-width: 0;
    border: 1px solid
      ${({ active }) => (active ? colors.blue : colors.lightGray)};
    border-radius: 1px;
    background-color: ${({ active }) => (active ? colors.blue : 'transparent')};
    font-size: 8px;
    line-height: 8px;
    letter-spacing: normal;
    color: ${({ active }) => (active ? colors.white : colors.black)};
    text-transform: none;
  }
`;*/

const setButtonsBorderRadius = () => {
  const buttons = document.querySelectorAll(
    '.highcharts-range-selector-buttons .highcharts-button rect'
  );

  [...buttons].forEach((button) => {
    button.setAttribute('rx', '0');
    button.setAttribute('ry', '0');
  });
};

const setSquareLegend = () => {
  const legends = document.querySelectorAll('.highcharts-legend-item path');
  [...legends].forEach((legend) => legend.setAttribute('stroke-width', '9'));
};

const defaultOptions: Highstock.Options = {
  chart: {
    events: {
      load: function () {
        setButtonsBorderRadius();
        setSquareLegend();
      },
      redraw: setSquareLegend,
    },
  },
  rangeSelector: {
    x: 0,
    y: 0,
    labelStyle: {
      color: colors.black,
    },
    selected: 4,
    buttonSpacing: 0,
    buttonTheme: {
      height: 12,
      padding: 6,
      fill: '#fff',
      stroke: colors.lightGray,
      style: {
        fontSize: '8px',
        lineHeight: '8px',
        color: colors.black,
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      // After update throws a warning
      /*states: {
        select: {
          fill: colors.blue,
          style: {
            color: colors.white,
          },
        },
        hover: {
          fill: colors.blue,
          style: {
            color: colors.white,
          },
        },
      },*/
    },
  },
  colors: [colors.blue, colors.darkBlue],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return getPrice(this.value, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    opposite: false,
  },
  plotOptions: {
    series: {
      // compare: 'percent',
      // compareBase: 100,
      showInNavigator: true,
      lineWidth: 1,
      cursor: 'pointer',
      states: {
        hover: {
          enabled: true,
          lineWidth: 1,
        },
      },
    },
  },
  tooltip: {
    // pointFormat: `<span style="color:{series.color}">●</span> {series.name}: <b>€ {point.y:.2f}</b> ({point.change:.2f}%)<br/>`,
    // @ts-ignore
    formatter: function () {
      const point = this.points?.[0];
      if (!point) return null;

      // @ts-ignore
      // const change: number = point.point.change ?? 0;

      return [
        capitalizeAllWords(moment(point.x).format('dddd, MMM D, YYYY')),
        `<span style="color:${point.color}">●</span> ${
          point.series.name
        }: <b>${getPrice(point.y)}</b>`,
        // }: <b>${getPrice(point.y)}</b> (${change.toFixed(2)}%)<br/>`,
      ];
    },
    valueDecimals: 2,
    shared: true,
    shadow: false,
  },
  legend: {
    enabled: true,
    symbolWidth: 9,
    itemStyle: {
      fontSize: '12px',
      fontWeight: '400',
      fontFamily: regularTextFontFamily,
    },
  },
};

const getChartTranslations = (t: TFunction): Highstock.LangOptions => ({
  rangeSelectorZoom: t('HIGHCHARTS__rangeSelectorZoom'),
  rangeSelectorFrom: t('HIGHCHARTS__rangeSelectorFrom'),
  rangeSelectorTo: t('HIGHCHARTS__rangeSelectorTo'),
});

export { Graph };
