/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useTranslation } from 'react-i18next';
import { Icon } from './Icon';
import { Title } from './Title';

const UpdateExchangeKeysSuccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon type={'success'} />
      <Title>{t('UPDATE_EXCHANGES_KEYS_MODAL__title')}</Title>
    </>
  );
};

export { UpdateExchangeKeysSuccess };
