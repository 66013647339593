/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { FC, PropsWithChildren } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

const GoogleRecaptchaV3: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3 ?? ''}
      language={i18n.language}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export { GoogleRecaptchaV3 };
