/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {ComponentType, HTMLAttributes, useMemo} from 'react';
import styled, {css} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {
  Step as DefStep,
  StepConnector,
  StepLabel as DefStepLabel,
  Stepper as DefStepper,
} from '@mui/material';
import {withStyles} from '@mui/styles';
import {colors} from '../../utils/colors';
import {externalLinkProps, SiteData} from '../../utils/consts';
import {arialFontFamily, boldText} from '../../utils/fonts';
import {WizardStepNames} from '../../utils/wizard';
import {WizardStatusType} from '../../apollo';
import {getColor, getDownMedia, mobileContentSidePaddings,} from '../../styles';
import {useExchange, useExchangeWizard, useModals} from '../../providers';
import {
  ContentModalWhiteContent as DefContentModalWhiteContent,
  ContentModalWrapper as DefContentModalWrapper,
} from '../modal-elements';
import {Link, Text} from '../texts';
import {
  ExchangeDepositStep,
  ExchangeKeysFormStep,
  ExchangesInfoStep,
} from '../wizard-elements';

export type ExchangeWizardStepperProps = {
  withExchangeTabs?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const ExchangeWizardStepper = ({
  withExchangeTabs = false,
  ...props
}: ExchangeWizardStepperProps) => {
  const { t } = useTranslation();
  const { dispatch: dispatchModal } = useModals();
  const { exchange } = useExchange();
  const { currentStep, firstStep, lastStep, moneyExist, dispatch } =
    useExchangeWizard();

  const { title, Component } = useMemo(() => {
    const { titleData, Component } = steps[currentStep as WizardStepNames];

    const title = (() => {
      if (!exchange) {
        return titleData['title'] ?? '';
      }

      if (
        [
          currentStep === WizardStepNames.DEPOSIT,
          exchange.wizardStatus !== WizardStatusType.NoMoney,
        ].every(Boolean)
      ) {
        return titleData['moneyExistTitle'];
      }

      return (
        titleData[`${exchange.exchangeCode.toLocaleLowerCase()}Title`] ?? ''
      );
    })();

    return {
      title,
      Component,
    };
  }, [currentStep, exchange]);

  return (
    <DashboardModalWrapper
      opened
      $withExchangeTabs={withExchangeTabs}
      {...props}
    >
      <DashboardModalWhiteContent>
        <StepTitle
          variant={'h1'}
          component={'h2'}
          align={'center'}
          $narrow={currentStep === WizardStepNames.DEPOSIT && !moneyExist}
        >
          {t(title)}
        </StepTitle>
        <StepperWrapper>
          <Stepper activeStep={currentStep} connector={<Connector />}>
            {Object.values(steps).map((_, index) => (
              <Step key={index}>
                <StepLabel icon={null}>
                  <span>{index + 1}</span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </StepperWrapper>
        <StepContent>
          <Component />
        </StepContent>
        <HelpBlock>
          <Text component={'h4'} variant={'subtitle2'}>
            {t('WIZARD_STEPPER__helpBlockTitle')}
          </Text>
          <HelpBlockText>
            {t('WIZARD_STEPPER__helpBlockText1')}{' '}
            <Link href={SiteData.FAQ_LINK} {...externalLinkProps}>
              {t('WIZARD_STEPPER__helpBlockLink1')}
            </Link>{' '}
            {t('WIZARD_STEPPER__helpBlockText2')}{' '}
            <Link href={SiteData.CONTACTS_LINK} {...externalLinkProps}>
              {t('WIZARD_STEPPER__helpBlockLink2')}
            </Link>{' '}
            {t('WIZARD_STEPPER__helpBlockText3')}
          </HelpBlockText>
        </HelpBlock>
        {!lastStep && (
          <ControlsBlock>
            <Link
              withDoubleLeftArrow
              onClick={() => {
                if (firstStep) {
                  dispatchModal({
                    type: 'setModalContent',
                    payload: {
                      name: 'initialPortfolioModal',
                    },
                  });
                } else {
                  dispatch({ type: 'goToPrevStep' });
                }
              }}
            >
              {t('WIZARD_STEPPER__goToPrev')}
            </Link>
          </ControlsBlock>
        )}
      </DashboardModalWhiteContent>
    </DashboardModalWrapper>
  );
};

const steps: {
  [key in WizardStepNames]: {
    titleData: {
      title?: string;
      krakenTitle?: string;
      bitvavoTitle?: string;
      moneyExistTitle?: string;
    };
    Component: ComponentType<Record<string, unknown>>;
  };
} = {
  [WizardStepNames.INFO]: {
    titleData: {
      title: 'WIZARD_STEPPER__openStepStepperTitle',
      krakenTitle: 'WIZARD_STEPPER__openStepStepperKrakenTitle',
      bitvavoTitle: 'WIZARD_STEPPER__openStepStepperBitvavoTitle',
    },
    Component: ExchangesInfoStep,
  },
  [WizardStepNames.KEYS_FORM]: {
    titleData: {
      krakenTitle: 'WIZARD_STEPPER__connectStepStepperKrakenTitle',
      bitvavoTitle: 'WIZARD_STEPPER__connectStepStepperBitvavoTitle',
    },
    Component: ExchangeKeysFormStep,
  },
  [WizardStepNames.DEPOSIT]: {
    titleData: {
      krakenTitle: 'WIZARD_STEPPER__depositStepStepperKrakenTitle',
      bitvavoTitle: 'WIZARD_STEPPER__depositStepStepperBitvavoTitle',
      moneyExistTitle: 'WIZARD_STEPPER__depositStepStepperMoneyExistTitle',
    },
    Component: ExchangeDepositStep,
  },
} as const;

const DashboardModalWrapper = styled(DefContentModalWrapper)<{
  $withExchangeTabs: boolean;
}>`
  ${({ $withExchangeTabs }) =>
    $withExchangeTabs &&
    css`
      top: ${50 + mobileContentSidePaddings * 2}px;

      ${getDownMedia('sm')} {
        top: 59px;
      }
    `};

  ${getDownMedia('sm')} {
    padding-bottom: 40px;
    background-color: #fff;
  }
`;

const DashboardModalWhiteContent = styled(DefContentModalWhiteContent)`
  padding-top: 52px;
`;

const StepTitle = styled(Text)<{
  $narrow: boolean;
}>`
  padding: 0 ${({ $narrow }) => ($narrow ? 50 : 10)}px;
  margin-bottom: 30px;

  ${getDownMedia('sm')} {
    padding: 0 10px;
  }
`;

const StepperWrapper = styled.div``;

const Stepper = styled(DefStepper)`
  background-color: transparent;
  &.MuiStepper-root {
    padding: 10px 24px;
  }
`;

const Connector = withStyles({
  root: {},
  line: {
    // Here
    borderColor: colors.porcelain,
    borderTopWidth: 1,
    borderRadius: 1,
  },
})(StepConnector);

const Step = styled(DefStep)`
  .MuiStepLabel-iconContainer {
    display: none;
  }
`;

const StepLabel = styled(DefStepLabel)`
  .MuiStepLabel-labelContainer {
    padding: 4px;
    background-color: ${getColor('porcelain')};
    // here
    border-radius: 50%;
  }

  .MuiStepLabel-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    font-size: 18px;
    line-height: 20px;
    font-family: ${arialFontFamily};
    ${boldText};
    color: ${getColor('blue')};

    &.Mui-active,
    &.Mui-completed {
      background-image: linear-gradient(
        134deg,
        ${getColor('blue')} 50%,
        ${getColor('blue2')} 0%
      );
      color: #fff;
    }
  }
`;

const StepContent = styled.section`
  display: flex;
  flex-direction: column;
  padding: 22px 20px 40px;
`;

const HelpBlock = styled.div`
  position: relative;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 16px;
  }

  &:before {
    content: '';
    display: block;
    height: 1px;
    margin: 0 0 14px;
    background-color: ${getColor('porcelain')};
  }
`;

const HelpBlockText = styled(Text)``;

const ControlsBlock = styled.div`
  position: absolute;
  left: 0;
  bottom: -40px;
  width: 100%;
  height: 30px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    bottom: -20px;
  }
`;

export { ExchangeWizardStepper };
