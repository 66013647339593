/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {
  useReducer,
  useEffect,
  useMemo,
  createContext,
  useContext,
  FC,
  PropsWithChildren,
  Reducer,
  Dispatch,
} from 'react';
import { isUndefined } from 'lodash-es';
import { useUpdateEffect } from 'react-use';
import { WizardStepNames } from '../utils/wizard';
import { WizardStatusType } from '../apollo';
import { useExchange } from './Exchange';

const ExchangeWizard: FC<PropsWithChildren> = (props) => {
  const { exchange } = useExchange();
  const exchangeCode = exchange?.exchangeCode;

  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    (initialState) => {
      const currentStep = getCurrentStep(exchange);

      return {
        ...initialState,
        currentStep,
        firstStep: currentStep === 0,
        lastStep: currentStep === stepsAmount - 1,
        moneyExist: exchange?.wizardStatus !== WizardStatusType.NoMoney,
        moneyAmount: exchange?.moneyUnderManagement,
        fiatOnly: exchange?.onlyFiatInPortfolio,
      };
    }
  );

  // If exchange was changed -- apply proper step
  useUpdateEffect(() => {
    const step = getCurrentStep(exchange);

    dispatch({
      type: 'setStep',
      payload: step,
    });

    if (step === WizardStepNames.DEPOSIT) {
      dispatch({
        type: 'setMoneyStatus',
        payload: {
          moneyExist: exchange?.wizardStatus !== WizardStatusType.NoMoney,
          moneyAmount: exchange?.moneyUnderManagement ?? 0,
          fiatOnly: exchange?.onlyFiatInPortfolio,
        },
      });
    }
  }, [exchangeCode]);

  // Reset money existence navigating between steps
  useEffect(() => {
    if (
      !isUndefined(state.moneyExist) &&
      state.currentStep !== WizardStepNames.DEPOSIT
    ) {
      dispatch({
        type: 'setMoneyStatus',
        payload: {
          moneyExist: undefined,
          moneyAmount: undefined,
          fiatOnly: undefined,
        },
      });
    }
  }, [state.currentStep, state.moneyExist]);

  const contextValue = useMemo(
    () => ({
      ...state,
      dispatch,
    }),
    [state]
  );

  return <ExchangeWizardContext.Provider value={contextValue} {...props} />;
};

const stepsAmount = Object.keys(WizardStepNames).length / 2;

type Exchange = ReturnType<typeof useExchange>['exchange'];

const getCurrentStep = (exchange: Exchange) => {
  switch (true) {
    case exchange?.wizardStatus === WizardStatusType.NoMoney:
    case exchange?.wizardStatus === WizardStatusType.StartTrial:
    case exchange?.wizardStatus === WizardStatusType.StartSubscription:
      return WizardStepNames.DEPOSIT;
    default:
      return WizardStepNames.INFO;
  }
};

type State = {
  currentStep: number;
  firstStep: boolean | undefined;
  lastStep: boolean | undefined;
  moneyExist: boolean | undefined;
  moneyAmount: number | undefined;
  fiatOnly: boolean | undefined;
};

const initialState: State = {
  currentStep: 0,
  firstStep: undefined,
  lastStep: undefined,
  moneyExist: undefined,
  moneyAmount: undefined,
  fiatOnly: undefined,
};

type Action =
  | {
      type: 'goToPrevStep' | 'goToNextStep';
    }
  | {
      type: 'setStep';
      payload: number;
    }
  | {
      type: 'setMoneyStatus';
      payload: {
        moneyExist: boolean | undefined;
        moneyAmount: number | undefined;
        fiatOnly: boolean | undefined;
      };
    };

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'goToPrevStep':
      const prevStep = state.currentStep - 1;
      return 0 <= prevStep
        ? {
            ...state,
            currentStep: state.currentStep - 1,
            firstStep: prevStep === 0,
            lastStep: prevStep === stepsAmount - 1,
          }
        : state;
    case 'goToNextStep':
      const nextStep = state.currentStep + 1;
      return nextStep < stepsAmount
        ? {
            ...state,
            currentStep: state.currentStep + 1,
            firstStep: nextStep === 0,
            lastStep: nextStep === stepsAmount - 1,
          }
        : state;
    case 'setStep':
      return {
        ...state,
        currentStep: action.payload,
        firstStep: action.payload === 0,
        lastStep: action.payload === stepsAmount - 1,
      };
    case 'setMoneyStatus':
      return {
        ...state,
        moneyExist: action.payload.moneyExist,
        moneyAmount: action.payload.moneyAmount,
        fiatOnly: action.payload.fiatOnly,
      };
    default:
      throw new Error('No such type');
  }
};

const ExchangeWizardContext = createContext<
  State & { dispatch: Dispatch<Action> }
>({ ...initialState, dispatch: () => undefined });

export const useExchangeWizard = () => useContext(ExchangeWizardContext);

export { ExchangeWizard };
