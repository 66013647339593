/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { makeVar, useReactiveVar } from '@apollo/client';

export const authVar = makeVar(false);
export const useAuthVar = () => useReactiveVar(authVar);
export const setAuthVar = (newValue: boolean) => authVar(newValue);

export const serverErrorVar = makeVar(false);
export const useServerErrorVar = () => useReactiveVar(serverErrorVar);
export const setServerErrorVar = (newValue: boolean) =>
  serverErrorVar(newValue);
