/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { persistor } from '../utils/cache';

export const useClearApolloCache = () => {
  const client = useApolloClient();
  return useCallback(
    () => client.clearStore().then(() => persistor.purge()),
    [client]
  );
};
