/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { compact } from 'lodash-es';
import { SentryLink } from 'apollo-link-sentry';
import { v4 as uuid } from 'uuid';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { paths } from '../pages';
import { authVar, serverErrorVar, setServerErrorVar } from './apollo-vars';
import { getToken, setToken } from './auth';
import { getCache, writeInitialCacheData } from './cache';
import { withSentry } from './consts';
import { getNetworkErrorStatusCode, isServerError } from './helpers';
import { history } from './history';
import { typeDefs } from './type-defs';

const uri = process.env.REACT_APP_API_ENDPOINT;

export type ApolloClientType = ApolloClient<NormalizedCacheObject>;

let apolloClient: ApolloClientType;

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
    });
  }
});

const serverErrorLink = onError((error) => {
  if (isServerError(error)) {
    setServerErrorVar(true);
  }
});

const logoutLink = onError((error) => {
  const statusCode = getNetworkErrorStatusCode(error);
  if (!statusCode) return;
  const authorized = authVar();
  if (authorized && statusCode === 401) {
    history.push(paths.logout);
  }
});

const resolveServerErrorLink = new ApolloLink((operation, forward) => {
  const serverError = serverErrorVar();
  if (serverError) {
    serverErrorVar(false);
  }
  return forward(operation);
});

const authLink = new ApolloLink((operation, forward) => {
  const requestId = uuid();

  operation.setContext(
    ({ headers: oldHeaders = {} }: { headers: Record<string, unknown> }) => ({
      headers: {
        ...oldHeaders,
        Authorization: getToken(),
        'X-MercuryRedstone-Request-ID': requestId,
      },
    })
  );

  // @ts-ignore
  return forward(operation);
});

const updateTokenLink = new ApolloLink((operation, forward) =>
  // @ts-ignore
  forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const token = headers.get('X-Refresh-Token');
      token && setToken(token);
    }

    return response;
  })
);

const sentryLink = withSentry
  ? new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeError: true,
      },
    })
  : null;

const httpLink = new HttpLink({
  uri,
  fetchOptions: {
    mode: 'cors',
  },
});

const apolloConfig = {
  link: ApolloLink.from(
    compact([
      errorLink,
      serverErrorLink,
      logoutLink,
      resolveServerErrorLink,
      authLink,
      updateTokenLink,
      sentryLink,
      httpLink,
    ])
  ),
  typeDefs,
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(lastFetchPolicy: WatchQueryFetchPolicy) {
        if (
          lastFetchPolicy === 'cache-and-network' ||
          lastFetchPolicy === 'network-only'
        ) {
          return 'cache-first';
        }

        return lastFetchPolicy;
      },
    },
  },
};

let creatingPromise: Promise<ApolloClientType>;

export const getClient = async () => {
  if (apolloClient) return apolloClient;
  if (creatingPromise) return creatingPromise;

  creatingPromise = getCache().then((cache) => {
    const client = new ApolloClient({
      cache,
      ...apolloConfig,
    });

    client.onClearStore(writeInitialCacheData);
    client.onResetStore(writeInitialCacheData);

    apolloClient = client;

    return apolloClient;
  });

  return creatingPromise;
};
