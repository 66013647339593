/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { isNil, isNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { getPercentages } from '../utils/percentages';
import {
  AdminIndexPerformanceQuery,
  useAdminIndexPerformanceQuery,
  useUserAdminStatusQuery,
} from '../apollo';
import { getColor, resetListStyles } from '../styles';
import { Text } from './texts';

const IndexPerformance = (props: HTMLAttributes<HTMLUListElement>) => {
  const { t } = useTranslation();
  const { data: { getUser: user } = {} } = useUserAdminStatusQuery();
  const admin = user?.isAdmin;
  const { data } = useAdminIndexPerformanceQuery({
    skip: !admin,
    fetchPolicy: 'cache-and-network',
  });

  if (!data) return null;

  const performance = data.adminGetPerformance;

  return (
    <List {...props}>
      {(
        Object.entries(items) as Array<[KeyofItems, typeof items[KeyofItems]]>
      ).map(([item, label]) => {
        const value = performance[item];
        return (
          <ListItem key={item}>
            <ListItemText>
              {t(label)}{' '}
              <PercentValue
                $positiveChange={
                  isNumber(value) && value !== 0 ? value > 0 : undefined
                }
              >
                {getPercentages(value, { withSign: true })}
              </PercentValue>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

const List = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-left: 10px;
    position: relative;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: ${getColor('blue2')};
    }
  }
`;

const ListItemText = styled(Text)`
  color: ${getColor('white')};
`;

const PercentValue = styled.span<{
  $positiveChange?: boolean;
}>`
  ${({ $positiveChange }) => {
    if (isNil($positiveChange)) return null;
    return css`
      color: ${$positiveChange ? getColor('lightGreen') : getColor('lightRed')};
    `;
  }}
`;

type AdminIndexPerformanceQueryResult = Omit<
  AdminIndexPerformanceQuery['adminGetPerformance'],
  '__typename'
>;

const items: Readonly<{
  [key in keyof AdminIndexPerformanceQueryResult]: string;
}> = {
  rate24: 'INDEX_PERFORMANCE__24hours',
  ytd: 'INDEX_PERFORMANCE__ytd',
  year: 'INDEX_PERFORMANCE__year',
  start: 'INDEX_PERFORMANCE__start',
};

type KeyofItems = keyof typeof items;

export { IndexPerformance };
