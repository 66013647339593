/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {
  useRef,
  useState,
  useMemo,
  useEffect,
  createContext,
  useContext,
  FC,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react';
import { isDutchLocale } from '../utils/helpers';
import { useIsDashboardPage, useMinAum } from '../hooks';
import {
  useUserExchangeProviderDataQuery,
  ExchangeCode,
  SelectedExchangeDocument,
  WizardStatusType,
  SelectedExchangeQuery,
  UserExchangesQuery,
} from '../apollo';
import { useModals } from './Modals';

const Exchange: FC<PropsWithChildren> = ({ children }) => {
  const initialModalWasShown = useRef(false);
  const dashboardPage = useIsDashboardPage();
  const { dispatch } = useModals();
  const {
    client,
    data: { getUser: user, selectedExchange, getUserExchanges: exchanges } = {},
  } = useUserExchangeProviderDataQuery();

  const [exchangeCode, setExchangeCode] = useState<ExchangeCode>(() => {
    if (!exchanges) return getDefaultExchange();

    // Both active
    if (
      exchanges.every(
        ({ wizardStatus }) => wizardStatus === WizardStatusType.Disabled
      )
    ) {
      return selectedExchange || getDefaultExchange();
    }

    // One started, not finished
    const exchangeInIntermediateStatus = exchanges.find(({ wizardStatus }) =>
      [WizardStatusType.StartTrial, WizardStatusType.StartSubscription].some(
        (status) => status === wizardStatus
      )
    );

    if (exchangeInIntermediateStatus) {
      return exchangeInIntermediateStatus.exchangeCode;
    }

    //  One active
    const activeExchange = exchanges.find(
      (exchange) => exchange.wizardStatus === WizardStatusType.Disabled
    );

    if (activeExchange) {
      return activeExchange.exchangeCode;
    }

    // None active
    return selectedExchange || getDefaultExchange();
  });

  const exchange = useMemo(
    () =>
      (exchangeCode &&
        exchanges?.find(({ exchangeCode: code }) => code === exchangeCode)) ??
      null,
    [exchangeCode, exchanges]
  );

  const exchangeWizardActive = useMemo(() => {
    return !exchange || exchange.wizardStatus !== WizardStatusType.Disabled;
  }, [exchange]);

  const exchangeWizardVisible = useMemo(
    () => dashboardPage && exchangeWizardActive,
    [dashboardPage, exchangeWizardActive]
  );

  // Save change of exchange to cache
  useEffect(() => {
    client.writeQuery<SelectedExchangeQuery>({
      query: SelectedExchangeDocument,
      data: { selectedExchange: exchange?.exchangeCode || null },
    });
  }, [exchange, client]);

  useMinAum(exchange, exchangeWizardActive);

  // Show initial portfolio modal if no exchange has been selected
  useEffect(() => {
    if (!dashboardPage) return;
    if (user?.isAdmin || !user?.profileValid) return;
    if (initialModalWasShown.current) return;
    if (
      !exchanges?.every(
        ({ wizardStatus }) => wizardStatus === WizardStatusType.NoKeys
      )
    ) {
      return;
    }

    console.log('exchanges', exchanges);

    initialModalWasShown.current = true;

    dispatch({
      type: 'setModalContent',
      payload: {
        name: 'initialPortfolioModal',
      },
    });
  }, [dashboardPage, user?.isAdmin, user?.profileValid, exchanges, dispatch]);

  const contextValue = useMemo<ContextState>(
    () => ({
      exchange,
      exchangeWizardActive,
      exchangeWizardVisible,
      setExchange: setExchangeCode,
    }),
    [exchangeWizardActive, exchangeWizardVisible, exchange]
  );

  return (
    <ExchangeContext.Provider value={contextValue}>
      {children}
    </ExchangeContext.Provider>
  );
};

type ExchangeType = UserExchangesQuery['getUserExchanges'][number];

type ContextState = {
  exchange: Nullish<ExchangeType>;
  exchangeWizardActive: boolean;
  exchangeWizardVisible: boolean;
  setExchange: Dispatch<SetStateAction<ExchangeCode>>;
};

const getDefaultExchange = () =>
  isDutchLocale() ? ExchangeCode.Bitvavo : ExchangeCode.Kraken;

const ExchangeContext = createContext<ContextState>({
  exchange: null,
  exchangeWizardActive: true,
  exchangeWizardVisible: false,
  setExchange: () => undefined,
});

export const useExchange = () => useContext(ExchangeContext);

export { Exchange };
