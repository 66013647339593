/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useModals } from '../../providers';
import { horizontalSectionButtonStyles } from '../../styles/account-page';
import { Button as DefButton } from '../buttons';
import { SectionTitle } from './SectionTitle';
import { SectionWrapper } from './SectionWrapper';

export type RemoveAccountSectionProps = ComponentProps<typeof SectionWrapper>;

const RemoveAccountSection = (props: RemoveAccountSectionProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals();

  return (
    <SectionWrapper $horizontal {...props}>
      <SectionTitle $noBottomMargin>
        {t('ACCOUNT_PAGE__removeAccountTitle')}
      </SectionTitle>
      <Button
        onClick={() => {
          dispatch({
            type: 'setModalContent',
            payload: {
              name: 'removeAccount',
            },
          });
        }}
      >
        {t('ACCOUNT_PAGE__removeAccountButtonText')}
      </Button>
    </SectionWrapper>
  );
};

const Button = styled(DefButton)`
  ${horizontalSectionButtonStyles};
`;

export { RemoveAccountSection };
