/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { FC } from 'react';
import { useDownloadFile } from '../../hooks';
import { Button, ButtonProps } from './Button';

export type DownloadFileButtonProps = ButtonProps & {
  filePath: string;
};

const DownloadFileButton: FC<DownloadFileButtonProps> = ({
  children,
  filePath,
}) => {
  const { loadFile, fileIsLoading } = useDownloadFile(filePath);

  return (
    <Button loading={fileIsLoading} onClick={loadFile}>
      {children}
    </Button>
  );
};

export { DownloadFileButton };
