/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, FC, HTMLAttributes, ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';
import { useUserValidProfileQuery } from '../apollo';
import {
  desktopContentSidePaddings,
  maxContentWidth,
  mobileContentSidePaddings,
  getDownMedia,
} from '../styles';
import { useExchange, usePayments } from '../providers';
import { AppFooter } from '../components/AppFooter';
import { CreditCard } from '../components/CreditCard';
import { InvalidProfile } from '../components/InvalidProfile';

export type AppPageLayoutProps = HTMLAttributes<HTMLDivElement>;

export const AppPageLayout: FC<AppPageLayoutProps> = ({
  children,
  ...props
}) => {
  const { exchangeWizardVisible } = useExchange();
  const { paymentModalOpened } = usePayments();

  const { data: { getUser: user } = {} } = useUserValidProfileQuery();

  const modalsOpened = useMemo(
    () => !user?.profileValid || exchangeWizardVisible || paymentModalOpened,
    [user, exchangeWizardVisible, paymentModalOpened]
  );

  return (
    <>
      <Content $strictHeight={modalsOpened} {...props}>
        {!user?.profileValid ? (
          <InvalidProfile />
        ) : (
          <>
            {children}
            <CreditCard />
          </>
        )}
      </Content>
      <Footer hidden={modalsOpened} />
    </>
  );
};

const Content = styled.main<{
  $strictHeight: boolean;
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: ${maxContentWidth}px;
  padding: 34px ${desktopContentSidePaddings}px 34px;
  position: relative;

  ${({ $strictHeight }) =>
    $strictHeight &&
    css`
      height: calc(100% - ${desktopFooterHeight}px);

      ${getDownMedia('sm')} {
        height: 100%;
      }

      overflow-y: hidden;
    `}

  ${getDownMedia('md')} {
    padding-top: ${mobileContentSidePaddings}px;
    padding-bottom: ${mobileContentSidePaddings}px;
    padding-left: ${mobileContentSidePaddings}px;
    padding-right: ${mobileContentSidePaddings}px;
  }
`;

const desktopFooterHeight = 48;
const mobileFooterHeight = 58;

const Footer = styled(
  ({
    hidden,
    ...props
  }: ComponentPropsWithoutRef<typeof AppFooter> & { hidden: boolean }) => (
    <AppFooter {...props} />
  )
)`
  height: ${mobileFooterHeight}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: ${desktopFooterHeight}px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ hidden }) =>
      hidden &&
      css`
        display: none;
      `}
  }
`;
