/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from './Icon';
import { Text as DefText } from './Text';
import { Title } from './Title';

const AumBelowMinimum = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon type={'sadFace'} />
      <Title>{t('AUM_BELOW_MINIMUM_MODAL__title')}</Title>
      <Text>{t('AUM_BELOW_MINIMUM_MODAL__desc')}</Text>
    </>
  );
};

const Text = styled(DefText)`
  margin-left: 0;
  margin-right: 0;
`;

export { AumBelowMinimum };
