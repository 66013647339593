/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { sendSentryError } from '../../utils/sentry';
import { useUserAdminStatusQuery } from '../../apollo';
import { useAlert } from '../../providers';
import { AppPageContentWrapper as DefAppPageContentWrapper } from '../../styled';
import { AdminUsersTable } from '../../components/tables';
import { AppPageLayout } from '../../layouts';
import { paths } from '../paths';

const AdminAllUsersPage = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const { data } = useUserAdminStatusQuery({
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      setAlert({ type: 'error', message: t('COMMON_ERROR') });
      sendSentryError(error);
    },
  });

  if (!data) return null;

  if (!data.getUser.isAdmin) return <Navigate to={paths.dashboard} replace />;

  return (
    <Wrapper>
      <AppPageContentWrapper>
        <AdminUsersTable />
      </AppPageContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(AppPageLayout)`
  max-width: none;
`;

const AppPageContentWrapper = styled(DefAppPageContentWrapper)`
  padding: 25px 30px 30px;
`;

export { AdminAllUsersPage };
