/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { css } from 'styled-components';
import 'typeface-roboto';
import '@fontsource/dm-serif-display/400.css';
import '@fontsource/rambla/700.css';
import '@fontsource/source-sans-pro/300-italic.css';
import '@fontsource/source-sans-pro/300.css';
import '@fontsource/source-sans-pro/400-italic.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600-italic.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/700.css';

export const regularTextFontFamily = 'Source Sans Pro, sans-serif';
export const titleTextFontFamily = 'Rambla, sans-serif';
export const arialFontFamily = 'Arial, Proxima Nova, sans-serif';
export const dmSerifDisplayFontFamily = 'DM Serif Display, sans-serif';

export const fontSize = 16;

export const regularText = css`
  font-weight: normal;
  font-style: normal;
`;

export const lightText = css`
  font-weight: 300;
  font-style: normal;
`;

export const semiBoldText = css`
  font-weight: 600;
  font-style: normal;
`;

export const boldText = css`
  font-weight: 700;
  font-style: normal;
`;

export const italicText = css`
  font-weight: normal;
  font-style: italic;
`;

export const italicLightText = css`
  font-weight: 300;
  font-style: italic;
`;

export const italicSemiboldText = css`
  font-weight: 600;
  font-style: italic;
`;
