/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation, TFunction } from 'react-i18next';
import { SiteData, externalLinkProps } from '../../utils/consts';
import { sendSentryError } from '../../utils/sentry';
import { ExchangeCode } from '../../apollo';
import { useExchangeWizard, useModals, useExchange } from '../../providers';
import { DesktopBr } from 'styled';
import { Button as DefButton, ButtonProps } from '../buttons';
import { Text as DefText, Link as DefLink, LinkProps } from '../texts';

export type OpenKrakenStepProps = HTMLAttributes<HTMLDivElement>;

const ExchangesInfoStep = (props: OpenKrakenStepProps) => {
  const { t } = useTranslation();
  const { dispatch: dispatchModal } = useModals();
  const { exchange } = useExchange();
  const { dispatch: dispatchExchangeWizard } = useExchangeWizard();

  const exchangesData = useMemo(
    () =>
      getExchangesData({
        t,
        dispatchModal,
        proceedToNextStep: () => {
          dispatchExchangeWizard({
            type: 'goToNextStep',
          });
        },
      }),
    [t, dispatchModal, dispatchExchangeWizard]
  );

  if (!exchange) {
    sendSentryError(new Error('No exchange at ExchangeInfoStep'));
    return null;
  }

  const { linkProps, buttons, text } = exchangesData[exchange.exchangeCode];

  return (
    <Wrapper {...props}>
      <Link withDoubleRightArrow {...linkProps} />
      <Button {...buttons.first} />
      <Text>{text}</Text>
      <Button variant={'outlined'} {...buttons.second} />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const Link = styled(DefLink)`
  margin-bottom: 32px;
`;

const Button = styled(DefButton)`
  align-self: stretch;
`;

const Text = styled(DefText)`
  margin: 28px 0;
  text-align: center;
`;

const getExchangesData: (data: {
  t: TFunction;
  dispatchModal: ReturnType<typeof useModals>['dispatch'];
  proceedToNextStep: () => void;
}) => {
  [key in ExchangeCode]: {
    buttons: {
      [key in 'first' | 'second']: ButtonProps;
    };
    linkProps: LinkProps;
    text: ReactNode;
  };
} = ({ t, dispatchModal, proceedToNextStep }) => ({
  [ExchangeCode.Bitvavo]: {
    linkProps: {
      children: t('WIZARD_STEPPER__bitvavoInfoModalLink'),
      onClick: () => {
        dispatchModal({
          type: 'setModalContent',
          payload: {
            name: 'bitvavoInfoModal',
          },
        });
      },
    },
    buttons: {
      first: {
        ...externalLinkProps,
        children: t('WIZARD_STEPPER__bitvavoInfoFirstButton'),
        href: SiteData.BITVAVO_SIGNUP,
      },
      second: {
        children: t('WIZARD_STEPPER__bitvavoInfoSecondButton'),
        onClick: proceedToNextStep,
      },
    },
    text: (
      <>
        {t('WIZARD_STEPPER__bitvavoInfoText1')}
        <DesktopBr />
        {t('WIZARD_STEPPER__bitvavoInfoText2')}
      </>
    ),
  },
  [ExchangeCode.Kraken]: {
    linkProps: {
      children: t('WIZARD_STEPPER__krakenInfoModalLink'),
      onClick: () => {
        dispatchModal({
          type: 'setModalContent',
          payload: {
            name: 'krakenInfoModal',
          },
        });
      },
    },
    buttons: {
      first: {
        ...externalLinkProps,
        children: t('WIZARD_STEPPER__krakenInfoFirstButton'),
        href: SiteData.KRAKEN_SIGNUP,
      },
      second: {
        children: t('WIZARD_STEPPER__krakenInfoSecondButton'),
        onClick: proceedToNextStep,
      },
    },
    text: (
      <>
        {t('WIZARD_STEPPER__krakenInfoText1')}
        <DesktopBr />
        {t('WIZARD_STEPPER__krakenInfoText2')}
      </>
    ),
  },
});

export { ExchangesInfoStep };
