/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDownMedia } from '../../styles';
import {
  WideContent,
  DescBlock,
  DescTitle,
  DescSubtitle,
  DescText,
  CreditCardBlock,
  CreditCardBlockProps,
} from '../credit-card-elements';
import {
  ContentModalWrapper,
  ContentModalWrapperProps,
} from '../modal-elements';

export type InTrialCreditCardModalProps = ContentModalWrapperProps &
  CreditCardBlockProps;

const InTrialCreditCardModal = ({
  close,
  buttonProps,
  ...props
}: InTrialCreditCardModalProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <WideContent>
        <DescBlock>
          <DescTitle>{t('CREDIT_CARD_IN_TRIAL__title')}</DescTitle>
          <DescSubtitle>{t('CREDIT_CARD_IN_TRIAL__subtitle1')}</DescSubtitle>
          <DescText>{t('CREDIT_CARD_IN_TRIAL__1text1')}</DescText>
          <DescText
            dangerouslySetInnerHTML={{
              __html: t('CREDIT_CARD_IN_TRIAL__1text2'),
            }}
          />
          <DescText>{t('CREDIT_CARD_IN_TRIAL__1text3')}</DescText>
          <DescSubtitle>{t('CREDIT_CARD_IN_TRIAL__subtitle2')}</DescSubtitle>
          <DescText>{t('CREDIT_CARD_IN_TRIAL__2text1')}</DescText>
        </DescBlock>
        <CreditCardBlock close={close} buttonProps={buttonProps} />
      </WideContent>
    </Wrapper>
  );
};

const Wrapper = styled(ContentModalWrapper)`
  ${getDownMedia('md')} {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  ${getDownMedia('sm')} {
    padding-top: 30px;
    padding-bottom: 10px;
  }
`;

export { InTrialCreditCardModal };
