/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useReducer,
  useMemo,
  createContext,
  useState,
  useEffect,
  useContext,
  Reducer,
  ComponentType,
  FC,
  PropsWithChildren,
  Dispatch,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { isObject } from 'lodash-es';
import { Modal as DefModal, Paper, useMediaQuery } from '@mui/material';
import { useWindowSize } from '../hooks';
import { getDownMedia, maxContentWidth } from '../styles';
import {
  ExecRebalanceSuccess,
  SellCryptoSuccess,
  RebalanceOrSellError,
  RebalanceOrSellExpired,
  PendingExecRebalance,
  PendingSellCrypto,
  PaymentSubscription,
  PaymentSingle,
  InitialPortfolioRebalanceStart,
  InitialPortfolioRebalanceError,
  UpdateExchangeKeysSuccess,
  SubscriptionDisabledSuccess,
  ChangeEmailSuccess,
  ChangeEmailError,
  ChangeEmailExpired,
  LockedCurrency,
  UnlockedCurrency,
  AumBelowMinimum,
  AccountRemovementEmailConfirm,
  RemoveAccountLinkExpired,
} from '../components/alert-modals';
import { ModalCloseButton } from '../components/modal-elements';

const AlertModals: FC<PropsWithChildren> = ({ children }) => {
  const { drawer } = useTheme();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [contentRightMargin, setContentRightMargin] = useState(0);

  const { modalOpened, Content, data } = state;

  const closeModal = () => dispatch(['closeModal']);

  const contextValue = useMemo(() => ({ ...state, dispatch }), [state]);

  const maxScreenWidth = useMemo(
    () => (drawer?.currentWidth ?? 0) + maxContentWidth,
    [drawer?.currentWidth]
  );

  const { width } = useWindowSize();

  const moreThanMaxWidth = useMediaQuery(`(min-width:${maxScreenWidth}px)`);

  useEffect(() => {
    if (moreThanMaxWidth) {
      setContentRightMargin(window.innerWidth - maxScreenWidth);
    } else {
      setContentRightMargin(0);
    }
  }, [width, moreThanMaxWidth, maxScreenWidth]);

  return (
    <AlertModalContext.Provider value={contextValue}>
      {children}
      <Modal open={modalOpened} disableEnforceFocus onClose={closeModal}>
        <ContentWrapper elevation={24} $rightMargin={contentRightMargin}>
          <ModalCloseButton onClick={closeModal} />
          {/*
          //@ts-ignore*/}
          <Content {...(isObject(data) ? data : {})} />
        </ContentWrapper>
      </Modal>
    </AlertModalContext.Provider>
  );
};

type State = {
  modalOpened: boolean;
  Content: ComponentType;
  data: unknown;
};

const initialState: State = {
  modalOpened: false,
  Content: () => null,
  data: undefined,
};

const modalsData = {
  execRebalanceSuccess: ExecRebalanceSuccess,
  rebalanceOrSellError: RebalanceOrSellError,
  rebalanceOrSellExpired: RebalanceOrSellExpired,
  sellCryptoSuccess: SellCryptoSuccess,
  pendingExecRebalance: PendingExecRebalance,
  pendingSellCrypto: PendingSellCrypto,
  paymentSubscription: PaymentSubscription,
  paymentSingle: PaymentSingle,
  initialPortfolioRebalanceStart: InitialPortfolioRebalanceStart,
  initialPortfolioRebalanceError: InitialPortfolioRebalanceError,
  updateExchangeKeysSuccess: UpdateExchangeKeysSuccess,
  subscriptionDisabledSuccess: SubscriptionDisabledSuccess,
  changeEmailSuccess: ChangeEmailSuccess,
  changeEmailError: ChangeEmailError,
  changeEmailExpired: ChangeEmailExpired,
  lockedCurrency: LockedCurrency,
  unlockedCurrency: UnlockedCurrency,
  aumBelowMinimum: AumBelowMinimum,
  accountRemovementEmailConfirm: AccountRemovementEmailConfirm,
  removeAccountLinkExpired: RemoveAccountLinkExpired,
} as const;

export type AlertModalContentType = keyof typeof modalsData;

type Action =
  | ['closeModal']
  | ['setModalContent', AlertModalContentType, unknown?];

const reducer: Reducer<State, Action> = (
  prevState,
  [type, modalType, data]
) => {
  switch (type) {
    case 'closeModal':
      return initialState;
    case 'setModalContent':
      const Content = !!modalType && modalsData[modalType];

      return !Content
        ? initialState
        : {
            modalOpened: true,
            Content,
            data,
          };
    default:
      return prevState;
  }
};

const Modal = styled(DefModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled(Paper)<{
  $rightMargin: number;
}>`
  display: flex;
  flex-direction: column;
  width: 470px;
  max-width: calc(100% - 20px);
  padding: 40px 30px 46px;
  position: relative;
  outline: none;

  ${getDownMedia('md')} {
    margin-left: ${({ theme: { drawer } }) => drawer?.currentWidth ?? 0}px;
    margin-right: ${({ $rightMargin }) => $rightMargin}px;
  }
`;

export const AlertModalContext = createContext<
  State & {
    dispatch: Dispatch<Action>;
  }
>({
  ...initialState,
  dispatch: () => undefined,
});

export const useAlertModal = () => useContext(AlertModalContext);

export { AlertModals };
