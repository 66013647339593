/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useLocation } from 'react-router-dom';
import { paths } from '../pages';

export const useIsDashboardPage = () => {
  const { pathname } = useLocation();
  return pathname === paths.dashboard;
};
