/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useState,
  useEffect,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
} from 'react';
import { useApolloClient } from '@apollo/client';
import {
  PortfolioCurrency,
  PortfolioCurrencyDocument,
  PortfolioCurrencyQueryResult,
} from '../apollo';

const PortfolioCurrencyProvider: FC<PropsWithChildren> = ({ children }) => {
  const apolloClient = useApolloClient();
  const [currency, setCurrency] = useState<PortfolioCurrency>(
    () =>
      apolloClient.readQuery<PortfolioCurrencyQueryResult['data']>({
        query: PortfolioCurrencyDocument,
      })?.portfolioCurrency ?? PortfolioCurrency.Eur
  );

  useEffect(() => {
    apolloClient.writeQuery({
      query: PortfolioCurrencyDocument,
      data: { portfolioCurrency: currency },
    });
  }, [currency, apolloClient]);

  return (
    <Context.Provider
      value={{
        currency,
        setCurrency,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const Context = createContext<{
  currency: PortfolioCurrency;
  setCurrency: (currency: PortfolioCurrency) => void;
}>({
  currency: PortfolioCurrency.Eur,
  setCurrency: () => undefined,
});

export const usePortfolioCurrency = () => useContext(Context);

export { PortfolioCurrencyProvider };
