/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled from 'styled-components';
import { isNull, isString } from 'lodash-es';
import moment, { Moment } from 'moment';
import {
  DatePicker as DefDatePicker,
  DatePickerProps as DefDatePickerProps,
} from '@mui/x-date-pickers';
// } from '@mui/lab';
import { Input as DefInput } from './Input';

export type DatePickerProps = Omit<
  DefDatePickerProps<Value, Value>,
  'value' | 'renderInput' | 'onChange'
> & {
  value: Value;
  error?: boolean;
  helperText?: string;
  onChange: (UTCDateStr: string | null) => void;
};

const DatePicker = ({
  className,
  value: rawValue,
  error,
  helperText,
  onChange,
  ...props
}: DatePickerProps) => {
  const value = useMemo(() => {
    switch (true) {
      case rawValue instanceof Date:
        return rawValue as Date;
      case moment.isMoment(rawValue):
        return (rawValue as Moment).toDate();
      case isString(rawValue) && !!rawValue:
        return moment.utc(rawValue as string).toDate();
      default:
        return null;
    }
  }, [rawValue]);

  return (
    <DefDatePicker
      value={value}
      // @ts-ignore
      renderInput={(props) => (
        <Input
          className={className}
          {...props}
          error={error}
          helperText={helperText}
        />
      )}
      // @ts-ignore
      onChange={(date) => {
        if (isNull(date)) {
          onChange(date);
        }
        if (moment.isMoment(date)) {
          onChange(date.utc().format());
        }
      }}
      {...props}
    />
  );
};

type Value = Date | Moment | string;

const Input = styled(DefInput)``;

export { DatePicker };
