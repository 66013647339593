/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDownMedia } from '../../styles';
import descImg from '../../assets/images/_Payment_icons_-_Block.svg';
import { paths } from '../../pages';
import { ButtonProps, RouteButton } from '../buttons';
import {
  ContentModalWrapper,
  ContentModalWhiteContent,
  ContentModalWrapperProps,
  ContentModalWhiteContentProps,
} from '../modal-elements';
import { Text } from '../texts';

export type UnpaidInvoiceModalProps = ContentModalWrapperProps &
  ContentModalWhiteContentProps & {
    buttonProps: ButtonProps;
  };

const UnpaidInvoiceModal = ({
  close,
  buttonProps,
  ...props
}: UnpaidInvoiceModalProps) => {
  const { t } = useTranslation();

  return (
    <ContentModalWrapper {...props}>
      <Content close={close}>
        <Title variant={'h2'} align={'center'}>
          {t('CREDIT_CARD_UNPAID_INVOICE__title')}
        </Title>
        <Subtitle align={'center'} $weight={'semiBold'}>
          {t('CREDIT_CARD_UNPAID_INVOICE__subtitle')}
        </Subtitle>
        <Desc align={'center'}>{t('CREDIT_CARD_UNPAID_INVOICE__desc')}</Desc>
        <RouteButton to={paths.invoices}>
          {t('CREDIT_CARD_UNPAID_INVOICE__button')}
        </RouteButton>
        <DescImage src={descImg} alt={'Payment options'} />
      </Content>
    </ContentModalWrapper>
  );
};

const Content = styled(ContentModalWhiteContent)`
  padding: 60px 80px;
  align-items: center;

  ${getDownMedia('sm')} {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const Title = styled(Text)`
  margin-bottom: 40px;
`;

const Subtitle = styled(Text)`
  margin-bottom: 36px;
`;

const Desc = styled(Text)`
  margin-bottom: 50px;
  padding: 0 7px;
`;

const DescImage = styled.img`
  width: auto;
  height: auto;
  margin-top: 50px;
`;

export { UnpaidInvoiceModal };
