/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { getColor } from '../../styles';
import { Title as DefTitle, TitleProps } from '../texts';

export type DescTitleProps = TitleProps;

const DescTitle = (props: DescTitleProps) => <Title withLine {...props} />;

const Title = styled(DefTitle)`
  margin-bottom: 30px;
  color: ${getColor('veniceBlue')};

  ${({ theme: { breakpoints } }) => breakpoints.between(960, 1000)} {
    font-size: 1.6rem;
  }
`;

export { DescTitle };
