/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

export const upperCaseFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

export const capitalizeAllWords = <T>(sentence: T) => {
  if (typeof sentence !== 'string') return sentence;

  return sentence
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
