/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState, useEffect } from 'react';
import {
  useLocation,
  useRoutes,
  Navigate,
  RouteObject,
} from 'react-router-dom';
import {
  isAdminPath,
  isAppPath,
  isAuthPath,
  isInnerAppPath,
} from '../utils/helpers';
import { useSetSavedURLParams } from '../hooks';
import { useAuthContext } from '../providers';
import { Wrapper } from '../styled';
import {
  adminPathPrefix,
  appPathPrefix,
  authPathPrefix,
  getPageTitle,
  pages,
  paths,
} from '../pages';
import { NotFound } from '../pages/public';
import { AppAndAdminRoutesWrapper } from './AppAndAdminRoutesWrapper';
import { AuthRoute } from './AuthRoute';
import { PrivateRoute } from './PrivateRoute';

const Routes = () => {
  const location = useLocation();
  const authorized = useAuthContext();
  useSetSavedURLParams();

  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location]);

  const routes = useCreateRoutes(authorized);

  const element = useRoutes(routes);

  return (
    <>
      <Wrapper>{element}</Wrapper>
    </>
  );
};

const useCreateRoutes = (authorized: boolean) => {
  const [routes, setRoutes] = useState<RouteObject[]>([]);

  useEffect(() => {
    setRoutes([
      {
        path: '/',
        element: (
          <Navigate to={authorized ? paths.dashboard : paths.login} replace />
        ),
      },
      {
        path: authPathPrefix,
        element: <Navigate to={paths.login} replace />,
      },
      {
        path: appPathPrefix,
        element: <Navigate to={paths.dashboard} replace />,
      },
      {
        path: adminPathPrefix,
        element: <Navigate to={paths.adminAllUsers} replace />,
      },
      // Common pages
      ...Object.entries(paths)
        .filter(([, path]) => !isAuthPath(path) && !isInnerAppPath(path))
        .map(([name, path]) => {
          const Page = pages[name];
          return {
            path,
            element: <Page />,
          };
        }),
      // Auth pages
      Object.entries(paths)
        .filter(([, path]) => isAuthPath(path))
        .reduce(
          (acc, [name, path]) => {
            const Page = pages[name];
            acc.children.unshift({
              path: path.replace(`${authPathPrefix}/`, ''),
              element: <Page />,
            });
            return acc;
          },
          {
            path: `${authPathPrefix}/*`,
            element: <AuthRoute />,
            children: [notFoundConfig] as RouteObject[],
          }
        ),
      // Verify page
      {
        path: `${appPathPrefix}/verify`,
        element: (
          <PrivateRoute>
            <VerifyPage />
          </PrivateRoute>
        ),
      },
      // App pages
      Object.entries(paths)
        .filter(
          ([, path]) => path !== `${appPathPrefix}/verify` && isAppPath(path)
        )
        .reduce(
          (acc, [name, path]) => {
            const Page = pages[name];
            acc.children.unshift({
              path: path.replace(`${appPathPrefix}/`, ''),
              element: <Page />,
            });
            return acc;
          },
          {
            path: `${appPathPrefix}/*`,
            element: <AppAndAdminRoutesWrapper />,
            children: [notFoundConfig] as RouteObject[],
          }
        ),
      // Admin pages
      Object.entries(paths)
        .filter(([, path]) => isAdminPath(path))
        .reduce(
          (acc, [name, path]) => {
            const Page = pages[name];
            acc.children.unshift({
              path: path.replace(`${adminPathPrefix}/`, ''),
              element: <Page />,
            });
            return acc;
          },
          {
            path: `${adminPathPrefix}/*`,
            element: <AppAndAdminRoutesWrapper />,
            children: [notFoundConfig] as RouteObject[],
          }
        ),
      notFoundConfig,
    ]);
  }, [authorized]);

  return routes;
};

const VerifyPage = pages.verify;

const notFoundConfig = {
  path: '*',
  element: <NotFound />,
};

export { Routes };
