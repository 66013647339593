/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { FC, PropsWithChildren } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const Pickers: FC<PropsWithChildren> = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    {children}
  </LocalizationProvider>
);

export { Pickers };
