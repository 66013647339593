/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureContext } from '@sentry/types/dist/scope';

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_API_POINT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_API_ENDPOINT,
    tunnel: `${process.env.REACT_APP_API_ENDPOINT}/sentry-tunnel`,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
};

export const sendSentryError = (
  error: unknown,
  captureContext?: CaptureContext
) => {
  if (error instanceof Error) {
    console.log(error.message);
  } else {
    console.log(error);
  }
  Sentry.captureException(error, captureContext);
};

/*export const sendSentryMessage = (
  message: string,
  extraData?: {},
  level: string = 'info'
) => {
  console.log(message);
  // Sentry.captureMessage(message, { level, extra: extraData });
};*/

/*
export const setSentryUserContext = (userID: string): void => {
  /!*return Sentry.setUserContext({
    userID,
  });*!/
};
*/
