/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isString } from 'lodash-es';
import moment from './moment';

const urlParamsLocalStorageName = 'saved-url-params';
const urlParamsDateLocalStorageName = 'saved-url-params-date';
type URLParams = [string, string][];

export const getSavedURLParams = (): URLParams => {
  try {
    const urlParamsStoredDate = localStorage.getItem(
      urlParamsDateLocalStorageName
    );
    if (!urlParamsStoredDate || areUrlParamsOutdated(urlParamsStoredDate)) {
      removeSavedURLParams();
      return [];
    }
    const savedString = localStorage.getItem(urlParamsLocalStorageName);
    return isString(savedString) ? JSON.parse(savedString) : [];
  } catch (e) {
    return [];
  }
};

export const setSavedURLParams = (params: URLParams) => {
  try {
    localStorage.setItem(urlParamsLocalStorageName, JSON.stringify(params));
    localStorage.setItem(urlParamsDateLocalStorageName, moment.utc().format());
    return true;
  } catch (e) {
    return false;
  }
};

export const removeSavedURLParams = () => {
  try {
    localStorage.removeItem(urlParamsLocalStorageName);
    localStorage.removeItem(urlParamsDateLocalStorageName);
    return true;
  } catch (e) {
    return false;
  }
};

export const areUrlParamsOutdated = (dateStr: string) => {
  const date = moment(dateStr);
  return !date.isValid() || moment.utc().diff(date, 'days') >= 1;
};
