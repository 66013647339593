/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { HTMLAttributes, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { get, isEmpty, isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { FileCopyOutlined } from '@mui/icons-material';
import { IconButton, Link as DefLink } from '@mui/material';
import { copyToClipboard } from '../../utils/clipboard';
import { getUrlParams } from '../../utils/helpers';
import { decodeFromImage } from '../../utils/qr-decoder';
import { sendSentryError } from '../../utils/sentry';
import {
  useGenerateQrCodeMutation,
  useTotpSetupMutation,
  GetUserTotpStatusDocument,
  TotpValidateResponse,
} from '../../apollo';
import { getColor, pxToRem } from '../../styles';
import { AlertContext } from '../../providers';
import { MobileBr, Spinner } from '../../styled';
import { Button as DefButton } from '../buttons';
import { Input } from '../form-elements';
import { Text } from '../texts';

type EnableTwoFactorFormProps = HTMLAttributes<HTMLDivElement> & {
  setBackupCodes: (codes: TotpValidateResponse['backupCodes']) => void;
};

const EnableTwoFactorForm = ({
  setBackupCodes,
  ...props
}: EnableTwoFactorFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertContext);
  const [userCode, setUserCode] = useState('');
  const [QRCodeData, setQRCodeData] = useState<{
    url: string | null;
    secret: string | null;
  }>({ url: null, secret: null });

  const [
    generateQRCode,
    {
      loading: generateQRCodeLoading,
      error: errorOnQRCodeGenerate,
      data: qrCodeMutationResult,
    },
  ] = useGenerateQrCodeMutation({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    generateQRCode();
  }, [generateQRCode]);

  const qrCode = qrCodeMutationResult?.totpGenerate;
  const imgUrl = qrCode ? `data:image/png;base64,${qrCode}` : null;

  // TODO: consider to get code right from response w/o parsing
  useEffect(() => {
    if (!imgUrl) return;

    let mounted = true;

    decodeFromImage(imgUrl).then((result) => {
      const params = getUrlParams(result);
      const secret = params?.get('secret');
      mounted &&
        !!secret &&
        setQRCodeData({
          url: result,
          secret,
        });
    });

    return () => {
      mounted = false;
    };
  }, [imgUrl]);

  const [setupTOTP, { loading }] = useTotpSetupMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [{ query: GetUserTotpStatusDocument }],
    onCompleted: ({ totpSetup }) => {
      const codes = get(totpSetup, 'backupCodes', []);

      if (isEmpty(codes)) {
        setAlert({ type: 'error', message: t('ENABLE_2FA_FORM__errorText') });
        sendSentryError(new Error('Backup codes in mutation are empty'));
        return;
      }

      setAlert({ type: 'success', message: t('ENABLE_2FA_FORM__successText') });

      setBackupCodes(codes as string[]);
    },
    onError: (error) => {
      setAlert({ type: 'error', message: t('ENABLE_2FA_FORM__errorText') });
      sendSentryError(error);
    },
  });

  return (
    <Wrapper {...props}>
      {(() => {
        if (generateQRCodeLoading) return <Spinner />;

        if (errorOnQRCodeGenerate || !isString(qrCode))
          return <span>{t('ENABLE_2FA_FORM__noQRCode')}.</span>;

        return (
          <>
            <Desc>
              {t('ENABLE_2FA_MODAL__description1Mobile1')} <MobileBr />
              {t('ENABLE_2FA_MODAL__description1Mobile2')}
            </Desc>
            {!!imgUrl && <Image src={imgUrl} />}
            {!!QRCodeData.url && (
              <Link href={QRCodeData.url}>QR code direct invoeren</Link>
            )}
            {!!QRCodeData.secret && (
              <Desc>
                {t('ENABLE_2FA_MODAL__description2')}
                <CopyButton
                  aria-label={'Copy'}
                  onClick={() => copyToClipboard(QRCodeData.secret)}
                >
                  <CopyIcon />
                </CopyButton>
              </Desc>
            )}
            <Form>
              <Desc variant={'subtitle2'}>
                {t('ENABLE_2FA_MODAL__description3Mobile1')} <MobileBr />
                {t('ENABLE_2FA_MODAL__description3Mobile2')}
              </Desc>
              <CodeInput
                type={'number'}
                label={t('ENABLE_2FA_MODAL__inputLabel')}
                value={userCode}
                // labelInBorder
                onChange={({ target: { value } }) => setUserCode(value)}
              />
              <Button
                loading={loading}
                onClick={() => {
                  setupTOTP({ variables: { code: userCode } });
                }}
              >
                {t('ENABLE_2FA_MODAL__submitButton')}
              </Button>
            </Form>
          </>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Desc = styled(Text)`
  text-align: center;
`;

const Image = styled.img`
  width: ${pxToRem(220)};
  height: ${pxToRem(220)};
  margin: 30px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: ${pxToRem(180)};
    height: ${pxToRem(180)};
    margin: 20px 0;
  }
`;

const Link = styled(DefLink)`
  margin: 2px 0 10px;
`;

const CopyButton = styled(IconButton)`
  margin-left: 4px;
  color: ${({ theme: { palette } }) => palette.primary.main};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 8px;
    background: ${getColor('lightGray')};
  }
`;

const CopyIcon = styled(FileCopyOutlined)`
  font-size: 1.2rem;
`;

const Form = styled.div`
  align-self: stretch;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid ${getColor('lightGray')};
`;

const CodeInput = styled(Input)``;

const Button = styled(DefButton)`
  margin-top: 6px;
`;

export { EnableTwoFactorForm };
