/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import download from 'downloadjs';

const type = 'text/plain;charset=utf-8';

export const saveTxtFile = (strData: BlobPart, filename = 'data.txt') => {
  const data = new Blob([strData], { type });
  download(data, filename, type);
};
