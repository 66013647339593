/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { isUndefined } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { externalLinkProps, SiteData } from '../../utils/consts';
import { AuthIdentityProvider, useUser2FaSectionDataQuery } from '../../apollo';
import { useModals } from '../../providers';
import { horizontalSectionButtonStyles } from '../../styles/account-page';
import { Button as DefButton } from '../buttons';
import { Text as DefText } from '../texts';
import { SectionTitle } from './SectionTitle';
import { SectionWrapper } from './SectionWrapper';

export type TwoFASectionProps = ComponentProps<typeof SectionWrapper>;

const TwoFASection = (props: TwoFASectionProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals();
  const { data: { getUser: user } = {} } = useUser2FaSectionDataQuery();

  const emailProviderExists = !!user?.authIdentities.find(
    ({ provider }) => provider === AuthIdentityProvider.Email
  );

  if (!user || !emailProviderExists) return null;

  return (
    <SectionWrapper $horizontal {...props}>
      <Content>
        <SectionTitle $noBottomMargin>
          {t('ACCOUNT_PAGE__twoFactorTitle')}
        </SectionTitle>
        <Text>
          {t('ACCOUNT_PAGE__twoFactorDescText1')}
          <Link href={SiteData.SECURITY_LINK} {...externalLinkProps}>
            {t('ACCOUNT_PAGE__twoFactorDescLinkText1')}
          </Link>
          {t('ACCOUNT_PAGE__twoFactorDescText2')}
        </Text>
      </Content>
      {!isUndefined(user.totpEnabled) ? (
        user.totpEnabled ? (
          <Button
            onClick={() => {
              dispatch({
                type: 'setModalContent',
                payload: {
                  name: 'disable2FA',
                },
              });
            }}
          >
            {t('ACCOUNT_PAGE__twoFactorDisableButtonText')}
          </Button>
        ) : (
          <Button
            onClick={() => {
              dispatch({
                type: 'setModalContent',
                payload: {
                  name: 'enable2FA',
                },
              });
            }}
          >
            {t('ACCOUNT_PAGE__twoFactorEnableButtonText')}
          </Button>
        )
      ) : (
        'Sorry, error occured determining status. Please reload a page'
      )}
    </SectionWrapper>
  );
};

const Content = styled.div``;

const Text = styled(DefText)`
  line-height: 1.2;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 16px;
  }
`;

const Button = styled(DefButton)`
  ${horizontalSectionButtonStyles};
`;

export { TwoFASection };
