/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { dmSerifDisplayFontFamily } from '../../utils/fonts';
import { getColor } from '../../styles';
import { Title, TitleProps } from './index';

export const AuthPageTitle = styled((props: TitleProps) => (
  <Title gutterBottom {...props} />
))`
  font-size: 28px;
  font-family: ${dmSerifDisplayFontFamily};
  text-align: center;
  color: ${getColor('veniceBlue')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 10px;
  }
`;
