/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useTranslation } from 'react-i18next';
import { setToken } from '../utils/auth';
import { setAuthIdentityId } from '../utils/auth-identity-id';
import { sendSentryError } from '../utils/sentry';
import { useAdminSignOutAsMutation } from '../apollo';
import { useAlert } from '../providers';
import { useClearApolloCache } from './useClearApolloCache';

export const useAdminSignOutAs = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const clearCache = useClearApolloCache();

  return useAdminSignOutAsMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ adminSignOutAs: { token, authIdentityID } }) => {
      clearCache().then(() => {
        setToken(token);
        setAuthIdentityId(authIdentityID);
        window.location.reload();
      });
    },
    onError: (error) => {
      setAlert({ type: 'error', message: t('COMMON_ERROR') });
      sendSentryError(error);
    },
  });
};
