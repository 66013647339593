/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useState,
  createContext,
  useMemo,
  useContext,
  useEffect,
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IndexPerformance } from '../components/IndexPerformance';
// noinspection ES6PreferShortImport
import { paths } from '../pages/paths';

const AppTitle: FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [title, setTitle] = useState<ContextValue['title']>(null);

  useEffect(() => {
    if (pathname === paths.adminAllUsers) {
      return setTitle(<IndexPerformance />);
    }

    const title = titles[pathname];
    setTitle(title ? t(title) : null);
  }, [pathname, t]);

  const contextValue = useMemo(
    () => ({
      title,
      setTitle,
    }),
    [title]
  );
  return <AppTitleContext.Provider value={contextValue} {...props} />;
};

const titles = {
  [paths.dashboard]: 'DASHBOARD_PAGE__title',
  [paths.invoices]: 'INVOICES_PAGE__title',
  [paths.account]: 'ACCOUNT_PAGE__title',
  [paths.contacts]: 'CONTACTS_PAGE__appBarTitle',
} as const;

type ContextValue = {
  title: ReactNode;
  setTitle: (title: ReactNode) => void;
};

const AppTitleContext = createContext<ContextValue>({
  title: null,
  setTitle: () => undefined,
});

export const useAppTitle = () => useContext(AppTitleContext);

export { AppTitle };
