/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { getAuthIdentityId } from '../../utils/auth-identity-id';
import { sendSentryError } from '../../utils/sentry';
import { useAdminSignOutAs } from '../../hooks';
import {
  useControllingUserQuery,
  useResendVerificationEmailMutation,
} from '../../apollo';
import { useAlert } from '../../providers';
import envelope from '../../assets/images/envelope.svg';
import { Button as DefButton } from '../../components/buttons';
import { Title as DefTitle, Text as DefText } from '../../components/texts';
import { AuthLayout } from '../../layouts';
import { paths } from '../paths';

const VerifyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const authIdentityId = parseInt(getAuthIdentityId() || '', 10);

  const { data: { getUser: user } = {} } = useControllingUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  const controllingUser = user?.controlledByUser;

  const [signOutAs] = useAdminSignOutAs();

  const [resendEmail, { loading: resendLoading }] =
    useResendVerificationEmailMutation({
      fetchPolicy: 'no-cache',
      variables: !isNaN(authIdentityId)
        ? {
            id: authIdentityId,
          }
        : undefined,
      onCompleted: () => {
        setAlert({
          type: 'success',
          message: t('COMMON_FORMS__sentSuccessfully'),
        });
      },
      onError: (error) => {
        setAlert({ type: 'error', message: t('VERIFY_PAGE__resendError') });
        sendSentryError(error);
      },
    });

  return (
    <Wrapper style={{ position: 'relative' }}>
      <CloseButton
        onClick={() => {
          if (controllingUser) {
            signOutAs();
          } else {
            navigate(paths.logout);
          }
        }}
      />
      <Image src={envelope} alt={'Thank you image'} />
      <Title align={'center'}>{t('VERIFY_PAGE__title')}</Title>
      <Text align={'center'}>{t('VERIFY_PAGE__text')}</Text>
      <Buttons>
        <Text>{t('VERIFY_PAGE__resendButtonDesc')}</Text>
        <Button
          variant={'contained'}
          loading={resendLoading}
          onClick={() => resendEmail()}
        >
          {t('VERIFY_PAGE__resendButtonText')}
        </Button>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled(AuthLayout)`
  padding-left: 116px;
  padding-right: 116px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding-left: 72px;
    padding-right: 72px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: 34px;
    padding-right: 34px;
  }
`;

const CloseButton = styled((props) => (
  <IconButton {...props}>
    <Close />
  </IconButton>
))`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Image = styled.img`
  width: 260px;
  height: 144px;
  object-fit: cover;
  margin: 0 auto 46px;
`;

const Title = styled(DefTitle)`
  padding-top: 14px;
  margin-bottom: 14px;
`;

const Button = styled(DefButton)`
  margin-bottom: 10px;
`;

const Text = styled(DefText)`
  & + ${Button} {
    margin-top: 10px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 20px;
`;

export { VerifyPage };
