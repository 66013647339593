/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
// eslint-disable-next-line import/no-named-as-default
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line
import i18n from 'i18next';
import en from '../assets/i18n/translations/en.json';
import nl from '../assets/i18n/translations/nl.json';
import { defaultLanguageLocale } from './consts';
import moment from './moment';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    nl: {
      translation: nl,
    },
  },
  lng: defaultLanguageLocale,
  fallbackLng: defaultLanguageLocale,
  interpolation: {
    escapeValue: false,
  },
});

i18n.on('languageChanged', function (lngLocale) {
  moment.locale(lngLocale);
});

export default i18n;
