/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useState, useEffect, FC, PropsWithChildren } from 'react';
import { ApolloProvider as Provider, ApolloClient } from '@apollo/client';
import { getClient } from '../utils/apollo-client';

const ApolloWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [client, setClient] = useState<ApolloClient<
    Record<string, unknown>
  > | null>(null);

  useEffect(() => {
    (async () => {
      const client = await getClient();
      setClient(client);
    })();
  }, []);

  return !!client ? <Provider client={client}>{children}</Provider> : null;
};
export { ApolloWrapper };
