/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isString } from 'lodash-es';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { TestConfig } from 'yup/lib/util/createValidation';
import {
  SignupFormValues,
  UserProfileFormValues,
  InvalidProfileFormValues,
  SetNewPasswordFormValues,
  ChangeUserPasswordFormValues,
  ChangeUserEmailFormValues,
  AdminSendEmailsFormValues,
  AdminTableInvoicesFormValues,
  ExchangeKeysFormValues,
} from '../components/forms';
import { phoneRegExp } from './consts';
import i18n from './i18n';
import moment from './moment';

const isUTCString: TestConfig = {
  name: 'isUTCString',
  message: i18n.t('COMMON_FORMS_ERROR__notValidDate'),
  test: (value) => !!value || moment.utc(value as string).isValid(),
};

const getEmailFieldSchema = () => ({
  email: Yup.string()
    .email(i18n.t('COMMON_FORMS_ERROR__wrongEmail'))
    .required(i18n.t('COMMON_FORMS_ERROR__required')),
});

export const getPasswordFieldSchema = () => ({
  password: Yup.string()
    .min(8, i18n.t('COMMON_FORMS_ERROR__tooShort'))
    .max(50, i18n.t('COMMON_FORMS_ERROR__tooLong'))
    .required(i18n.t('COMMON_FORMS_ERROR__required')),
});

const getFirstNameAndLastNameSchema = (t: TFunction) => ({
  firstName: Yup.string()
    .min(2, t('COMMON_FORMS_ERROR__tooShort'))
    .max(50, t('COMMON_FORMS_ERROR__tooLong'))
    .required(t('COMMON_FORMS_ERROR__required')),
  lastName: Yup.string()
    .min(2, t('COMMON_FORMS_ERROR__tooShort'))
    .max(50, t('COMMON_FORMS_ERROR__tooLong'))
    .required(t('COMMON_FORMS_ERROR__required')),
});

const getCompanyNameAndVatNumberSchema = (t: TFunction) => ({
  companyName: Yup.string()
    .min(2, t('COMMON_FORMS_ERROR__tooShort'))
    .max(50, t('COMMON_FORMS_ERROR__tooLong'))
    .when('vatNumber', {
      is: (vatNumber: string) => !vatNumber,
      then: Yup.string(),
      otherwise: Yup.string().required(t('COMMON_FORMS_ERROR__required')),
    }),
  vatNumber: Yup.string()
    .min(2, t('COMMON_FORMS_ERROR__tooShort'))
    .max(50, t('COMMON_FORMS_ERROR__tooLong')),
});

const getPasswordFieldsSchema = (t: TFunction) => ({
  ...getPasswordFieldSchema(),
  confPassword: Yup.string()
    .min(8, t('COMMON_FORMS_ERROR__tooShort'))
    .max(50, t('COMMON_FORMS_ERROR__tooLong'))
    .oneOf(
      [Yup.ref('password'), null],
      t('SIGNUP_FORM__differentPasswordsError')
    )
    .required(t('COMMON_FORMS_ERROR__required')),
});

const getCommonUserFormFields = (t: TFunction) => ({
  address: Yup.string()
    .min(2, t('COMMON_FORMS_ERROR__tooShort'))
    .max(50, t('COMMON_FORMS_ERROR__tooLong'))
    .required(t('COMMON_FORMS_ERROR__required')),
  phone: Yup.string()
    .matches(phoneRegExp, t('COMMON_FORMS_ERROR__wrongPhone'))
    .required(t('COMMON_FORMS_ERROR__required')),
  city: Yup.string()
    .min(2, t('COMMON_FORMS_ERROR__tooShort'))
    .max(50, t('COMMON_FORMS_ERROR__tooLong'))
    .required(t('COMMON_FORMS_ERROR__required')),
  zip: Yup.string().required(t('COMMON_FORMS_ERROR__required')),
  houseNumber: Yup.string().required(t('COMMON_FORMS_ERROR__required')),
  country: Yup.string().required(t('COMMON_FORMS_ERROR__required')),
});

export const getSignUpFormSchema = (
  t: TFunction
): Yup.SchemaOf<SignupFormValues> =>
  Yup.object().shape({
    ...getFirstNameAndLastNameSchema(t),
    ...getEmailFieldSchema(),
    ...getPasswordFieldsSchema(t),
  });

export const getInvalidProfileFormSchema = (
  t: TFunction
): Yup.SchemaOf<InvalidProfileFormValues> =>
  Yup.object().shape({
    ...getFirstNameAndLastNameSchema(t),
    ...getCompanyNameAndVatNumberSchema(t),
    ...getCommonUserFormFields(t),
    amountToInvest: Yup.number()
      .moreThan(0, t('COMMON_FORMS__wrongAmountError'))
      .required(t('COMMON_FORMS_ERROR__required')),
    referralCode: Yup.string().min(
      2,
      t('INVALID_PROFILE_FORM__referralFieldErrorText')
    ),
    olderThan18AndTermsAccepted: Yup.boolean()
      .default(false)
      .oneOf([true], t('COMMON_FORMS_ERROR__required')),
    sendMeNews: Yup.boolean().default(false),
    notUsPerson: Yup.boolean()
      .default(false)
      .oneOf([true], t('COMMON_FORMS_ERROR__required')),
  });

export const getUserProfileFormSchema = (
  t: TFunction
): Yup.SchemaOf<
  Record<
    keyof UserProfileFormValues,
    UserProfileFormValues[keyof UserProfileFormValues] | undefined
  >
> =>
  Yup.object().shape({
    ...getFirstNameAndLastNameSchema(t),
    ...getCompanyNameAndVatNumberSchema(t),
    ...getCommonUserFormFields(t),
  });

export const getSetNewPasswordFormSchema = (
  t: TFunction
): Yup.SchemaOf<SetNewPasswordFormValues> =>
  Yup.object().shape(getPasswordFieldsSchema(t));

export const getChangeUserEmailFormSchema =
  (): Yup.SchemaOf<ChangeUserEmailFormValues> =>
    Yup.object().shape({
      ...getEmailFieldSchema(),
      currentPassword: getPasswordFieldSchema().password,
    });

export const getChangeUserPasswordSchema = (
  t: TFunction
): Yup.SchemaOf<ChangeUserPasswordFormValues> =>
  Yup.object().shape({
    currentPassword: getPasswordFieldSchema().password,
    ...getPasswordFieldsSchema(t),
  });

export const getAdminSendEmailsFormSchema = (
  t: TFunction
): Yup.SchemaOf<AdminSendEmailsFormValues> =>
  Yup.object().shape({
    templateId: Yup.string().required(t('COMMON_FORMS_ERROR__required')),
  });

export const getDownloadInvoicesFormSchema = (): Yup.SchemaOf<
  Partial<AdminTableInvoicesFormValues>
> =>
  Yup.object().shape(
    {
      from: Yup.string()
        .test(isUTCString)
        .when('till', {
          is: (till: string) => !till,
          then: Yup.string(),
          otherwise: Yup.string().required(
            i18n.t('COMMON_FORMS_ERROR__required')
          ),
        }),
      till: Yup.string()
        .test(isUTCString)
        .test({
          name: 'compare with from',
          message: i18n.t('COMMON_FORMS_ERROR__dateShouldBeAfterPrevious'),
          test: function (value) {
            if (!value) return true;
            const { from: fromStr } = this.parent;
            debugger;
            if (!fromStr || fromStr.length === 0) return true;
            const fromMoment =
              isString(fromStr) && !!fromStr && moment(fromStr);
            const tillMoment = isString(value) && !!value && moment(value);
            return fromMoment && tillMoment && fromMoment.isBefore(tillMoment);
          },
        })
        .when('from', {
          is: (till: string) => !till,
          then: Yup.string(),
          otherwise: Yup.string().required(
            i18n.t('COMMON_FORMS_ERROR__required')
          ),
        }),
    },
    [['from', 'till']]
  );

export const getExchangeKeysFormSchema = (
  t: TFunction
): Yup.SchemaOf<ExchangeKeysFormValues> =>
  Yup.object({
    key: Yup.string()
      .min(20, t('COMMON_FORMS_ERROR__wrongKeyLength'))
      .required(t('COMMON_FORMS_ERROR__required')),
    secretKey: Yup.string()
      .min(20, t('COMMON_FORMS_ERROR__wrongKeyLength'))
      .required(t('COMMON_FORMS_ERROR__required')),
  });
