/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Tooltip } from '@mui/material';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { sendSentryError } from '../../utils/sentry';
import { useAlert } from '../../providers';
import { Button as DefButton, ButtonProps } from '../buttons';
import { GoogleIcon } from '../icons';

export type GoogleSigninButtonProps = {
  text: string;
  onSuccess: (code: string) => void;
} & ButtonProps;

const GoogleSigninButton = ({
  text,
  onSuccess,
  ...props
}: GoogleSigninButtonProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [incognitoMode, setIncognitoMode] = useState(false);

  const onError = (error: Error | ApolloError | GoogleSignInError) => {
    setAlert({
      type: 'error',
      message: t('GOOGLE_AUTH_BUTTON__error'),
    });
    sendSentryError(error);
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      onSuccess(tokenResponse.access_token);
    },
    onError: (error) => {
      if ('error' in error) {
        switch (error.error) {
          case 'temporarily_unavailable':
            setIncognitoMode(true);
            return;
        }
      }

      onError(error);
    },
  });

  return (
    <Tooltip
      title={
        incognitoMode ? (t('GOOGLE_AUTH_BUTTON__incognitoError') as string) : ''
      }
      placement={'top'}
    >
      <div>
        <Button
          disabled={incognitoMode}
          variant={'outlined'}
          color={'primary'}
          onClick={() => login()}
          {...props}
        >
          <Icon />
          {text}
        </Button>
      </div>
    </Tooltip>
  );
};

type GoogleSignInError = Pick<
  TokenResponse,
  'error' | 'error_description' | 'error_uri'
>;

const Icon = styled(GoogleIcon)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const Button = styled(DefButton)`
  .MuiButton-root {
    position: relative;
  }
`;

export { GoogleSigninButton };
