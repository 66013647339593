/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { regularText, semiBoldText } from 'utils/fonts';
import { animated, useSpring } from '@react-spring/web';
import { PortfolioCurrency } from '../apollo';
import { getColor, getDownMedia, getUpMedia } from '../styles';
import { usePortfolioCurrency } from '../providers';
import { Button } from './buttons';

export type PortfolioCurrencySwitcherProps = HTMLAttributes<HTMLDivElement> & {
  hidden?: boolean;
};

const PortfolioCurrencySwitcher = ({
  hidden = false,
  ...props
}: PortfolioCurrencySwitcherProps) => {
  const { currency, setCurrency } = usePortfolioCurrency();

  const animation = useSpring({
    to: {
      opacity: hidden ? 0 : 1,
    },
  });

  return (
    <Wrapper style={animation} $hidden={hidden} {...props}>
      <CurrencyButton
        $active={currency === PortfolioCurrency.Eur}
        variant={'text'}
        onClick={() => setCurrency(PortfolioCurrency.Eur)}
      >
        EUR
      </CurrencyButton>{' '}
      |{' '}
      <CurrencyButton
        $active={currency === PortfolioCurrency.Usd}
        variant={'text'}
        onClick={() => setCurrency(PortfolioCurrency.Usd)}
      >
        USD
      </CurrencyButton>
    </Wrapper>
  );
};

const Wrapper = styled(animated.div)<{
  $hidden: boolean;
}>`
  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'auto')};
  font-size: 1rem;
  line-height: 1;
  color: #fff;

  ${getDownMedia('md')} {
    color: ${getColor('darkBlue')};
  }
`;

const CurrencyButton = styled(Button)<{
  $active: boolean;
}>`
  display: inline-block;

  ${getDownMedia('sm')} {
    width: auto;
  }

  .MuiButton-root {
    min-width: auto;
    padding: 0;
    position: relative;
    bottom: 1px;

    ${regularText};
    color: ${getColor('darkBlue')};
    ${getDownMedia('md')} {
      color: ${getColor('veniceBlue')};
      ${semiBoldText};
    }

    &:hover {
      background-color: transparent;
    }

    ${getUpMedia('md')} {
      font-size: inherit;
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      .MuiButton-root {
        color: ${getColor('white')};

        ${getDownMedia('md')} {
          color: ${getColor('blue2')};
        }
      }
    `};
`;

export { PortfolioCurrencySwitcher };
