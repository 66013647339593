/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, ComponentProps } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { exchangesCodesTranslations } from '../../utils/translations';
import { useUserExchangesStartDatesQuery } from '../../apollo';
import { getDownMedia, getUpMedia, resetListStyles } from '../../styles';
import { useModals } from '../../providers';
import { horizontalSectionButtonStyles } from '../../styles/account-page';
import { Button as DefButton } from '../buttons';
import { Text as DefText } from '../texts';
import { SectionTitle } from './SectionTitle';
import { SectionWrapper } from './SectionWrapper';

export type DisableSubscriptionSectionProps = ComponentProps<
  typeof SectionWrapper
>;

const DisableSubscriptionSection = (props: DisableSubscriptionSectionProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals<'disableSubscription'>();

  const { data: { getUserExchanges: exchanges } = {} } =
    useUserExchangesStartDatesQuery();

  const exchangesToRender = useMemo(() => {
    if (!exchanges) return [];

    return exchanges
      .filter(({ subscriptionStartDate }) => !!subscriptionStartDate)
      .map(({ subscriptionStartDate, ...rest }) => ({
        ...rest,
        subscriptionStartDate: moment
          .utc(subscriptionStartDate)
          .format('DD-MM-YYYY'),
      }));
  }, [exchanges]);

  return (
    <SectionWrapper {...props}>
      <List>
        {exchangesToRender.map(
          ({ exchangeID, exchangeCode, subscriptionStartDate }) => (
            <ListItem key={exchangeID}>
              <div>
                <SectionTitle $noBottomMargin>
                  {t('ACCOUNT_PAGE__disableSubscriptionTitle')}{' '}
                  {t(exchangesCodesTranslations[exchangeCode])}
                </SectionTitle>
                <Text>
                  {t('ACCOUNT_PAGE__disableSubscriptionStartDate')}:{' '}
                  {subscriptionStartDate}
                </Text>
              </div>
              <Button
                onClick={() => {
                  dispatch({
                    type: 'setModalContent',
                    payload: {
                      name: 'disableSubscription',
                      data: {
                        exchangeID,
                        exchangeCode,
                      },
                    },
                  });
                }}
              >
                {t('ACCOUNT_PAGE__disableSubscriptionButton')}
              </Button>
            </ListItem>
          )
        )}
      </List>
    </SectionWrapper>
  );
};

const List = styled.ul`
  ${resetListStyles};
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${getDownMedia('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }

  & + & {
    margin-top: 30px;
  }
`;

const Text = styled(DefText)`
  margin-top: 4px;
  line-height: 1.2;
`;

const Button = styled(DefButton)`
  ${horizontalSectionButtonStyles};

  ${getUpMedia('sm')} {
    position: static;
  }

  ${getDownMedia('sm')} {
    margin-top: 20px;
  }
`;

export { DisableSubscriptionSection };
