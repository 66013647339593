/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { TableCell } from './TableCell';
import { commonNumbersAligning } from './common';

export const PriceCell = styled(TableCell)`
  ${commonNumbersAligning};
  min-width: 60px;
`;
