/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useInModal } from 'providers';

export type InputProps = TextFieldProps;

const Input = (props: InputProps) => {
  const inModal = useInModal();

  return (
    <TextField
      type={'text'}
      {...(inModal ? { InputLabelProps: { shrink: true } } : {})}
      {...props}
    />
  );
};

export { Input };
