/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const CheckmarkIcon = (props: IconProps) => (
  <Icon viewBox="0 0 14 11" {...props}>
    <path
      d="M5 11L0 6l1.41-1.41L5 8.17 12.59.58 14 2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Icon>
);

export { CheckmarkIcon };
