/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useContext,
  useCallback,
  FC,
  PropsWithChildren,
  Reducer,
  Dispatch,
} from 'react';
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { LocalStorage } from '../utils/consts';
import { useIsTablet } from '../hooks';
import { useUserAdminStatusQuery } from '../apollo';
import { drawerDesktopFullWidth, drawerDesktopShrinkedWidth } from '../styles';
import { useExchange } from './Exchange';
import { usePayments } from './Payments';

const DrawerProvider: FC<PropsWithChildren> = (props) => {
  const { pathname } = useLocation();
  const isTablet = useIsTablet();
  const { exchangeWizardVisible } = useExchange();
  const { paymentModalOpened, paymentTrialClockActive } = usePayments();
  const { data: userAdminStatusData } = useUserAdminStatusQuery();
  const admin = userAdminStatusData?.getUser.isAdmin;
  const [desktopShrinked = false, setDesktopShrinked] =
    useLocalStorage<boolean>(
      admin
        ? LocalStorage.adminDrawerDesktopShrinked
        : LocalStorage.drawerDesktopShrinked
    );

  const modalsOpened = useMemo(
    () => exchangeWizardVisible || paymentModalOpened,
    [exchangeWizardVisible, paymentModalOpened]
  );

  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    (initialState) => ({
      ...initialState,
      type: (isTablet ? 'mobile' : 'desktop') as State['type'],
      desktopShrinked,
    })
  );

  const closeMobile = useCallback(() => {
    dispatch({ type: 'toggleMobile', payload: false });
  }, []);

  // Change type
  useEffect(() => {
    dispatch({ type: 'setType', payload: isTablet ? 'mobile' : 'desktop' });
  }, [isTablet]);

  // Disable desktop shrinking if trial clock has to be shown
  useEffect(() => {
    paymentTrialClockActive &&
      dispatch({ type: 'toggleDesktopShrink', payload: false });
  }, [paymentTrialClockActive]);

  // Hide mobile if desktop width
  useEffect(() => {
    !isTablet && closeMobile();
  }, [isTablet, closeMobile]);

  // Save shrink state to local storage
  useEffect(() => {
    setDesktopShrinked(state.desktopShrinked);
  }, [state, setDesktopShrinked]);

  // Close mobile if path was changed
  useEffect(() => {
    closeMobile();
  }, [pathname, closeMobile]);

  // Hide mobile if modals were opened
  useEffect(() => {
    modalsOpened && closeMobile();
  }, [modalsOpened, closeMobile]);

  // Call resize window event after shrink change
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [desktopShrinked]);

  const currentWidth = useMemo(() => {
    switch (state.type) {
      case 'mobile':
        return 0;
      case 'desktop':
        return state.desktopShrinked
          ? drawerDesktopShrinkedWidth
          : drawerDesktopFullWidth;
      default:
        return 0;
    }
  }, [state.type, state.desktopShrinked]);

  const contextValue = useMemo(
    () => ({
      ...state,
      dispatch,
    }),
    [state]
  );

  return (
    <ThemeProvider
      theme={(defTheme) => ({
        ...defTheme,
        drawer: {
          currentWidth,
          desktopShrinked,
        },
      })}
    >
      <DrawerContext.Provider value={contextValue} {...props} />
    </ThemeProvider>
  );
};

type State = {
  type: 'mobile' | 'desktop';
  mobileOpened: boolean;
  desktopShrinked: boolean;
};

type ActionTypes = {
  setType: State['type'];
  toggleMobile: boolean;
  toggleDesktopShrink: boolean;
};

type Action = {
  type: keyof ActionTypes;
  payload?: ActionTypes[keyof ActionTypes];
};

const reducer: Reducer<State, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'setType':
      return {
        ...state,
        type: payload as State['type'],
      };
    case 'toggleMobile':
      return {
        ...state,
        mobileOpened: (payload as State['mobileOpened']) ?? !state.mobileOpened,
      };
    case 'toggleDesktopShrink':
      return {
        ...state,
        desktopShrinked:
          (payload as State['desktopShrinked']) ?? !state.desktopShrinked,
      };
    default:
      throw new Error(`No such reducer type: ${type}`);
  }
};

const initialState: State = {
  type: 'desktop',
  desktopShrinked: false,
  mobileOpened: false,
};

const DrawerContext = createContext<State & { dispatch: Dispatch<Action> }>({
  ...initialState,
  dispatch: () => undefined,
});

export const useDrawerContext = () => useContext(DrawerContext);

export { DrawerProvider };
