/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text as DefText } from './texts';

export type AuthSplitBlockProps = HTMLAttributes<HTMLDivElement>;

const AuthSplitBlock = (props: AuthSplitBlockProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper {...props}>
      <Text>{t('LOGIN_PAGE__splitBlockText')}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 20px 0 8px;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: calc(50% - 20px);
    height: 1px;
    background-color: rgba(0, 0, 0, 0.23);
    position: absolute;
    top: 50%;
    left: 0;
  }
  &:after {
    left: auto;
    right: 0;
  }
`;

const Text = styled(DefText)`
  text-align: center;
  color: rgba(0, 0, 0, 0.23);
`;

export { AuthSplitBlock };
