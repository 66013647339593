/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useCallback,
  createContext,
  useContext,
  useState,
  forwardRef,
  FC,
  PropsWithChildren,
} from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import {
  CheckCircleOutlined,
  ReportProblemOutlined,
  InfoOutlined,
  ErrorOutlineOutlined,
  SvgIconComponent,
} from '@mui/icons-material';
import { Snackbar, SnackbarContent } from '@mui/material';
import { SnackbarContentProps } from '@mui/material/SnackbarContent';
import { amber, green } from '@mui/material/colors';
import { theme } from '../styles';

type AlertType = 'success' | 'info' | 'warning' | 'error';

type Alert = {
  type: AlertType;
  message: string;
  action?: Array<React.ReactElement>;
};

let showAlert: (arg0: Alert) => void = () => undefined;

const commonIconStyles = css`
  font-size: 20px;
  margin-right: 5px;
`;

const iconByType: {
  [key in AlertType]: StyledComponent<SvgIconComponent, typeof theme>;
} = {
  success: styled(CheckCircleOutlined)`
    ${commonIconStyles}
  `,
  info: styled(InfoOutlined)`
    ${commonIconStyles}
  `,
  warning: styled(ReportProblemOutlined)`
    ${commonIconStyles}
  `,
  error: styled(ErrorOutlineOutlined)`
    ${commonIconStyles}
  `,
};

const SuccessContent = styled(SnackbarContent)`
  background-color: ${green[600]};
`;

const InfoContent = styled(SnackbarContent)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const WarningContent = styled(SnackbarContent)`
  background-color: ${amber[700]};
`;

const ErrorContent = styled(SnackbarContent)`
  background-color: ${({ theme }) => theme.palette.error.dark};
`;

const contentsByType: {
  [key in AlertType]: typeof SnackbarContent;
} = {
  success: SuccessContent,
  info: InfoContent,
  warning: WarningContent,
  error: ErrorContent,
};

const Message = styled.span`
  display: flex;
  align-items: center;
`;

export const AlertContext = createContext<{
  setAlert: (arg0: Alert) => void;
  closeAlert: () => void;
}>({
  setAlert: () => undefined,
  closeAlert: () => undefined,
});

const MySnackbarContentWrapper = forwardRef<
  typeof SnackbarContent,
  Alert & SnackbarContentProps
>((props, ref) => {
  const { type, message, action, ...other } = props;
  const Content = contentsByType[type];
  const Icon = iconByType[type];

  return (
    <Content
      ref={ref as any}
      message={
        <Message>
          <Icon />
          {message}
        </Message>
      }
      action={action || []}
      {...other}
    />
  );
});

// interface IProps {}

const AlertWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [alert, setAlertInState] = useState<Alert>({
    message: '',
    type: 'success',
    action: [],
  });

  const setAlert = useCallback((alert: Alert) => {
    setAlertInState(alert);
    setOpen(true);
  }, []);

  showAlert = setAlert;

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <AlertContext.Provider
      value={{
        setAlert: setAlert,
        closeAlert: handleClose,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          type={alert.type}
          message={alert.message}
          action={alert.action}
        />
      </Snackbar>
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);

export { AlertWrapper, showAlert };
