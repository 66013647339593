/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isNumber, toString } from 'lodash-es';

export const countDecimals = (value: number | string) => {
  value = isNumber(value) ? toString(value) : value;
  if (value.indexOf('.') === -1) return 0;
  return value.split('.')[1].length || 0;
};
