/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { compact } from 'lodash-es';
import { serverResponseErrorsCodes } from './consts';

export const getErrorCodesOfServerResponse = (serverError: any) =>
  compact([
    ...(serverError?.networkError?.result?.errors.map?.(
      (error: any) => error?.extensions?.code
    ) ?? []),
    ...(serverError?.graphQLErrors?.map?.(
      (error: any) => error?.extensions?.code
    ) ?? []),
  ]);

export const isServerErrorOfType = ({
  error,
  type,
}: {
  error: unknown;
  type: serverResponseErrorsCodes;
}) => getErrorCodesOfServerResponse(error).some((er) => er === type);
