/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled, { css } from 'styled-components';
import { isNil } from 'lodash-es';
import { colors } from '../../utils/colors';
import { semiBoldText } from '../../utils/fonts';
import { getDownMedia, getUpMedia } from '../../styles';
import {
  DollarIcon as DefDollarIcon,
  EuroIcon as DefEuroIcon,
  GraphIconSummary as DefGraphIconSummary,
  PieIcon as DefPieIcon,
} from '../icons';
import { Text } from '../texts';

export const EuroIcon = styled(DefEuroIcon)``;
export const DollarIcon = styled(DefDollarIcon)``;
export const PieIcon = styled(DefPieIcon)``;
export const GraphIconSummary = styled(DefGraphIconSummary)``;

export const BlockData = styled.div<{ positiveValue?: boolean }>`
  ${semiBoldText};
  line-height: 1.3125;
  white-space: nowrap;
  ${({ positiveValue }) => {
    if (isNil(positiveValue)) return null;

    return css`
      color: ${positiveValue ? colors.green : colors.red};
    `;
  }}

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: auto;
    margin-right: 5px;
    font-size: 18px;
  }
`;

export const BlockIcon = styled.div`
  margin: 0 10px;
`;

export const BlockInfo = styled(Text)<{
  $semiBold?: boolean;
}>`
  font-size: ${({ theme: { typography } }) => typography.pxToRem(15)};
  ${({ $semiBold = false }) => $semiBold && semiBoldText};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
    margin-top: 5px;
    text-align: center;
  }
`;

export const MobileBlockTitle = styled(Text)`
  margin-bottom: 12px;
`;

export const MobileBlockContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

export const SingleLine = styled.span`
  white-space: nowrap;
`;

const Block = styled.li`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  background-color: ${colors.white};

  & + & {
    margin-top: 20px;
  }

  ${getDownMedia('lg')} {
    flex-wrap: wrap;
    justify-content: center;
  }

  ${getDownMedia('md')} {
    & + & {
      margin-top: 2px;
    }
  }
`;

export const RebalanceBlock = styled(Block)`
  padding-left: 2.5%;
  padding-right: 2.5%;
  background-color: ${({ theme }) => theme.palette.primary.main};

  ${getUpMedia('lg')} {
    padding-left: 13px;
    padding-right: 13px;
  }

  ${getDownMedia('md')} {
    display: none;
  }

  ${BlockInfo} {
    flex-grow: 1;
    text-align: center;
    font-size: ${({ theme: { typography } }) => typography.pxToRem(15)};
    color: ${colors.white};
  }
`;

export const RebalanceBlockLineBreaker = styled.br`
  @media screen and (max-width: 1440px) {
    display: none;
  }

  ${getDownMedia('sm')} {
    display: block;
  }
`;

export const DesktopBlock = styled(Block)`
  ${getDownMedia('sm')} {
    display: none;
  }
`;

export const MobileBlock = styled(Block)`
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  ${getUpMedia('sm')} {
    display: none;
  }
`;
