/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useEffect, FC, PropsWithChildren } from 'react';
import { unstable_HistoryRouter as DefRouter } from 'react-router-dom';
import { developmentMode } from '../utils/consts';
import { ReactGA } from '../utils/google-analytics';
import { history } from '../utils/history';

const Router: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (!developmentMode) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return <DefRouter history={history}>{children}</DefRouter>;
};

export { Router };
