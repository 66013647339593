/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Formik, Form as DefForm, FormikProps, FormikFormProps } from 'formik';
import { TFunction } from 'i18next';
import { setAuthVar } from '../../utils/apollo-vars';
import { isValidToken, setToken } from '../../utils/auth';
import { setAuthIdentityId } from '../../utils/auth-identity-id';
import { isServerError } from '../../utils/helpers';
import { sendSentryError } from '../../utils/sentry';
import { getSignUpFormSchema } from '../../utils/validation-schemas';
import { useSignUpMutation, SignUpMutationVariables } from '../../apollo';
import { useAlert, useGoogleRecaptchaContext } from '../../providers';
import { GoogleRecaptchaV2 } from '../GoogleRecaptchaV2';
import { Button } from '../buttons';
import { FormikInput, FormikInputProps } from '../formik-elements';

export type SignupFormProps = {
  referralCode?: string;
} & FormikFormProps;

const SignupForm = ({ referralCode, ...props }: SignupFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const schema = useMemo(() => getSignUpFormSchema(t), [t]);
  const dataByFieldName = useMemo(() => getDataByFieldName(t), [t]);
  const recaptchaData = useGoogleRecaptchaContext();

  useEffectOnce(() => {
    recaptchaData.initRecaptcha('signupForm');
  });

  const [signUp] = useSignUpMutation({
    fetchPolicy: 'no-cache',
    onCompleted: async ({ signUp: { token, authIdentityId } }) => {
      if (!isValidToken(token)) {
        setAlert({ type: 'error', message: t('SIGNUP_FORM__errorText') });
        sendSentryError(new Error(`Token is not a string, or it's empty`));
        return;
      }

      setToken(token);
      authIdentityId && setAuthIdentityId(authIdentityId);
      setAuthVar(true);
    },
    onError: (error) => {
      const { recaptchaError } = recaptchaData.handleCaptchaError(error);
      if (recaptchaError) return;

      if (isServerError(error)) {
        setAlert({
          type: 'error',
          message: t('COMMON_FORMS__serverUnavailableError'),
        });
      } else {
        setAlert({ type: 'error', message: t('SIGNUP_FORM__errorText') });
      }

      sendSentryError(error);
    },
  });

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confPassword: '',
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        if (!recaptchaData.token) {
          setAlert({ type: 'error', message: t('SIGNUP_FORM__errorText') });
          sendSentryError(new Error('No captchaToken in login form'));
          return;
        }

        const captchaFieldName =
          recaptchaData.version === 'V3' ? 'captchaToken' : 'captchaV2Token';

        await signUp({
          variables: {
            ...values,
            referralCode,
            [captchaFieldName]: recaptchaData.token,
          },
        });
      }}
    >
      {({ values, isSubmitting }: FormikProps<SignupFormValues>) => (
        <Form {...props}>
          {(Object.keys(values) as Array<keyof SignupFormValues>).map(
            (name) => {
              const { Component, extraProps } = dataByFieldName[name];

              return (
                <Component
                  key={name}
                  id={`signup-form-${name}`}
                  name={name}
                  disabled={isSubmitting}
                  {...extraProps}
                />
              );
            }
          )}
          <GoogleRecaptchaV2 />
          <BottomBlock>
            <Button type={'submit'} loading={isSubmitting}>
              {t('SIGNUP_FORM__buttonText')}
            </Button>
          </BottomBlock>
        </Form>
      )}
    </Formik>
  );
};

export type SignupFormValues = Omit<
  SignUpMutationVariables,
  'referralCode' | 'captchaToken' | 'captchaV2Token'
>;

const Form = styled(DefForm)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FullWidthInput = styled(FormikInput)``;

const HalfWidthInput = styled(FormikInput)`
  flex-basis: calc(50% - 5px);
`;

const BottomBlock = styled.div`
  margin-top: 12px;
  flex: 0 0 100%;
`;

const getDataByFieldName: (t: TFunction) => {
  [key in keyof Required<SignupFormValues>]: {
    Component: typeof FormikInput;
    extraProps: Omit<FormikInputProps, 'name'>;
  };
} = (t) => ({
  firstName: {
    Component: HalfWidthInput,
    extraProps: {
      label: t<string>('COMMON_FORMS__firstNameFieldPlaceholder'),
      type: 'text',
    },
  },
  lastName: {
    Component: HalfWidthInput,
    extraProps: {
      label: t<string>('COMMON_FORMS__lastNameFieldPlaceholder'),
      type: 'text',
    },
  },
  email: {
    Component: FullWidthInput,
    extraProps: {
      label: t<string>('COMMON_FORMS__emailFieldPlaceholder'),
      type: 'text',
    },
  },
  password: {
    Component: FullWidthInput,
    extraProps: {
      label: t<string>('COMMON_FORMS__passwordFieldPlaceholder'),
      type: 'password',
    },
  },
  confPassword: {
    Component: FullWidthInput,
    extraProps: {
      label: t<string>('COMMON_FORMS__confirmPasswordFieldPlaceholder'),
      type: 'password',
    },
  },
});

export { SignupForm };
