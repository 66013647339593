/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { InMemoryCache } from '@apollo/client';
import {
  PortfolioCurrencyDocument,
  PortfolioCurrency,
  SelectedExchangeDocument,
  StrictTypedTypePolicies,
  SelectedExchangeQuery,
  PortfolioCurrencyQuery,
} from '../apollo';
import { setServerErrorVar } from './apollo-vars';

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      getPortfolio: {
        merge: (_, incoming) => incoming,
      },
      adminGetUsers: {
        keyArgs: false,
      },
    },
  },
  UserExchangeResponse: {
    keyFields: ['exchangeID'],
    fields: {
      aumBelowMinLastShowTime: {
        read: (existing = null) => existing,
        merge: (_, income) => income,
      },
    },
  },
  AdminUserResponse: {
    keyFields: false,
    fields: {
      exchanges: {
        keyArgs: false,
      },
    },
  },
  AdminUsersResponse: {
    fields: {
      users: {
        keyArgs: false,
        read: () => undefined,
        /*read: (existing, { args }) => {
          const { offset = 0, limit = existing?.length } = args ?? {};
          return existing && existing.slice(offset, offset + limit);
        },*/
        /*merge(existing, incoming, { args }) {
          const { offset = 0 } = args ?? {};
          const merged = existing ? [...existing] : [];
          console.log('existing', existing);
          console.log('incoming', incoming);
          for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
          }
          return merged;
        },*/
      },
    },
  },
  AuthIdentityResponse: {
    keyFields: false,
  },
};

const cache = new InMemoryCache({
  typePolicies,
});

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

let persisted = false;

const writeInitialCacheData = async () => {
  cache.writeQuery<PortfolioCurrencyQuery>({
    query: PortfolioCurrencyDocument,
    data: {
      portfolioCurrency: PortfolioCurrency.Eur,
    },
  });

  cache.writeQuery<SelectedExchangeQuery>({
    query: SelectedExchangeDocument,
    data: {
      selectedExchange: null,
    },
  });

  setServerErrorVar(false);
};

const getCache = async () => {
  if (persisted) return cache;

  await writeInitialCacheData();

  await persistor.persist();
  /*await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });*/

  persisted = true;

  return cache;
};

export { getCache, writeInitialCacheData };
