/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useUserHasActiveSubscriptionQuery } from '../../apollo';
import { ContentBlock } from './ContentBlock';
import { DisableSubscriptionSection } from './DisableSubscriptionSection';
import { RemoveAccountSection } from './RemoveAccountSection';

const SubscriptionsAndAccountBlock = () => {
  const { data: { getUser: user } = {} } = useUserHasActiveSubscriptionQuery();

  if (!user) return null;

  return (
    <ContentBlock $sameVerticalPaddings>
      {user.hasActiveSubscription ? (
        <DisableSubscriptionSection />
      ) : (
        <RemoveAccountSection />
      )}
    </ContentBlock>
  );
};

export { SubscriptionsAndAccountBlock };
