/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { getPrice } from '../../utils/currency';
import { PortfolioCurrency } from '../../apollo';
import { usePortfolioCurrency } from '../../providers';
import {
  BlockData,
  BlockIcon,
  BlockInfo,
  DesktopBlock,
  DollarIcon,
  EuroIcon,
} from './styled';

export type CostInfoProps = {
  data: number;
} & HTMLAttributes<HTMLLIElement>;

const CostInfo = ({ data, ...props }: CostInfoProps) => {
  const { t } = useTranslation();
  const { currency } = usePortfolioCurrency();

  return (
    <DesktopBlock {...props}>
      <BlockData>
        {getPrice(
          data,
          currency !== PortfolioCurrency.Eur
            ? {
                currency: 'USD',
              }
            : undefined
        )}
      </BlockData>
      <BlockIcon>
        {currency !== PortfolioCurrency.Eur ? <DollarIcon /> : <EuroIcon />}
      </BlockIcon>
      <BlockInfo>{t('DASHBOARD_PAGE__summaryPortfolioValue')}</BlockInfo>
    </DesktopBlock>
  );
};

export { CostInfo };
