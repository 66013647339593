/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isString } from 'lodash-es';

type Options = {
  round?: boolean;
  withSign?: boolean;
};

const defaultOptions: Options = {
  round: false,
  withSign: false,
};

export const getPercentages = (
  rawPercent: number | string,
  options: Options = defaultOptions
) => {
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };

  const percent = isString(rawPercent) ? parseFloat(rawPercent) : rawPercent;

  if (isNaN(percent)) return '';

  const localeOptions = {
    style: 'percent',
    minimumFractionDigits: mergedOptions.round ? 0 : 2,
    maximumFractionDigits: mergedOptions.round ? 0 : 2,
  };

  if (!mergedOptions.withSign) {
    return (percent / 100).toLocaleString('nl-NL', localeOptions);
  }

  const positivePercent = Math.abs(percent);

  let result = (positivePercent / 100).toLocaleString('nl-NL', localeOptions);

  if (percent !== 0) {
    const positive = percent === 0 || percent > 0;
    result = `${positive ? '+' : '-'} ${result}`;
  }

  return result;
};
