/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { createBrowserHistory } from 'history';
import { developmentMode } from './consts';
import { ReactGA } from './google-analytics';

const history = createBrowserHistory();

!developmentMode &&
  history.listen(({ location }) => {
    ReactGA.pageview(location.pathname + location.search);
  });

export { history };
