/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isString } from 'lodash-es';
import axios from 'axios';
import download from 'downloadjs';
import { getToken } from './auth';
import { sendSentryError } from './sentry';

export const downloadFile = (fileUrl: string) =>
  axios
    .get(fileUrl, {
      headers: {
        Authorization: getToken() ?? '',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const content = response.headers['content-type'];
      const fileName =
        (response.headers['content-disposition'] || '').split('filename=')[1] ||
        'invoice.pdf';

      isValid(response.data) && download(response.data, fileName, content);
    })
    .catch((error) => sendSentryError(error));

const isValid = (data: unknown): data is string | File | Blob | Uint8Array =>
  isString(data) ||
  [File, Blob, Uint8Array].some((type) => data instanceof type);
